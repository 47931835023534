import React from 'react';
import { useToggle } from 'react-use';
import SidePanel from 'components/SidePanel';
import SimpleButton from 'components/SimpleButton';
import BlockToolbox from '../BlockToolbox';
import { ControlsWrapper } from './styled';
import { BlockToolBoxSidePanelProps } from './types';

const BlockToolboxSidePanel = ({
  layout,
  draggedBlockRef,
}: BlockToolBoxSidePanelProps) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <>
      <ControlsWrapper>
        <SimpleButton onClick={() => toggleIsOpen(true)}>Open</SimpleButton>
      </ControlsWrapper>
      <SidePanel isOpen={isOpen} overlay={false} toggle={toggleIsOpen}>
        <BlockToolbox layout={layout} draggedBlockRef={draggedBlockRef} />
      </SidePanel>
    </>
  );
};

export default BlockToolboxSidePanel;
