import styled from 'styled-components';

export const ControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`;

export const BlockHeader = styled.div`
  margin-right: 100px;
`;
