import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import LayoutSidebar from '../LayoutSidebar';
import LayoutTopbar from '../LayoutTopbar';
import LayoutTaskbar from '../LayoutTaskbar';
import { LayoutSpacer } from './styled';
import ErrorWindow from '../ErrorWindow';
import Toast from '../Toast';
import UserNotesWindow from '../UserNotesWindow';
import UserNotifications from '../UserNotifications';

const LayoutSpacerRefContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);
const BreadcrumbRefContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);
const TopbarExtensionRefContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);

export const useLayoutSpacer = () => useContext(LayoutSpacerRefContext);
export const useBreadcrumb = () => useContext(BreadcrumbRefContext);
export const useTopbarExtension = () => useContext(TopbarExtensionRefContext);

const Layout = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const { isOpen: sidebarMenuIsOpen } = useSelector(
    (state: any) => state.layout.sidebarMenuMeta
  );

  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const layoutSpacerRef = useRef<HTMLDivElement>(null);
  const topbarExtensionRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutSpacerRefContext.Provider value={layoutSpacerRef}>
      <TopbarExtensionRefContext.Provider value={topbarExtensionRef}>
        <div>
          <LayoutSidebar />
          <div className="d-flex flex-column" style={{ height: '100vh' }}>
            <LayoutTopbar
              breadcrumbRef={breadcrumbRef}
              extensionRef={topbarExtensionRef}
            />
            <LayoutSpacer
              ref={layoutSpacerRef}
              id="layout-spacer"
              className="flex-grow-1 py-4 pr-4"
              sidebarMenuIsOpen={sidebarMenuIsOpen}
            >
              <BreadcrumbRefContext.Provider value={breadcrumbRef}>
                {children}
              </BreadcrumbRefContext.Provider>
            </LayoutSpacer>
            <LayoutTaskbar />
          </div>
          <ErrorWindow />
          <Toast />
          <UserNotesWindow />
          <UserNotifications />
        </div>
      </TopbarExtensionRefContext.Provider>
    </LayoutSpacerRefContext.Provider>
  );
};

export default Layout;
