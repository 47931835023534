import { useLazyQuery, useQuery } from '@apollo/client';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import ClickToCopy from '../../../../components/ClickToCopy';
import JobPriorityPill from '../../../../components/JobPriorityPill';
import JobStatusFlag from '../../../../components/JobStatusFlag';
import ScrollDiv from '../../../../components/ScrollDiv';
import SearchBar from '../../../../components/SearchBar';
import SimpleButton from '../../../../components/SimpleButton';
import { Small } from '../../../../components/Typography';
import { useSearchRefetch } from '../../../hooks';
import { Job, QueryResult } from '../../../types';
import { BlockHeader } from '../Block/styled';
import { BlockInnerProps } from '../Block/types';
import { BlockToolboxCardProps } from '../BlockToolbox/types';
import { JOB_QUERY, JOBS_QUERY } from './query';

export const BlockToolboxCardJob = ({
  blockDefinition,
}: BlockToolboxCardProps) => {
  const [incomingJobs, setIncomingJobs] = useState<QueryResult<Job>>({
    results: [],
    count: 0,
  });

  useQuery(JOB_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { size: 5 },
    onCompleted: (data) => {
      setIncomingJobs(data.incomingJobs);
    },
  });

  return (
    <div>
      {blockDefinition.type} hello
      <div>
        {incomingJobs.results.map((job) => (
          <div key={job.id}>{job.reference}</div>
        ))}
      </div>
    </div>
  );
};

const BlockJob = ({ block, actions }: BlockInnerProps) => {
  const [jobs, setJobs] = useState<QueryResult<Job>>({ results: [], count: 0 });
  const [job, setJob] = useState<Job>(block.data.job);

  const { loading: initialLoading } = useQuery(JOB_QUERY, {
    fetchPolicy: 'no-cache',
    skip: !block.data.id,
    variables: { jobId: block.data.id },
    onCompleted: (data) => {
      setJob(data.job);
    },
  });

  const { refetch } = useQuery(JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    skip: !!block.data.id,
    onCompleted: (data) => {
      setJobs(data.jobs);
    },
  });

  const [searchBarProps] = useSearchRefetch(refetch, {
    onCompleted: (response) => {
      setJobs(response.data.jobs);
    },
  });

  const handleSelectJob = (selectedJob: Job) => {
    actions.updateBlock(block, { data: selectedJob });
    setJob(selectedJob);
  };

  if (initialLoading) {
    return <div />;
  }

  if (!job) {
    return (
      <div>
        <BlockHeader>
          <SearchBar {...searchBarProps} />
        </BlockHeader>
        <div>
          {jobs.results.map((innerJob) => (
            <SimpleButton
              key={innerJob.id}
              onClick={() => handleSelectJob(innerJob)}
            >
              {innerJob.reference}
            </SimpleButton>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 h-100">
      <div>
        <div className="mb-3">
          <div className="d-flex mb-1">
            <Small className="mr-2" style={{ lineHeight: '26px' }}>
              Ref:{' '}
              <ClickToCopy
                copy={job.reference}
                className="mono font-weight-normal"
              >
                {job.reference}
              </ClickToCopy>
            </Small>
            <JobPriorityPill includeCode={false} job={job} type="response" />
          </div>
          <h4 className="mb-0">{job.name}</h4>
        </div>
        <div className="mb-2">
          <Small>Status</Small>
          <p className="mb-0">
            <JobStatusFlag className="mr-2" flag={job.status.flag} />
            {job.status.nameDisplay}
          </p>
        </div>
        <div className="mb-2">
          <Small>Site</Small>
          <p className="mb-0">{job.site.name}</p>
        </div>
        {job.assignees.length > 0 && (
          <ScrollDiv className="flex-grow-1" style={{ overflowY: 'auto' }}>
            <Small>Assignees</Small>
            <div className="d-flex flex-wrap">
              {job.assignees.map((user) => (
                <div key={user.id} className="mr-2 text-nowrap">
                  <FontAwesomeIcon
                    className="mr-1"
                    icon={faUserCircle}
                    fixedWidth
                  />
                  <span>{user.name}</span>
                </div>
              ))}
            </div>
          </ScrollDiv>
        )}
      </div>
    </div>
  );
};

export default BlockJob;
