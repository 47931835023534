import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($clientId: ID!) {
    incomingJobs(clientId: $clientId) {
      count
      results {
        id
        client {
          slug
        }
        reference
        priority {
          code
          responseDuration
          responseName
          completionName
          completionDuration
          color
        }
        name
        dateTimeCreated
        status {
          nameDisplay
          flag {
            color
          }
        }
        instantiationType {
          name
        }
      }
    }
    incomingJobsChart(clientId: $clientId) {
      keys {
        name
        color
      }
      items {
        count
        timestamp
      }
    }
    jobIssues(clientId: $clientId) {
      count
      results {
        id
        client {
          slug
        }
        number
        dateTimeCreated
        issueType
        issueTypeDisplay
        status
        openedBy {
          id
          type
          meta
          data
          dateTime
          user {
            id
            name
          }
        }
        closedBy {
          id
          type
          meta
          data
          dateTime
          user {
            id
            name
          }
        }
        worstLogBlock {
          id
          type
          meta
          data
          dateTime
          user {
            id
            name
          }
        }
        tags {
          id
          name
          color
        }
        job {
          id
          reference
          name
          client {
            slug
          }
          originalTargetDateTimeStart
          targetDateTimeStart
          targetDateTimeEnd
          status {
            nameDisplay
            flag {
              color
              icon
            }
          }
        }
      }
    }
    jobIssuesChart(clientId: $clientId) {
      keys {
        name
        color
      }
      items {
        open
        closed
        timestamp
      }
    }
    jobReports(clientId: $clientId, status: "COMPLETED") {
      count
      results {
        id
        number
        client {
          slug
        }
        job {
          reference
          client {
            slug
          }
          status {
            nameDisplay
            flag {
              color
            }
          }
        }
        template {
          name
        }
        status
      }
    }
    jobReportsChart(clientId: $clientId) {
      keys {
        name
        color
      }
      items {
        count
        timestamp
      }
    }
  }
`;
