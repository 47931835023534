import styled from 'styled-components';
import { SidePanelWrapperProps, SidePanelOverlayProps } from './types';

const transitionTime = '250ms';

export const SidePanelWrapper = styled.div<SidePanelWrapperProps>`
  position: absolute;
  top: 0;
  right: 1rem;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
  z-index: 8500;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid rgba(0, 0, 0, 0.05);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : '100%')});
  transition: opacity ${transitionTime}, transform ${transitionTime};

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }
`;

export const SidePanelOverlay = styled.div<SidePanelOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 8499;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity ${transitionTime};
`;
