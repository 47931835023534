import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import NodeBaseCondition from '../NodeBaseCondition';
import { NodeUnaryConditionProps } from './types';
import { NumberInput } from './styled';

const NodeUnaryCondition = ({ children, ...node }: NodeUnaryConditionProps) => {
  const { id } = node;
  return (
    <NodeBaseCondition {...node}>
      <Handle id={`target-1-${id}`} type="target" position={Position.Top} />
      {children}
      <Handle id={`source-1-${id}`} type="source" position={Position.Bottom} />
    </NodeBaseCondition>
  );
};

export { NumberInput };

export default NodeUnaryCondition;
