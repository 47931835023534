import update, { Spec } from 'immutability-helper';
import {
  ReportTemplate,
  ReportTemplateBlock,
  ReportTemplateBlockLogic,
  ReportTemplateResponse,
  ReportTemplateResponseSet,
  ReportTemplateRootBlock,
} from 'lib/types';
import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { getItemDescendants, getItemIsCollapsed } from '../../lib/tree/utils';
import {
  CreateReportTemplateResponseMutationData,
  DeleteReportTemplateResponseMutationData,
  ReportTemplateResponseSetsQueryData,
} from '../ReportTemplateBlockValueTypeEditor/types';
import {
  InitialQueryData,
  NewReportTemplateBlock,
  State,
  UndoReportTemplateUpdateMutationData,
} from './types';

export const initialState: State = {
  reportTemplate: null,
  reportTemplateState: {
    id: '',
    value: {},
    valueDefaults: {},
    collapsed: [],
  },
  reportTemplateResponseSets: [],
  reportTemplateResponseSet: null,
  focused: null,
  selected: null,
  controlsPosition: { top: 0 },
};

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [
    initialState,
    () => {
      throw Error(
        'Default reducer called, have you forgotten to wrap your component with ReducerContext.Provider?'
      );
    },
  ]
);

export const useReducerContext = () => useContext(ReducerContext);

export const initialQueryOnCompleted = (data: InitialQueryData) => ({
  type: 'INITIAL_QUERY_ON_COMPLETED',
  reportTemplate: data.reportTemplate,
  reportTemplateState: data.reportTemplateState,
});

export const reportTemplateRootOnChange = (root: ReportTemplateRootBlock) => ({
  type: 'REPORT_TEMPLATE_ROOT_ON_CHANGE',
  root,
});

export const reportTemplateBlockOnCollapse = (
  collapsed: ReportTemplateBlock['id'][]
) => ({
  type: 'REPORT_TEMPLATE_BLOCK_ON_COLLAPSE',
  collapsed,
});

export const reportTemplateStateOnChange = (
  id: ReportTemplateBlock['id'],
  value: ReportTemplateBlock['id']
) => ({
  type: 'REPORT_TEMPLATE_STATE_ON_CHANGE',
  id,
  value,
});

export const reportTemplateBlockOnChange = (
  id: ReportTemplateBlock['id'],
  diff: Partial<ReportTemplateBlock>
) => ({
  type: 'REPORT_TEMPLATE_BLOCK_ON_CHANGE',
  id,
  diff,
});

export const updateReportTemplateBlock = (
  reportTemplateBlockId: ReportTemplateBlock['id'],
  updateSpec: Spec<ReportTemplateBlock>
) => ({
  type: 'UPDATE_REPORT_TEMPLATE_BLOCK',
  reportTemplateBlockId,
  updateSpec,
});

export const toggleReportTemplateBlockBoolean = (
  id: ReportTemplateBlock['id'],
  key: keyof ReportTemplateBlock
) => ({
  type: 'TOGGLE_REPORT_TEMPLATE_BLOCK_BOOLEAN',
  id,
  key,
});

export const reportTemplateBlockLogicOnChange = (
  reportTemplateBlockId: ReportTemplateBlock['id'],
  reportTemplateBlockLogicId: ReportTemplateBlock['id'],
  diff: Partial<ReportTemplateBlockLogic>
) => ({
  type: 'REPORT_TEMPLATE_BLOCK_LOGIC_ON_CHANGE',
  reportTemplateBlockId,
  reportTemplateBlockLogicId,
  diff,
});

export const updateReportTemplateBlockLogic = (
  reportTemplateBlockLogicId: ReportTemplateBlock['id'],
  updateSpec: Spec<ReportTemplateBlockLogic>
) => ({
  type: 'UPDATE_REPORT_TEMPLATE_BLOCK_LOGIC',
  reportTemplateBlockLogicId,
  updateSpec,
});

export const reportTemplateBlockLogicAddTrigger = (
  reportTemplateBlockId: ReportTemplateBlock['id'],
  reportTemplateBlockLogicId: ReportTemplateBlock['id'],
  logicTrigger: string
) => ({
  type: 'REPORT_TEMPLATE_BLOCK_LOGIC_ADD_TRIGGER',
  reportTemplateBlockId,
  reportTemplateBlockLogicId,
  logicTrigger,
});

export const addLogicToBlock = (
  addToBlock: ReportTemplateBlock,
  newLogic: ReportTemplateBlockLogic
) => ({
  type: 'ADD_LOGIC_TO_BLOCK',
  addToBlock,
  newLogic,
});

export const addBlockToRoot = (newBlock: NewReportTemplateBlock) => ({
  type: 'ADD_BLOCK_TO_ROOT',
  newBlock,
});

export const addBlock = (
  newBlock: NewReportTemplateBlock,
  item: ReportTemplateBlock
) => ({
  type: 'ADD_BLOCK',
  newBlock,
  item,
});

export const addBlockToLogic = (
  newBlock: NewReportTemplateBlock,
  item: ReportTemplateBlock,
  logic: ReportTemplateBlockLogic
) => ({
  type: 'ADD_BLOCK_TO_LOGIC',
  newBlock,
  item,
  logic,
});

export const focusBlock = (blockId: ReportTemplateBlock['id'] | null) => ({
  type: 'FOCUS_BLOCK',
  blockId,
});

export const blurBlock = () => ({
  type: 'BLUR_BLOCK',
});

export const selectBlock = (blockId: ReportTemplateBlock['id'] | null) => ({
  type: 'SELECT_BLOCK',
  blockId,
});

export const setControlsPosition = (
  controlsPosition: State['controlsPosition']
) => ({
  type: 'SET_CONTROLS_POSITION',
  controlsPosition,
});

export const deleteBlock = (
  reportTemplateBlockId: ReportTemplateBlock['id']
) => ({
  type: 'DELETE_BLOCK',
  reportTemplateBlockId,
});

export const reportTemplateResponseSetsQueryOnCompleted = (
  responseData: ReportTemplateResponseSetsQueryData
) => ({
  type: 'REPORT_TEMPLATE_RESPONSE_SETS_QUERY_ON_COMPLETED',
  reportTemplateResponseSets: responseData.reportTemplateResponseSets,
});

export const selectReportTemplateResponseSetById = (
  reportTemplateResponseSetId: ReportTemplateResponseSet['id']
) => ({
  type: 'SELECT_REPORT_TEMPLATE_RESPONSE_SET_BY_ID',
  reportTemplateResponseSetId,
});

export const createReportTemplateResponseMutationOnCompleted = (
  responseData: CreateReportTemplateResponseMutationData
) => ({
  type: 'CREATE_REPORT_TEMPLATE_RESPONSE_MUTATION_ON_COMPLETED',
  reportTemplateResponse:
    responseData.createReportTemplateResponse.reportTemplateResponse,
});

export const reportTemplateResponseValueOnChange = (
  reportTemplateResponseId: ReportTemplateResponse['id'],
  diff: Partial<ReportTemplateResponse>
) => ({
  type: 'REPORT_TEMPLATE_RESPONSE_ON_CHANGE',
  reportTemplateResponseId,
  diff,
});

export const deleteReportTemplateResponseMutationOnCompleted = (
  responseData: DeleteReportTemplateResponseMutationData
) => ({
  type: 'DELETE_REPORT_TEMPLATE_RESPONSE_MUTATION_ON_COMPLETED',
  deleted: responseData.deleteReportTemplateResponse.deleted,
  deletedId: responseData.deleteReportTemplateResponse.deletedId,
});

export const reportTemplateResponseOrderOnChange = (
  reportTemplateResponses: ReportTemplateResponse[]
) => ({
  type: 'REPORT_TEMPLATE_RESPONSE_ORDER_ON_CHANGE',
  reportTemplateResponses,
});

export const toggleReportTemplateLogicConditionResponse = (
  block: ReportTemplateBlock,
  logic: ReportTemplateBlockLogic,
  response: ReportTemplateResponse
) => ({
  type: 'TOGGLE_REPORT_TEMPLATE_LOGIC_CONDITION_RESPONSE',
  block,
  logic,
  response,
});

export const undoReportTemplateUpdateMutationOnCompleted = (
  responseData: UndoReportTemplateUpdateMutationData
) => ({
  type: 'UNDO_REPORT_TEMPLATE_UPDATE_MUTATION_ON_COMPLETED',
  reportTemplate: responseData.undoReportTemplateUpdate.reportTemplate,
});

const reducer = (state: State, action: AnyAction) => {
  switch (action.type) {
    case 'INITIAL_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<typeof initialQueryOnCompleted>;
      return {
        ...state,
        reportTemplate: thisAction.reportTemplate,
        reportTemplateState: thisAction.reportTemplateState,
      };
    }

    case 'REPORT_TEMPLATE_ROOT_ON_CHANGE': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateRootOnChange
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: thisAction.root,
        },
      };
    }

    case 'REPORT_TEMPLATE_BLOCK_ON_COLLAPSE': {
      if (!state.reportTemplateState) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateBlockOnCollapse
      >;
      return {
        ...state,
        reportTemplateState: {
          ...state.reportTemplateState,
          collapsed: thisAction.collapsed,
        },
      };
    }

    case 'REPORT_TEMPLATE_STATE_ON_CHANGE': {
      if (!state.reportTemplateState) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateStateOnChange
      >;
      return {
        ...state,
        reportTemplateState: {
          ...state.reportTemplateState,
          value: {
            ...state.reportTemplateState.value,
            [thisAction.id]: thisAction.value,
          },
        },
      };
    }

    case 'REPORT_TEMPLATE_BLOCK_ON_CHANGE': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateBlockOnChange
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.id
                ? { ...block, ...(thisAction.diff ?? {}) }
                : block
            ),
          },
        },
      };
    }

    case 'UPDATE_REPORT_TEMPLATE_BLOCK': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof updateReportTemplateBlock>;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.reportTemplateBlockId
                ? update(block, thisAction.updateSpec)
                : block
            ),
          },
        },
      };
    }

    case 'TOGGLE_REPORT_TEMPLATE_BLOCK_BOOLEAN': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof toggleReportTemplateBlockBoolean
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.id
                ? { ...block, [thisAction.key]: !block[thisAction.key] }
                : block
            ),
          },
        },
      };
    }

    case 'REPORT_TEMPLATE_BLOCK_LOGIC_ON_CHANGE': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateBlockLogicOnChange
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.reportTemplateBlockId
                ? {
                    ...block,
                    logic: block.logic.map((logic) =>
                      logic.id === thisAction.reportTemplateBlockLogicId
                        ? { ...logic, ...thisAction.diff }
                        : logic
                    ),
                  }
                : block
            ),
          },
        },
      };
    }

    case 'UPDATE_REPORT_TEMPLATE_BLOCK_LOGIC': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof updateReportTemplateBlockLogic
      >;

      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.logic
                .map((logic) => logic.id)
                .includes(thisAction.reportTemplateBlockLogicId)
                ? {
                    ...block,
                    logic: block.logic.map((logic) =>
                      logic.id === thisAction.reportTemplateBlockLogicId
                        ? update(logic, thisAction.updateSpec)
                        : logic
                    ),
                  }
                : block
            ),
          },
        },
      };
    }

    case 'REPORT_TEMPLATE_BLOCK_LOGIC_ADD_TRIGGER': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateBlockLogicAddTrigger
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.reportTemplateBlockId
                ? {
                    ...block,
                    logic: block.logic.map((logic) =>
                      logic.id === thisAction.reportTemplateBlockLogicId
                        ? {
                            ...logic,
                            logicTriggers: [
                              ...logic.logicTriggers,
                              thisAction.logicTrigger,
                            ],
                          }
                        : logic
                    ),
                  }
                : block
            ),
          },
        },
      };
    }

    case 'ADD_LOGIC_TO_BLOCK': {
      if (!state.reportTemplate || !state.reportTemplateState) return state;
      const thisAction = action as ReturnType<typeof addLogicToBlock>;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.addToBlock.id
                ? {
                    ...block,
                    logicConditionOrder: [
                      ...block.logicConditionOrder,
                      thisAction.newLogic.id,
                    ],
                    logic: [...block.logic, thisAction.newLogic],
                  }
                : block
            ) as ReportTemplateBlock[],
          } as ReportTemplateRootBlock,
        },
        reportTemplateState: {
          ...state.reportTemplateState,
          value: {
            ...state.reportTemplateState.value,
            [thisAction.addToBlock.id]: thisAction.newLogic.id,
          },
        },
      };
    }

    case 'ADD_BLOCK_TO_ROOT': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof addBlockToRoot>;

      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            children: [
              ...state.reportTemplate.root.children,
              thisAction.newBlock.id,
            ],
            blocks: [...state.reportTemplate.root.blocks, thisAction.newBlock],
          } as ReportTemplateRootBlock,
        } as ReportTemplate,
      };
    }

    case 'ADD_BLOCK': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof addBlock>;

      let blocks = [...state.reportTemplate.root.blocks];
      const blockIds = blocks.map((block) => block.id);

      const currentBlock = thisAction.item;
      const currentBlockIndex = blocks.indexOf(thisAction.item);
      const currentBlockDescendants = getItemDescendants(
        currentBlock.id,
        state.reportTemplate.root.blocks
      );

      blocks.splice(
        currentBlockIndex + currentBlockDescendants.length + 1,
        0,
        thisAction.newBlock as ReportTemplateBlock
      );

      blocks = blocks.map((block) => {
        let ret = block;

        if (block.id === thisAction.item.parent.id) {
          ret = {
            ...ret,
            children: [...block.children, thisAction.newBlock.id].sort(
              (a, b) => blockIds.indexOf(a) - blockIds.indexOf(b)
            ),
          };

          const logicId =
            state.reportTemplateState.value[thisAction.item.parent.id] ||
            state.reportTemplateState.valueDefaults[thisAction.item.parent.id];

          ret = {
            ...ret,
            logic: ret.logic.map((logic) =>
              logic.id === logicId
                ? {
                    ...logic,
                    children: [...logic.children, thisAction.newBlock.id].sort(
                      (a, b) => blockIds.indexOf(a) - blockIds.indexOf(b)
                    ),
                  }
                : logic
            ),
          };
        }

        return ret;
      });

      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks,
          },
        },
      };
    }

    case 'ADD_BLOCK_TO_LOGIC': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof addBlockToLogic>;

      let blocks = [...state.reportTemplate.root.blocks];
      const blockIds = blocks.map((block) => block.id);

      const currentBlock = thisAction.item;
      const currentBlockIndex = blockIds.indexOf(thisAction.item.id);

      blocks.splice(
        currentBlockIndex + 1,
        0,
        thisAction.newBlock as ReportTemplateBlock
      );

      blocks = blocks.map((block) => {
        if (block.id === currentBlock.id) {
          return {
            ...block,
            children: [...block.children, thisAction.newBlock.id],
            logic: block.logic.map((logic) =>
              logic.id === thisAction.logic.id
                ? {
                    ...logic,
                    children: [...logic.children, thisAction.newBlock.id].sort(
                      (a, b) => blockIds.indexOf(a) - blockIds.indexOf(b)
                    ),
                  }
                : logic
            ),
          };
        }

        return block;
      });

      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks,
          },
        },
      };
    }

    case 'SELECT_BLOCK': {
      const thisAction = action as ReturnType<typeof selectBlock>;
      return {
        ...state,
        selected: thisAction.blockId,
      };
    }

    case 'FOCUS_BLOCK': {
      const thisAction = action as ReturnType<typeof focusBlock>;
      return {
        ...state,
        focused: thisAction.blockId,
        prevFocused: 'FOCUS',
      };
    }

    case 'BLUR_BLOCK': {
      return {
        ...state,
        focused: null,
        prevFocused: 'BLUR',
      };
    }

    case 'SET_CONTROLS_POSITION': {
      const thisAction = action as ReturnType<typeof setControlsPosition>;
      return {
        ...state,
        controlsPosition: thisAction.controlsPosition,
      };
    }

    case 'DELETE_BLOCK': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof deleteBlock>;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            children: state.reportTemplate.root.children.filter(
              (childId) => childId !== thisAction.reportTemplateBlockId
            ),
            blocks: state.reportTemplate.root.blocks
              .filter(
                (reportTemplateBlock) =>
                  reportTemplateBlock.id !== thisAction.reportTemplateBlockId
              )
              .map((reportTemplateBlock) => ({
                ...reportTemplateBlock,
                children: reportTemplateBlock.children.filter(
                  (childId) => childId !== thisAction.reportTemplateBlockId
                ),
                logic: reportTemplateBlock.logic.map((logic) => ({
                  ...logic,
                  children: logic.children.filter(
                    (childId) => childId !== thisAction.reportTemplateBlockId
                  ),
                })) as ReportTemplateBlockLogic[],
              })) as ReportTemplateBlock[],
          } as ReportTemplateRootBlock,
        },
      };
    }

    case 'REPORT_TEMPLATE_RESPONSE_SETS_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof reportTemplateResponseSetsQueryOnCompleted
      >;
      return {
        ...state,
        reportTemplateResponseSets: thisAction.reportTemplateResponseSets,
      };
    }

    case 'SELECT_REPORT_TEMPLATE_RESPONSE_SET_BY_ID': {
      const thisAction = action as ReturnType<
        typeof selectReportTemplateResponseSetById
      >;
      return {
        ...state,
        reportTemplateResponseSet:
          state.reportTemplateResponseSets.find(
            (reportTemplateResponseSet) =>
              reportTemplateResponseSet.id ===
              thisAction.reportTemplateResponseSetId
          ) ?? null,
      };
    }

    case 'CREATE_REPORT_TEMPLATE_RESPONSE_MUTATION_ON_COMPLETED': {
      if (!state.reportTemplateResponseSet) return state;
      const thisAction = action as ReturnType<
        typeof createReportTemplateResponseMutationOnCompleted
      >;
      return {
        ...state,
        reportTemplateResponseSets: state.reportTemplateResponseSets.map(
          (reportTemplateSet) => {
            if (!state.reportTemplateResponseSet) return reportTemplateSet;
            return reportTemplateSet.id === state.reportTemplateResponseSet.id
              ? {
                  ...reportTemplateSet,
                  responses: [
                    ...reportTemplateSet.responses,
                    thisAction.reportTemplateResponse,
                  ],
                }
              : reportTemplateSet;
          }
        ),
        reportTemplateResponseSet: {
          ...state.reportTemplateResponseSet,
          responses: [
            ...state.reportTemplateResponseSet.responses,
            thisAction.reportTemplateResponse,
          ],
        },
      };
    }

    case 'REPORT_TEMPLATE_RESPONSE_ON_CHANGE': {
      if (!state.reportTemplate || !state.reportTemplateResponseSet)
        return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateResponseValueOnChange
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.responseSet
                ? {
                    ...block,
                    responseSet: {
                      ...block.responseSet,
                      responses: block.responseSet.responses.map((response) =>
                        response.id === thisAction.reportTemplateResponseId
                          ? { ...response, ...thisAction.diff }
                          : response
                      ),
                    },
                    logic: block.logic.map((logic) => ({
                      ...logic,
                      logicConditionResponses:
                        logic.logicConditionResponses.map((response) =>
                          response.id === thisAction.reportTemplateResponseId
                            ? { ...response, ...thisAction.diff }
                            : response
                        ),
                    })),
                  }
                : block
            ),
          },
        },
        reportTemplateResponseSets: state.reportTemplateResponseSets.map(
          (reportTemplateSet) => {
            if (!state.reportTemplateResponseSet) return reportTemplateSet;
            return reportTemplateSet.id === state.reportTemplateResponseSet.id
              ? {
                  ...reportTemplateSet,
                  responses: reportTemplateSet.responses.map(
                    (reportTemplateResponse) =>
                      reportTemplateResponse.id ===
                      thisAction.reportTemplateResponseId
                        ? { ...reportTemplateResponse, ...thisAction.diff }
                        : reportTemplateResponse
                  ),
                }
              : reportTemplateSet;
          }
        ),
        reportTemplateResponseSet: {
          ...state.reportTemplateResponseSet,
          responses: state.reportTemplateResponseSet.responses.map(
            (reportTemplateResponse) =>
              reportTemplateResponse.id === thisAction.reportTemplateResponseId
                ? { ...reportTemplateResponse, ...thisAction.diff }
                : reportTemplateResponse
          ),
        },
      };
    }

    case 'DELETE_REPORT_TEMPLATE_RESPONSE_MUTATION_ON_COMPLETED': {
      if (!state.reportTemplateResponseSet) return state;
      const thisAction = action as ReturnType<
        typeof deleteReportTemplateResponseMutationOnCompleted
      >;
      if (!thisAction.deleted) return state;
      return {
        ...state,
        reportTemplateResponseSets: state.reportTemplateResponseSets.map(
          (reportTemplateSet) => {
            if (!state.reportTemplateResponseSet) return reportTemplateSet;
            return reportTemplateSet.id === state.reportTemplateResponseSet.id
              ? {
                  ...reportTemplateSet,
                  responses: reportTemplateSet.responses.filter(
                    (reportTemplateResponse) =>
                      reportTemplateResponse.id !== thisAction.deletedId
                  ),
                }
              : reportTemplateSet;
          }
        ),
        reportTemplateResponseSet: {
          ...state.reportTemplateResponseSet,
          responses: state.reportTemplateResponseSet.responses.filter(
            (reportTemplateResponse) =>
              reportTemplateResponse.id !== thisAction.deletedId
          ),
        },
      };
    }

    case 'REPORT_TEMPLATE_RESPONSE_ORDER_ON_CHANGE': {
      if (!state.reportTemplateResponseSet) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateResponseOrderOnChange
      >;
      return {
        ...state,
        reportTemplateResponseSets: state.reportTemplateResponseSets.map(
          (reportTemplateSet) => {
            if (!state.reportTemplateResponseSet) return reportTemplateSet;
            return reportTemplateSet.id === state.reportTemplateResponseSet.id
              ? {
                  ...reportTemplateSet,
                  responses: thisAction.reportTemplateResponses,
                }
              : reportTemplateSet;
          }
        ),
        reportTemplateResponseSet: {
          ...state.reportTemplateResponseSet,
          responses: thisAction.reportTemplateResponses,
        },
      };
    }

    case 'TOGGLE_REPORT_TEMPLATE_LOGIC_CONDITION_RESPONSE': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof toggleReportTemplateLogicConditionResponse
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.block.id
                ? {
                    ...block,
                    logic: block.logic.map((logic) =>
                      logic.id === thisAction.logic.id
                        ? {
                            ...logic,
                            logicConditionResponses: [
                              ...(logic.logicConditionResponses
                                .map((response) => response.id)
                                .includes(thisAction.response.id)
                                ? logic.logicConditionResponses.filter(
                                    (response) =>
                                      response.id !== thisAction.response.id
                                  )
                                : [
                                    ...logic.logicConditionResponses,
                                    thisAction.response,
                                  ]),
                            ].sort((a, b) => {
                              if (!block.responseSet) return 0;
                              return (
                                block.responseSet.responses.indexOf(a) -
                                block.responseSet.responses.indexOf(b)
                              );
                            }),
                          }
                        : logic
                    ),
                  }
                : block
            ),
          },
        },
      };
    }

    case 'UNDO_REPORT_TEMPLATE_UPDATE_MUTATION_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof undoReportTemplateUpdateMutationOnCompleted
      >;
      return {
        ...state,
        reportTemplate: thisAction.reportTemplate,
      };
    }

    default:
      return state;
  }
};

export default reducer;
