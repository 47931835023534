import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { useClient, useTable } from 'lib/hooks';
import { getId } from 'lib/utils';
import { ClientSite, JobIssue, ManagementTask } from 'lib/types';
import SimpleButton from '../SimpleButton';
import { MANAGEMENT_TASK_QUERY, MANAGEMENT_TASKS_QUERY } from './query';
import {
  managementTaskQueryOnCompleted,
  useReducerContext,
} from '../ClientSettingsManagement/reducer';
import {
  ManagementTaskQueryData,
  ManagementTaskQueryVariables,
} from '../ClientSettingsManagement/types';
import {
  ManagementTasksQueryData,
  ManagementTasksQueryVariables,
} from './types';
import SearchBar from '../SearchBar';
import Table from '../Table';
import { PriorityPill } from './styled';

const ClientSettingsManagementTaskTable = () => {
  const client = useClient();
  const [{ managementTasks }, dispatch] = useReducerContext();

  const [getManagementTasks] = useLazyQuery<
    ManagementTasksQueryData,
    ManagementTasksQueryVariables
  >(MANAGEMENT_TASKS_QUERY);

  const { searchInputProps } = useTable(
    ['name'],
    (variables) =>
      getManagementTasks({
        variables: { ...variables, clientId: client.id },
      }),
    {
      defaultActiveName: 'name',
      sortOnCompleted: () => {},
      searchOnCompleted: () => {},
      changePageCompleted: () => {},
    }
  );

  const [getManagementTask] = useLazyQuery<
    ManagementTaskQueryData,
    ManagementTaskQueryVariables
  >(MANAGEMENT_TASK_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(managementTaskQueryOnCompleted(data));
    },
  });

  const handleManagementTaskOnClick = (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    const managementTaskId = getId(event) as string;
    getManagementTask({ variables: { managementTaskId } });
  };

  const renderPriority = (priority: ManagementTask['priority']) => {
    return (
      <PriorityPill priority={priority}>
        <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
        {priority.toLowerCase()}
      </PriorityPill>
    );
  };

  const renderManagementTaskRow = (managementTask: ManagementTask) => {
    switch (managementTask.type) {
      case 'ENTER_SITE_LOCATION': {
        const data = managementTask.instance.data as ClientSite;
        return (
          <tr
            key={managementTask.id}
            data-id={managementTask.id}
            onClick={handleManagementTaskOnClick}
          >
            <td className="table-cell-with-button">
              {renderPriority(managementTask.priority)}
            </td>
            <td>{managementTask.name}</td>
            <td>{data.name}</td>
            <td className="table-cell-with-button d-flex justify-content-end pr-3 show-on-hover">
              <SimpleButton active icon={faLongArrowRight} iconSide="right">
                Click to fix
              </SimpleButton>
            </td>
          </tr>
        );
      }
      case 'STALE_JOB_ISSUE': {
        const data = managementTask.instance.data as JobIssue;
        return (
          <tr
            key={managementTask.id}
            data-id={managementTask.id}
            onClick={handleManagementTaskOnClick}
          >
            <td className="table-cell-with-button">
              {renderPriority(managementTask.priority)}
            </td>
            <td>{managementTask.name}</td>
            <td>
              <span className="mono">{data.job.reference}</span>{' '}
              {data.issueTypeDisplay}
            </td>
            <td className="table-cell-with-button d-flex justify-content-end pr-3 show-on-hover">
              <SimpleButton active icon={faLongArrowRight} iconSide="right">
                Click to fix
              </SimpleButton>
            </td>
          </tr>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="m-3">
        <SearchBar
          wrapperClassName="mr-0"
          {...searchInputProps}
          placeholder="Search..."
        />
      </div>
      <Table
        className="flex-grow-1"
        style={{ minHeight: 0 }}
        flexBases={[15, 20, 50, 15]}
      >
        <thead>
          <tr>
            <th>Priority</th>
            <th>Action required</th>
            <th>For</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {managementTasks.results.length > 0 ? (
            managementTasks.results.map(renderManagementTaskRow)
          ) : (
            <tr>
              <td>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faCheckCircle}
                  color="#106c12"
                />
                No outstanding management tasks
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ClientSettingsManagementTaskTable;
