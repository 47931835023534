import { useLazyQuery } from '@apollo/client';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons/faLayerGroup';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { JobGroup } from 'lib/types';
import { JOB_GROUP_QUERY } from './query';
import JobStatusFlag from '../JobStatusFlag';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import Table from '../Table';
import { Small } from '../Typography';
import {
  ClientJobGroupButtonProps,
  JobGroupQueryData,
  JobGroupQueryVariables,
} from './types';

const ClientJobGroupButton = ({ job }: ClientJobGroupButtonProps) => {
  const [jobGroup, setJobGroup] = useState<JobGroup>();
  const [getJobGroup] = useLazyQuery<JobGroupQueryData, JobGroupQueryVariables>(
    JOB_GROUP_QUERY,
    {
      onCompleted: (data) => {
        setJobGroup(data.jobGroup);
      },
    }
  );

  const handleJobGroupOnOpened = () => {
    getJobGroup({ variables: { jobGroupId: job?.group.id } });
  };

  return (
    <Link to={`/clients/${job.client.slug}/job-groups/${jobGroup?.reference}`}>
      <SimpleButtonWithWindow
        icon={faLayerGroup}
        inButtonToolbar
        windowChildren={({ close }) => (
          <div
            className="d-flex flex-column"
            style={{ width: 600, height: 400 }}
          >
            <div className="mt-3 mx-3">
              <Small>Ref: {jobGroup?.reference}</Small>
              <h5>{jobGroup?.name || job.name}</h5>
            </div>
            <div className="mx-3">
              <Small>Other jobs in this group</Small>
            </div>
            <div className="flex-grow-1" style={{ minHeight: 0 }}>
              <Table flexBases={[25, 25, 50]} className="h-100">
                <thead>
                  <tr>
                    <th>Reference</th>
                    <th>Status</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {jobGroup?.jobs.map((innerJob) => (
                    <tr key={innerJob.reference}>
                      <td>
                        <span className="mono">{innerJob.reference}</span>
                      </td>
                      <td>
                        <JobStatusFlag
                          className="mr-2"
                          flag={innerJob.status.flag}
                        />
                        {innerJob.status.nameDisplay}
                      </td>
                      <td>
                        <Link
                          to={`/clients/${job.client.slug}/jobs/${innerJob.reference}`}
                          onClick={close}
                        >
                          {innerJob.name}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
        onOpened={handleJobGroupOnOpened}
      >
        <span className="mono">{job.group.reference}</span>
      </SimpleButtonWithWindow>
    </Link>
  );
};

export default ClientJobGroupButton;
