import React from 'react';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';

const Context = React.createContext<{
  depth: number;
  draggableSnapshot: DraggableStateSnapshot;
}>({
  depth: 0,
  draggableSnapshot: {} as DraggableStateSnapshot,
});

export default Context;
