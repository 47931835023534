import { useDispatch } from 'react-redux';

const useToast = () => {
  const dispatch = useDispatch();
  return (type: 'SUCCESS' | 'WARNING', data: any) => {
    dispatch({ type: `ADD_${type}`, payload: data });
  };
};

export default useToast;
