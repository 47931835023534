import React from 'react';
import { faMapMarkedAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { parseDateTime } from '../../lib/utils';
import { useReducerContext } from '../ClientJob/reducer';
import SimpleButton from '../SimpleButton';
import { JobVisitWrapper } from './styled';

const ClientJobVisits = () => {
  const theme = useTheme();
  const [{ job }] = useReducerContext();

  if (!job) return null;
  return (
    <div>
      <div
        className="d-flex justify-content-end px-3 py-2"
        style={{ borderBottom: theme.border }}
      >
        <Link to={`/clients/${job.client.slug}/jobs/${job.reference}/map`}>
          <small style={{ display: 'block', lineHeight: '34px' }}>
            <SimpleButton
              className="text-75"
              icon={faMapMarkedAlt}
              hoverStyles={false}
            >
              View on map
            </SimpleButton>
          </small>
        </Link>
      </div>
      <div className="p-3">
        {job.visits.map((jobVisit) => (
          <JobVisitWrapper key={jobVisit.id} className="p-2">
            <div className="d-flex justify-content-between mb-2">
              <small>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faFlag}
                  fixedWidth
                  color={jobVisit.status.color}
                />
                <span className="text-75">{jobVisit.status.nameDisplay}</span>
              </small>
              <small className="mono text-75">
                {parseDateTime(jobVisit.dateTimeStart)} -{' '}
                {parseDateTime(jobVisit.dateTimeEnd)}
              </small>
            </div>
            <div className="d-flex justify-content-between">
              <h5>
                <span className="mono text-75">#{jobVisit.number}</span>{' '}
                {jobVisit.label}
              </h5>
              <div>{jobVisit.user.name}</div>
            </div>
          </JobVisitWrapper>
        ))}
      </div>
    </div>
  );
};

export default ClientJobVisits;
