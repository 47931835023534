import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ValueType } from 'react-select';
import { DataType } from 'lib/types';
import { getDataTypeDisplay } from 'lib/utils';
import Select from '../Select';
import { Label } from '../SelectField/styled';
import { Small } from '../Typography';

const DataTypeField = ({
  input,
  meta,
  label,
  noLabel = false,
}: FieldRenderProps<DataType>) => {
  const hasError = !!meta.touched && !!meta.error;

  const handleOnChange = (value: ValueType<any, false>) => {
    input.onChange(value.value);
  };

  return (
    <div>
      {!noLabel && (
        <Label
          htmlFor={input.name}
          className="d-inline-flex w-100 justify-content-between"
        >
          <Small>{label || input.name}</Small>
          {hasError && (
            <Small className="text-danger" capitalize={false}>
              {meta.error}
            </Small>
          )}
        </Label>
      )}
      <Select
        options={[
          {
            label: 'Number',
            value: 'NUMBER',
          },
          {
            label: 'Boolean',
            value: 'BOOLEAN',
          },
          {
            label: 'Date & Time',
            value: 'DATE_TIME',
          },
        ]}
        value={{
          label: getDataTypeDisplay(input.value),
          value: input.value,
        }}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default DataTypeField;
