import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { JobIssue } from 'lib/types';
import { parseDateTime } from 'lib/utils';
import JobIssuePill from '../JobIssuePill';
import JobPill from '../JobPill';
import ScrollDiv from '../ScrollDiv';
import { useReducerContext } from '../ClientDashboard/reducer';
import { Small } from '../Typography';
import Loader from './loader';
import { Wrapper } from './styled';

const ClientDashboardIssues = () => {
  const theme = useTheme();
  const [{ jobIssues }] = useReducerContext();

  if (!jobIssues?.results) return <Loader />;
  if (jobIssues?.results?.length === 0) {
    return <div className="p-2">No open issues</div>;
  }

  const renderJobIssueCard = (jobIssue: JobIssue) => {
    return (
      <div>
        <div className="mb-2 font-weight-bold">
          The target response time for this job was missed
        </div>
        <div className="d-flex">
          <div
            className={classNames('mr-3', {
              'text-75': jobIssue.status === 'CLOSED',
            })}
          >
            <Small>Target response</Small>
            <p className="mb-0 mono">
              {parseDateTime(jobIssue.job.originalTargetDateTimeStart)}
            </p>
          </div>
          <div
            className={classNames('mr-3', {
              'text-75': jobIssue.status === 'CLOSED',
            })}
          >
            <Small>Actual response</Small>
            <p className="mb-0 mono">
              <FontAwesomeIcon
                className="mr-1 text-danger"
                icon={faTimesCircle}
              />
              {parseDateTime(jobIssue.worstLogBlock?.data?.dateTimeStart)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ScrollDiv className="h-100" style={{ overflowY: 'auto' }}>
      {jobIssues.results.map((jobIssue) => {
        const href = `/clients/${jobIssue.job.client.slug}/jobs/${jobIssue.job.reference}/issues/${jobIssue.number}`;
        return (
          <Link key={jobIssue.id} to={href}>
            <Wrapper
              href={href}
              className="p-2"
              style={{ borderBottom: theme.border }}
            >
              <div className="mb-2">
                <JobPill
                  className="mr-2"
                  job={jobIssue.job}
                  style={{ backgroundColor: 'transparent' }}
                />
                <JobIssuePill jobIssue={jobIssue} />
              </div>
              <small>{renderJobIssueCard(jobIssue)}</small>
            </Wrapper>
          </Link>
        );
      })}
    </ScrollDiv>
  );
};

export default ClientDashboardIssues;
