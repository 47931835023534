import classNames from 'classnames';
import React, { useRef } from 'react';
import StaticGridLayout, {
  WidthProvider,
  Layout as LayoutType,
} from 'react-grid-layout';
import { generateId } from '../../../utils';
import { BlockDefinition } from '../BlockToolbox/types';
import BlockToolboxSidePanel from '../BlockToolboxSidePanel';
import { Wrapper } from './styled';
import { LayoutProps, Block as BlockType } from './types';
import Block from '../Block';

const GridLayout = WidthProvider(StaticGridLayout);

export const LayoutResizableHandle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, style, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      style={style}
      className={classNames('react-resizable-handle', className)}
      {...rest}
    />
  );
});

const Layout = ({ layout, onLayoutChange, ...rest }: LayoutProps) => {
  const draggedBlockRef = useRef<BlockDefinition>();

  const handleOnDrop = (newLayout: LayoutType[]) => {
    if (!onLayoutChange) return;
    if (!draggedBlockRef.current) return;

    onLayoutChange(
      newLayout.map((item) => {
        return item.i === '__dropping-elem__'
          ? {
              ...item,
              data: {},
              type: draggedBlockRef?.current?.type,
              i: generateId(),
            }
          : item;
      })
    );
  };

  const handleOnDropDragOver = () => {
    if (draggedBlockRef.current) {
      return draggedBlockRef.current.layout;
    }

    return { w: 3, h: 3 };
  };

  const handleUpdateBlock = (block: BlockType, diff: Partial<BlockType>) => {
    if (!onLayoutChange) return;

    onLayoutChange(
      layout.map((innerBlock) => {
        if (innerBlock.i === block.i) {
          return {
            ...block,
            ...innerBlock,
            ...diff,
          };
        }

        return innerBlock;
      })
    );
  };

  const handleBlockOnClose = (block: BlockType) => {
    if (!onLayoutChange) return;
    onLayoutChange(layout.filter((innerBlock) => innerBlock.i !== block.i));
  };

  return (
    <Wrapper>
      {layout && (
        <GridLayout
          className="h-100"
          layout={layout}
          cols={12}
          rowHeight={50}
          measureBeforeMount
          draggableHandle=".react-grid-layout-drag-handle"
          isDroppable
          isResizable
          resizeHandle={<LayoutResizableHandle />}
          onDrop={handleOnDrop}
          onDropDragOver={handleOnDropDragOver}
          onLayoutChange={onLayoutChange}
          {...rest}
        >
          {layout.map((block) => (
            <div key={block.i}>
              <Block
                block={block}
                actions={{
                  updateBlock: handleUpdateBlock,
                }}
                controls={{
                  onClose: handleBlockOnClose,
                }}
              />
            </div>
          ))}
        </GridLayout>
      )}
      <BlockToolboxSidePanel
        layout={layout}
        draggedBlockRef={draggedBlockRef}
      />
    </Wrapper>
  );
};

export default Layout;
