import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { removeElements } from 'react-flow-renderer';
import ContextMenu from '../../../ContextMenu';
import { useDerivationEditorContext } from '../../context';
import { NodeProps } from './types';
import { NodeWrapper } from './styled';

const NodeBase = ({
  children,
  wrapperProps = {},
  contextMenuProps,
  ...node
}: NodeProps) => {
  const { derivationOnChange } = useDerivationEditorContext();
  const { id } = node;

  const handleDeleteOnClick = useCallback(() => {
    derivationOnChange({
      derivation: (prevDerivation) => removeElements([node], prevDerivation),
    });
  }, [node, derivationOnChange]);

  const getOptions = useCallback(() => {
    const ret = [];

    if (node.type !== 'OUTPUT') {
      ret.push({
        label: 'Delete',
        value: 'DELETE',
        data: { node },
        children: (
          <>
            <FontAwesomeIcon className="mr-1" icon={faTimes} fixedWidth />
            Delete
          </>
        ),
        onClick: handleDeleteOnClick,
      });
    }

    return ret;
  }, [node, handleDeleteOnClick]);

  const options = getOptions();

  return (
    <ContextMenu
      {...contextMenuProps}
      id={id}
      animation="fade"
      style={{ zIndex: 9999 }}
      options={options}
    >
      {({ show }) => (
        <NodeWrapper
          {...wrapperProps}
          onContextMenu={(event) => {
            if (options.length === 0) return;
            const rect = event.currentTarget.getBoundingClientRect();
            show(event, {
              position: {
                x: rect.x,
                y: rect.y + rect.height,
              },
            });
          }}
        >
          {children}
        </NodeWrapper>
      )}
    </ContextMenu>
  );
};

export default NodeBase;
