import styled from 'styled-components';

export const Line = styled.div`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  height: 24px;
  border-radius: 0.25rem;
`;

export const getRandomWidth = (min = 20, max = 80) => {
  const width = Math.random() * 100;
  return `${Math.min(Math.max(width, min), max)}%`;
};
