import { useState } from 'react';
import { ViewState } from 'react-map-gl';
import { DeckProps } from '@deck.gl/core/lib/deck';

const useMap = () => {
  const [viewState, setViewState] = useState<ViewState>({
    longitude: -0.115,
    latitude: 51.5074,
    zoom: 12,
    pitch: 45,
    bearing: -10,
  });

  const handleViewStateOnChange: DeckProps['onViewStateChange'] = ({
    viewState: nextViewState,
  }) => {
    setViewState(nextViewState);
  };

  const handleUpdateViewState = (partialViewState: Partial<ViewState>) => {
    setViewState((prevViewState) => ({
      ...prevViewState,
      ...partialViewState,
    }));
  };

  return {
    mapProps: {
      viewState,
      viewStateOnChange: handleViewStateOnChange,
    },
    updateViewState: handleUpdateViewState,
  };
};

export default useMap;
