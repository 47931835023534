import styled from 'styled-components';

export const TemplateBlockControlsWrapper = styled.div`
  position: relative;
  padding: 0.25rem;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  z-index: 2;
  border-radius: 0 0 0 0.25rem;
`;
