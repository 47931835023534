import { gql } from '@apollo/client';

const JOB_FRAGMENT = gql`
  fragment JobFields on Job {
    id
    reference
    name
    site {
      name
    }
    status {
      nameDisplay
      flag {
        color
      }
    }
    assignees {
      name
      initials
    }
  }
`;

export const JOBS_QUERY = gql`
  ${JOB_FRAGMENT}
  query ($searchTerm: String) {
    jobs(searchTerm: $searchTerm) {
      results {
        ...JobFields
      }
      count
    }
  }
`;

export const JOB_QUERY = gql`
  ${JOB_FRAGMENT}
  query ($jobId: ID!) {
    job(jobId: $jobId) {
      ...JobFields
    }
  }
`;
