import React from 'react';
import { Link } from 'react-router-dom';
import { useReportColor } from 'lib/hooks';
import { JobReportPillProps } from './types';
import { Wrapper } from './styled';

const JobReportPill = ({
  jobReport,
  statusOverride,
  as = 'a',
  ...rest
}: JobReportPillProps) => {
  const color = useReportColor(statusOverride ?? jobReport.status);
  const href = `/clients/${jobReport.client.slug}/jobs/${jobReport.job.reference}/reports/${jobReport.number}`;
  return (
    <Link to={href}>
      <Wrapper as={as} {...rest}>
        <div className="d-flex">
          <div
            className="job-report-pill-number"
            style={{ backgroundColor: color }}
          >
            <span className="mono text-100">#{jobReport.number}</span>
          </div>
          <div className="job-report-pill-name">{jobReport.template.name}</div>
        </div>
      </Wrapper>
    </Link>
  );
};

export default JobReportPill;
