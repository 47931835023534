import NodeAbsolute from './NodeAbsolute';
import NodeAnd from './NodeAnd';
import NodeConstant from './NodeConstant';
import NodeDivide from './NodeDivide';
import NodeInput from './NodeInput';
import NodeLessThan from './NodeLessThan';
import NodeMultiply from './NodeMultiply';
import NodeNot from './NodeNot';
import NodeOr from './NodeOr';
import NodeOutput from './NodeOutput';
import NodeAdd from './NodeAdd';
import NodeScope from './NodeScope';
import NodeSubtract from './NodeSubtract';

const nodes = {
  INPUT: NodeInput,
  OUTPUT: NodeOutput,
  CONSTANT: NodeConstant,

  // Unary operations
  ABSOLUTE: NodeAbsolute,

  // Binary operations
  ADD: NodeAdd,
  SUBTRACT: NodeSubtract,
  MULTIPLY: NodeMultiply,
  DIVIDE: NodeDivide,

  // Unary logical operations
  NOT: NodeNot,

  // Binary logical operations
  AND: NodeAnd,
  OR: NodeOr,

  // Comparisons
  LESS_THAN: NodeLessThan,
  LESS_THAN_EQUAL: NodeLessThan,

  // Misc
  SCOPE: NodeScope,
};

export default nodes;
