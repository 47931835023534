import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { Node } from 'react-flow-renderer';
import NodeBinary from '../NodeBinary';

const NodeAdd = (node: Node) => {
  return (
    <NodeBinary {...node}>
      <FontAwesomeIcon icon={faPlus} fixedWidth />
    </NodeBinary>
  );
};

export default NodeAdd;
