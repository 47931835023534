import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleButton from '../SimpleButton';
import { SimpleButtonSquareProps } from './types';

const SimpleButtonSquare = ({
  children,
  icon,
  iconProps,
  style,
  ...rest
}: SimpleButtonSquareProps) => {
  return (
    <SimpleButton style={{ ...style, height: 'auto' }} {...rest}>
      <div className="mt-1">
        <FontAwesomeIcon icon={icon} {...iconProps} />
      </div>
      <div className="mb-1" style={{ fontSize: '0.65rem' }}>
        {children}
      </div>
    </SimpleButton>
  );
};

export default SimpleButtonSquare;
