import classNames from 'classnames';
import React from 'react';
import CardWrapper from 'components/CardWrapper';
import { BlockToolboxCardIncomingJobs } from '../BlockIncomingJobs';
import { BlockToolboxCardJob } from '../BlockJob';
import { BlockToolboxCardUserNotes } from '../BlockUserNotes';
import { BlockToolboxCardWelcome } from '../BlockWelcome';
import { BlockDefinition, BlockToolBoxProps } from './types';

const BLOCKS: BlockDefinition[] = [
  {
    type: 'WELCOME',
    description: 'A nice welcome',
    limit: 1,
    layout: {
      w: 4,
      h: 4,
    },
  },
  {
    type: 'INCOMING_JOBS',
    description: 'View all incoming jobs',
    layout: {
      w: 4,
      h: 4,
    },
  },
  {
    type: 'OPEN_ISSUES',
    description: 'View all open issues',
    layout: {
      w: 2,
      h: 2,
    },
  },
  {
    type: 'JOB',
    description: 'Track a particular job',
    layout: {
      w: 2,
      h: 2,
    },
  },
  {
    type: 'USER_NOTES',
    description: 'All your notes',
    limit: 1,
    layout: {
      w: 4,
      h: 4,
    },
  },
];

const BlockToolbox = ({ layout, draggedBlockRef }: BlockToolBoxProps) => {
  const handleOnDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const { blockType } = event.currentTarget.dataset;
    if (blockType) {
      // eslint-disable-next-line no-param-reassign
      draggedBlockRef.current = BLOCKS.find(
        (innerBlock) => innerBlock.type === blockType
      ) as BlockDefinition;
    }
  };

  const renderBlockToolboxCard = (blockDefinition: BlockDefinition) => {
    switch (blockDefinition.type) {
      case 'WELCOME':
        return <BlockToolboxCardWelcome />;
      case 'INCOMING_JOBS':
        return (
          <BlockToolboxCardIncomingJobs blockDefinition={blockDefinition} />
        );
      case 'JOB':
        return <BlockToolboxCardJob blockDefinition={blockDefinition} />;
      case 'USER_NOTES':
        return <BlockToolboxCardUserNotes />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        {BLOCKS.filter((blockDefinition) => {
          if (!blockDefinition.limit) return true;
          const count = layout.filter(
            (block) => block.type === blockDefinition.type
          ).length;
          return count < blockDefinition.limit;
        }).map((blockDefinition, index) => {
          const last = index === BLOCKS.length - 1;
          return (
            <CardWrapper
              key={blockDefinition.type}
              className={classNames({ 'mb-3': !last })}
              data-block-type={blockDefinition.type}
              draggable
              unselectable="on"
              onDragStart={handleOnDrag}
            >
              {renderBlockToolboxCard(blockDefinition)}
            </CardWrapper>
          );
        })}
      </div>
    </div>
  );
};

export default BlockToolbox;
