import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { useClient } from 'lib/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { useReducerContext } from '../ClientJobGroup/reducer';
import PortalBreadcrumb from '../PortalBreadcrumb';

const ClientJobGroupBreadcrumb = ({ loading }: { loading: boolean }) => {
  const [{ jobGroup }] = useReducerContext();
  const client = useClient();
  if (!jobGroup) {
    return (
      <PortalBreadcrumb>
        <div className="d-flex">
          <Link to={`/clients/${client.slug}/jobs`}>
            <h4 className="text-75 client__tab-bar-breadcrumb-link">
              PPM Jobs
            </h4>
          </Link>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <FontAwesomeIcon
            style={{ fontSize: '1.25rem', marginTop: 5 }}
            icon={faSpinnerThird}
            fixedWidth
            spin
          />
        </div>
      </PortalBreadcrumb>
    );
  }
  return (
    <PortalBreadcrumb>
      <Routes>
        <Route
          path="/clients/:slug/job-groups/:rest*"
          element={
            <div className="d-flex">
              <Link to={`/clients/${jobGroup?.client?.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  PPM Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              {loading ? (
                <FontAwesomeIcon
                  style={{ fontSize: '1.25rem', marginTop: 5 }}
                  icon={faSpinnerThird}
                  fixedWidth
                  spin
                />
              ) : (
                <h4 className="mono">{jobGroup?.reference}</h4>
              )}
            </div>
          }
        />
      </Routes>
    </PortalBreadcrumb>
  );
};

export default ClientJobGroupBreadcrumb;
