import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBreadcrumb } from '../Layout';

const PortalBreadcrumb = ({ children }: React.PropsWithChildren<unknown>) => {
  const breadcrumbRef = useBreadcrumb();

  if (!breadcrumbRef?.current) return null;

  return ReactDOM.createPortal(children, breadcrumbRef.current);
};

export default PortalBreadcrumb;
