import { gql } from '@apollo/client';

export const DELETE_REPORT_TEMPLATE_BLOCK_MUTATION = gql`
  mutation ($reportTemplateBlockId: ID!) {
    deleteReportTemplateBlock(reportTemplateBlockId: $reportTemplateBlockId) {
      deleted
      deletedId
    }
  }
`;
