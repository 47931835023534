import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { Handle, Node, Position } from 'react-flow-renderer';
import { ValueType } from 'react-select/src/types';
import Switch from 'react-switch';
import { useTheme } from 'styled-components';
import { useClient } from 'lib/hooks';
import { ButtonMultiInner } from '../../../ButtonMulti';
import SelectAsyncCreatable from '../../../SelectAsyncCreatable';
import SimpleButtonWithWindow from '../../../SimpleButtonWithWindow';
import { WindowChildrenProps } from '../../../SimpleButtonWithWindow/types';
import { useDerivationEditorContext } from '../../context';
import { renderValueType } from '../../utils';
import NodeBase from '../NodeBase';
import { Small } from '../../../Typography';
import { SETTINGS_VARIABLES_QUERY } from './query';
import {
  SettingsVariablesQueryData,
  SettingsVariablesQueryVariables,
} from './types';

const NodeConstant = (node: Node) => {
  const theme = useTheme();
  const client = useClient();
  const { derivationOnChange } = useDerivationEditorContext();
  const { id, data } = node;

  const updateData = (newData: any) => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        prevDerivation.map((element) =>
          element.id === id
            ? {
                ...element,
                data: { ...element.data, ...newData },
              }
            : element
        ),
    });
  };

  const handleNumberValueOnChange = (value: ValueType<any, false>) => {
    updateData({
      select: value,
      value: value.settingsVariableValue,
      settingsVariableId: value.settingsVariableId,
      // eslint-disable-next-line no-underscore-dangle
      constantType: value.__isnew__ ? 'CONSTANT' : 'SETTINGS_VARIABLE',
    });
  };

  const handleBooleanValueOnChange = () => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        prevDerivation.map((element) =>
          element.id === id
            ? {
                ...element,
                data: { ...element.data, value: !element.data.value },
              }
            : element
        ),
    });
  };

  const handleUpdateValueType = (dataType: string) => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        prevDerivation.map((element) =>
          element.id === id
            ? { ...element, data: { ...element.data, dataType } }
            : element
        ),
    });
  };

  const renderWindowChildren = ({ close }: WindowChildrenProps) => {
    return (
      <div>
        <ButtonMultiInner
          options={[
            {
              label: 'Number',
              onClick: () => {
                handleUpdateValueType('NUMBER');
                close();
              },
            },
            {
              label: 'Boolean',
              onClick: () => {
                handleUpdateValueType('BOOLEAN');
                close();
              },
            },
          ]}
        />
      </div>
    );
  };

  const [getSettingsVariables] = useLazyQuery<
    SettingsVariablesQueryData,
    SettingsVariablesQueryVariables
  >(SETTINGS_VARIABLES_QUERY);

  const handleLoadOptions = async (searchTerm: string) => {
    const { data: searchData } = await getSettingsVariables({
      variables: { clientId: client.id, searchTerm },
    });
    if (searchData) {
      return searchData.settingsVariables.map((variable) => ({
        label: variable.name,
        value: variable.id,
        settingsVariableId: variable.id,
        settingsVariableValue: variable.value,
      }));
    }
    return [];
  };

  const renderSelector = () => {
    switch (data.dataType) {
      case 'NUMBER':
        return (
          <SelectAsyncCreatable
            createOptionPosition="first"
            value={data.select}
            customStyles={{ menu: { width: 'auto', maxWidth: 500 } }}
            cacheOptions
            loadOptions={handleLoadOptions}
            onChange={handleNumberValueOnChange}
          />
        );
      case 'BOOLEAN':
        return (
          <div>
            <Switch
              onColor="#106c12"
              checked={data.value}
              onChange={handleBooleanValueOnChange}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <NodeBase wrapperProps={{ style: { padding: '0.5rem' } }} {...node}>
      <div className="d-flex justify-content-between mb-2">
        <Small className="mr-4" dark>
          Constant
        </Small>
        <SimpleButtonWithWindow
          style={{ color: theme.color.cardBackground.hex(), height: 'auto' }}
          openMode="click"
          small
          hoverStyles={false}
          windowChildren={renderWindowChildren}
        >
          {renderValueType(data.dataType)}
        </SimpleButtonWithWindow>
      </div>
      <div>{renderSelector()}</div>
      <Handle id={`source-1-${id}`} type="source" position={Position.Bottom} />
    </NodeBase>
  );
};

export default NodeConstant;
