import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useClient } from 'lib/hooks';
import ClientSearch from '../ClientSearch';
import { TabProps } from './types';
import { TabBarWrapper, TabBarInner, TabWrapper } from './styled';
import Breadcrumb from '../Breadcrumb';

const Tab = ({ name, tab, path }: TabProps) => {
  const client = useClient();
  const location = useLocation();
  const match = Array.isArray(path)
    ? path.some((innerPath) => matchPath(innerPath, location.pathname))
    : matchPath(path, location.pathname);
  const to = `/clients/${client.slug}${tab === '' ? '' : `/${tab}`}`;
  return (
    <TabWrapper to={to} active={!!match}>
      <span>{name}</span>
    </TabWrapper>
  );
};

const ClientTabBar = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  const client = useClient();

  return (
    <TabBarWrapper className="pl-4">
      <div className="center-vertically h-100">
        <div className="d-flex h-100">
          <Breadcrumb.Wrapper
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Breadcrumb.Link to={`/clients/${client?.slug}`}>
              {client.name}
            </Breadcrumb.Link>
            <Breadcrumb.Separator />
            <div ref={breadcrumbRef} />
          </Breadcrumb.Wrapper>
          <div className="center-vertically flex-grow-1 mr-2">
            <div className="d-flex justify-content-end">
              <ClientSearch />
            </div>
          </div>
        </div>
      </div>
      <TabBarInner className="d-flex">
        <Tab name="Dashboard" tab="" path="/clients/:slug" />
        <Tab
          name="Jobs"
          tab="jobs"
          path={['/clients/:slug/jobs/*', '/clients/:slug/job-groups/*']}
        />
        <Tab
          name={
            <span>
              {client?.immediateActionCount > 0 ? (
                <span className="mono text-danger">
                  {client?.immediateActionCount}
                </span>
              ) : (
                <span className="mono text-primary">0</span>
              )}{' '}
              Immediate Actions
            </span>
          }
          tab="immediate-actions"
          path="/clients/:slug/immediate-actions/*"
        />
        <Tab name="Sites" tab="sites" path="/clients/:slug/sites/*" />
        <Tab name="People" tab="people" path="/clients/:slug/people/*" />
        <Tab name="Settings" tab="settings" path="/clients/:slug/settings/*" />
      </TabBarInner>
    </TabBarWrapper>
  );
};

export default ClientTabBar;
