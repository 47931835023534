import { useState } from 'react';
import { Layout } from 'react-grid-layout';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Block } from '../layout/components/Layout/types';
import { Page } from '../types';
import { generateId } from '../utils';

const PAGE_QUERY = gql`
  query ($pageName: String!) {
    webPage(webPageName: $pageName) {
      id
      layout
    }
  }
`;

type PageQueryVariables = {
  pageName: Page['name'];
};

type PageQueryData = {
  webPage: Page;
};

const UPDATE_WEB_PAGE_MUTATION = gql`
  mutation ($pageId: ID, $pageName: String!, $layout: JSONScalarInput!) {
    updateWebPage(webPageId: $pageId, webPageName: $pageName, layout: $layout) {
      webPage {
        id
      }
    }
  }
`;

type UpdatePageLayoutMutationVariables = {
  pageId: Page['id'] | null;
  pageName: Page['name'];
  layout: Page['layout'];
};

type UpdatePageLayoutMutationData = {
  updateWebPage: {
    webPage: Page;
  };
};

type Return = [
  Page['layout'],
  (newLayout: Block[]) => void,
  { loading: boolean }
];

const usePageLayout = (
  pageName: string,
  defaultLayout: Omit<Block, 'i'>[]
): Return => {
  const cachedLayout = localStorage.getItem(`Page${pageName}Layout`);
  const [pageId, setPageId] = useState<Page['id']>();
  const [pageLayout, setPageLayout] = useState<Page['layout']>(() => {
    if (cachedLayout) {
      return JSON.parse(cachedLayout) as Block[];
    }

    return [];
  });

  const { loading } = useQuery<PageQueryData, PageQueryVariables>(PAGE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { pageName },
    onCompleted: (data) => {
      if (data.webPage) {
        const { id, layout } = data.webPage;

        localStorage.setItem(`Page${pageName}Layout`, JSON.stringify(layout));
        setPageId(id);
        setPageLayout(layout);
      } else {
        setPageLayout(
          defaultLayout.map((block) => ({ ...block, i: generateId() }))
        );
      }
    },
  });

  const [updatePage] = useMutation<
    UpdatePageLayoutMutationData,
    UpdatePageLayoutMutationVariables
  >(UPDATE_WEB_PAGE_MUTATION);

  const [canChange, setCanChange] = useState(false);

  const handlePageLayoutOnChange = async (newLayout: Layout[]) => {
    if (!canChange) {
      setCanChange(true);
      return;
    }

    const newBlocks = newLayout.map((item) => {
      const block = pageLayout.find((innerBlock) => innerBlock.i === item.i);
      return { ...block, ...item } as Block;
    });

    localStorage.setItem(`Page${pageName}Layout`, JSON.stringify(newBlocks));
    setPageLayout(newBlocks);

    const { data } = await updatePage({
      variables: { pageId: pageId ?? null, pageName, layout: newBlocks },
    });
    if (data) {
      setPageId(data.updateWebPage.webPage.id);
    }
  };

  return [
    pageLayout,
    handlePageLayoutOnChange,
    { loading: loading && !cachedLayout },
  ];
};

export default usePageLayout;
