import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
// @ts-ignore
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import {
  SchedulerPro,
  SchedulerEventModel,
  SchedulerProConfig,
  EventStore,
  Store,
  ProjectModel,
  SchedulerResourceModel,
  // eslint-disable-next-line import/extensions
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { DateTime } from 'luxon';
import {
  useClient,
  useCaptureServerError,
  useSessionId,
  useWebsocketUri,
} from 'lib/hooks';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { JobVisit, JobVisitNumber } from 'lib/types';
import { useToggle } from 'react-use';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import { INITIAL_QUERY, JOB_VISIT_QUERY, JOB_VISIT_ROUTE_QUERY } from './query';
import {
  InitialQueryData,
  InitialQueryVariables,
  JobVisitSchedulerProps,
} from './types';
import JobVisitSchedulerCreateSidePanel from '../JobVisitSchedulerCreateSidePanel';
import JobVisitSchedulerEditSidePanel from '../JobVisitSchedulerEditSidePanel';
import { Drag, UnassignedGridComponent } from './drag';
import {
  schedulerConfig,
  addResources,
  addDeadlines,
  addEotRequest,
  addAssignedVisits,
  addUnassignedVisits,
  fetchMoreVisitsOnCompleted,
  getRouteOnCompleted,
  applyReversions,
} from './utils';
import JobVisitSchedulerControls from '../JobVisitSchedulerControls';
import { renderColumn, renderEvent, renderTooltip } from './renderers';
import { useInitialDates } from './hooks';

const JobVisitScheduler = ({
  job,
  readOnly,
  selectMode,
  visitSelectOffset,
  visitOnCreate,
  visitOnChange,
  visitOnRemove,
  visitOnSelect,
  timeAxisOnChange,
}: JobVisitSchedulerProps) => {
  const theme = useTheme();
  const client = useClient();

  const gridRef = useRef<UnassignedGridComponent>(null);
  const schedulerRef = useRef<{ instance: SchedulerPro }>(null);

  const [createSidePanelIsOpen, toggleCreateSidePanel] = useToggle(false);
  const [editSidePanelIsOpen, toggleEditSidePanel] = useToggle(false);

  const [currentEventRecord, setCurrentEventRecord] =
    useState<SchedulerEventModel | null>(null);

  const {
    initialViewDateTimes,
    initialTargetDateTimeStart,
    initialTargetDateTimeEnd,
  } = useInitialDates(job);

  const [renderUnassignedGrid, setRenderUnassignedGrid] = useState(false);
  const [showUnassignedGrid, setShowUnassignedGrid] = useState(true);

  const handleToggleUnassignedGrid = () => {
    setShowUnassignedGrid((prevShowUnassignedGrid) => !prevShowUnassignedGrid);
  };

  const [schedulerProject] = useState(new ProjectModel());

  const [unassignedGridEventStore] = useState(
    new EventStore({
      listeners: {
        change: ({ source }: { source: EventStore }) => {
          setRenderUnassignedGrid(source.records.length > 0);
        },
      },
    })
  );

  const handleUpdateEvent = useCallback(
    (draggedEvent) => {
      if (!schedulerRef.current || readOnly) return null;
      return visitOnChange({
        id: draggedEvent.id as string,
        dateTimeStart: DateTime.fromJSDate(
          draggedEvent.startDate as Date
        ).toISO(),
        dateTimeEnd: DateTime.fromJSDate(draggedEvent.endDate as Date).toISO(),
        userId: draggedEvent.resourceId as string,
      });
    },
    [readOnly, visitOnChange]
  );

  useEffect(() => {
    if (readOnly || !renderUnassignedGrid || !showUnassignedGrid) return;
    // eslint-disable-next-line no-new
    new Drag({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      grid: gridRef.current.unassignedGrid,
      schedule: schedulerRef.current?.instance,
      constrain: false,
      outerElement: gridRef.current?.unassignedGrid.element,
      updateEvent: handleUpdateEvent,
    });
  }, [readOnly, handleUpdateEvent, renderUnassignedGrid, showUnassignedGrid]);

  const sessionId = useSessionId();
  const websocketUri = useWebsocketUri('job_visit/');
  useEffect(() => {
    const websocket = new WebSocket(websocketUri);
    websocket.onmessage = (rawResponse) => {
      if (!schedulerRef.current) return;
      const response = { data: JSON.parse(rawResponse.data) };

      const { jobVisit, sessionId: sessionIdUsedForUpdate } =
        response.data.jobVisitSubscription;

      if (sessionId === sessionIdUsedForUpdate) return;

      const eventRecord = schedulerRef.current.instance.eventStore.getById(
        jobVisit.id
      ) as SchedulerEventModel;

      if (!eventRecord) return;

      eventRecord.startDate = DateTime.fromISO(
        jobVisit.dateTimeStart
      ).toJSDate();
      eventRecord.endDate = DateTime.fromISO(jobVisit.dateTimeEnd).toJSDate();
      eventRecord.resourceId = jobVisit.user;
    };
    return () => {
      websocket.close();
    };
  }, [sessionId, websocketUri]);

  const { fetchMore } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        dateTimeStart: initialViewDateTimes.dateTimeStart.toISO(),
        dateTimeEnd: initialViewDateTimes.dateTimeEnd.toISO(),
        jobId: job.id,
      },
      onCompleted: (data) => {
        if (!schedulerRef.current) return;
        addResources(data.userGroups, schedulerProject, theme);
        addDeadlines(
          job,
          initialViewDateTimes.dateTimeStart,
          initialTargetDateTimeEnd,
          initialTargetDateTimeStart,
          schedulerProject
        );
        if (job?.activeEotRequest) {
          addEotRequest(job.activeEotRequest, schedulerProject);
        }
        addAssignedVisits(data.assignedVisits, job, schedulerProject);
        addUnassignedVisits(data.unassignedVisits, unassignedGridEventStore);

        if (job.visitsInView.length > 0) {
          const resourceRecord =
            schedulerRef.current.instance.resourceStore.getById(
              job.visitsInView[0].user.id
            );
          if (resourceRecord) {
            schedulerRef.current.instance.scrollResourceIntoView(
              resourceRecord as SchedulerResourceModel,
              { block: 'center' }
            );
          }
        }
      },
    }
  );

  useEffect(() => {
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.setTimeSpan(
      DateTime.fromISO(job.viewDateTimeStart).toJSDate(),
      DateTime.fromISO(job.viewDateTimeEnd).toJSDate()
    );
  }, [job.viewDateTimeStart, job.viewDateTimeEnd]);

  const handleOnForward = (unit: string) => {
    if (!schedulerRef.current) return;
    schedulerRef.current?.instance.shift(1, unit);
  };

  const handleOnBackward = (unit: string) => {
    if (!schedulerRef.current) return;
    schedulerRef.current?.instance.shift(-1, unit);
  };

  const handleOnTimeAxisChange = ({
    source,
    config,
  }: {
    source: SchedulerPro;
    config: SchedulerProConfig;
  }) => {
    const newDateTimeStart = DateTime.fromJSDate(config.startDate as Date);
    const newDateTimeEnd = DateTime.fromJSDate(config.endDate as Date);

    if (timeAxisOnChange) {
      timeAxisOnChange({
        dateTimeStart: newDateTimeStart,
        dateTimeEnd: newDateTimeEnd,
      });
    }

    fetchMore({
      variables: {
        dateTimeStart: newDateTimeStart.toISO(),
        dateTimeEnd: newDateTimeEnd.toISO(),
      },
    }).then((response) => {
      fetchMoreVisitsOnCompleted(response, job, schedulerProject);
    });
  };

  const captureServerError = useCaptureServerError();

  const handleOnAfterEventDrop = useCallback(
    ({
      draggedRecords,
      valid,
    }: {
      draggedRecords: SchedulerEventModel[];
      valid: boolean;
    }) => {
      if (readOnly || !valid) return;
      const [draggedEvent] = draggedRecords;
      handleUpdateEvent(draggedEvent)
        ?.then((result) => {
          if (!result) return result;
          const [, responseData] = result;
          responseData.updateJobVisit.jobVisitNumbers.forEach(
            (jobVisitNumber: JobVisitNumber) => {
              schedulerRef.current?.instance.eventStore
                .getById(jobVisitNumber.id)
                .set('number', jobVisitNumber.number);
            }
          );
          return result;
        })
        .catch((error) => {
          const [graphqlError] = error.graphQLErrors;
          const { reversions } = graphqlError;
          applyReversions(draggedEvent, reversions);
          captureServerError(graphqlError);
        });
    },
    [readOnly, handleUpdateEvent, captureServerError]
  );

  const handleOnEventResizeEnd = useCallback(
    ({
      changed,
      eventRecord,
    }: {
      changed: boolean;
      eventRecord: SchedulerEventModel;
    }) => {
      if (readOnly || !changed) return;
      handleUpdateEvent(eventRecord)?.catch((error) => {
        const [graphqlError] = error.graphQLErrors;
        const { reversions } = graphqlError;
        applyReversions(eventRecord, reversions);
        captureServerError(graphqlError);
      });
    },
    [captureServerError, handleUpdateEvent, readOnly]
  );

  const handleOnDestroy = useCallback(
    ({ eventRecords }: { eventRecords: SchedulerEventModel[] }) => {
      if (readOnly) return false;
      const [eventRecord] = eventRecords;
      visitOnRemove(eventRecord.id as string)
        .then((result) => {
          if (!result) return result;
          const [, responseData] = result;
          responseData.deleteJobVisit.jobVisitNumbers.forEach(
            (jobVisitNumber: JobVisitNumber) => {
              schedulerRef.current?.instance.eventStore
                .getById(jobVisitNumber.id)
                .set('number', jobVisitNumber.number);
            }
          );
          return result;
        })
        .catch((error) => {
          if (!error.graphQLErrors) throw error;
          if (!schedulerRef.current) return;
          const [graphqlError] = error.graphQLErrors;
          captureServerError(graphqlError);
          schedulerRef.current.instance.eventStore.add(eventRecord);
        });
      return true;
    },
    [captureServerError, readOnly, visitOnRemove]
  );

  const handleOnBeforeTaskEdit = useCallback(
    ({ taskRecord }: { taskRecord: SchedulerEventModel }) => {
      if (readOnly) return false;
      if (taskRecord.isPhantom) {
        toggleCreateSidePanel(true);
      } else {
        toggleEditSidePanel(true);
      }
      setCurrentEventRecord(taskRecord);
      return false;
    },
    [readOnly, toggleCreateSidePanel, toggleEditSidePanel]
  );

  const [getRoute] = useLazyQuery(JOB_VISIT_ROUTE_QUERY, {
    onCompleted: (data) => {
      getRouteOnCompleted(data, schedulerProject);
    },
  });

  const handleEstimateTravelTime = useCallback(
    (eventRecord: SchedulerEventModel) => {
      getRoute({
        variables: {
          originJobVisitId: eventRecord.id,
          destinationJobId: job.id,
        },
      });
    },
    [getRoute, job.id]
  );

  const handleEventMenuProcessItems = useCallback(
    (context: { eventRecord: SchedulerEventModel; items: any }) => {
      if (!context.eventRecord.draggable) {
        if (
          readOnly ||
          DateTime.fromJSDate(context.eventRecord.endDate as Date) <
            DateTime.local()
        ) {
          return false;
        }
        context.items = {
          getTravelTime: {
            text: 'Estimate travel time',
            icon: 'b-fa b-fa-fw b-fa-car',
            onItem: () => handleEstimateTravelTime(context.eventRecord),
          },
        };
      }
      return true;
    },
    [readOnly, handleEstimateTravelTime]
  );

  const [selectedVisit, setSelectedVisit] = useState<JobVisit>();
  const handleOnEventSelectionChange = useCallback(
    ({
      action,
      selected,
    }: {
      action: string;
      selected: SchedulerEventModel[];
    }) => {
      if (!readOnly || !visitOnSelect) return;
      if (action === 'select' || action === 'update') {
        const event = selected?.[0];
        switch (selectMode) {
          case 'eot':
            if (event) {
              visitOnSelect(event.id as string).then((visit) => {
                if (!schedulerRef.current) return;
                setSelectedVisit(visit);
                (schedulerRef.current.instance.timeRangeStore as Store).add({
                  id: 'new-response-deadline',
                  name: 'New response deadline',
                  startDate: DateTime.fromISO(visit.dateTimeStart)
                    .plus(visitSelectOffset || { hour: 1 })
                    .toJSDate(),
                  timeRangeColor: 'red',
                });
              });
            }
            break;
          case 'parent':
            if (!event.draggable || event.isCreating) return;
            visitOnSelect(event.id as string);
            break;
          default:
        }
      }
    },
    [readOnly, selectMode, visitOnSelect, visitSelectOffset]
  );

  useEffect(() => {
    if (
      !readOnly ||
      !schedulerRef.current ||
      !selectedVisit ||
      selectMode !== 'eot'
    )
      return;
    const newResponseDeadline = (
      schedulerRef.current.instance.timeRangeStore as Store
    ).getById('new-response-deadline');
    newResponseDeadline.set(
      'startDate',
      DateTime.fromISO(selectedVisit.dateTimeStart)
        .plus(visitSelectOffset || { hour: 1 })
        .toJSDate()
    );
  }, [readOnly, selectMode, selectedVisit, visitSelectOffset]);

  const apolloClient = useApolloClient();
  const getJobVisitById = useCallback(
    (id: JobVisit['id']) => {
      return apolloClient.query({
        query: JOB_VISIT_QUERY,
        fetchPolicy: 'no-cache',
        variables: { id },
      });
    },
    [apolloClient]
  );

  const tooltipTemplate = useCallback(
    ({ eventRecord }: { eventRecord: SchedulerEventModel }) => {
      if (
        eventRecord.isPhantom ||
        eventRecord.isCreating ||
        eventRecord.hasGeneratedId
      )
        return false;
      return getJobVisitById(eventRecord.id as string).then((response) => {
        const { jobVisit: visit } = response.data;
        return renderTooltip(client, visit, eventRecord);
      });
    },
    [client, getJobVisitById]
  );

  const handleEventDragValidate = useCallback(
    ({ startDate }: { startDate: Date }) => {
      let message = '';
      if (
        !job.targetDateTimeStartMet &&
        DateTime.fromJSDate(startDate as Date) >
          DateTime.fromISO(job.targetDateTimeStart)
      ) {
        message = 'Late response';
      }
      return {
        valid: true,
        message,
      };
    },
    [job.targetDateTimeStart, job.targetDateTimeStartMet]
  );

  // const handleOnBeforeReconfigure = useCallback(
  //   ({
  //     startDate: startDateJS,
  //     endDate: endDateJS,
  //   }: {
  //     startDate: Date;
  //     endDate: Date;
  //   }) => {
  //     const startDate = DateTime.fromJSDate(startDateJS);
  //     const endDate = DateTime.fromJSDate(endDateJS);
  //
  //     console.table([
  //       startDate.toLocaleString(DateTime.DATETIME_FULL),
  //       endDate.toLocaleString(DateTime.DATETIME_FULL),
  //     ]);
  //
  //     if (startDate > endDate) return false;
  //
  //     if (startDate < initialViewDateTimeStart.minus({ months: 1 })) {
  //       return false;
  //     }
  //
  //     return endDate <= initialViewDateTimeEnd.plus({ months: 1 });
  //   },
  //   [initialViewDateTimeStart, initialViewDateTimeEnd]
  // );

  useEffect(() => {
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.timeAxis.on(
      'beforereconfigure',
      ({
        startDate: startDateJS,
        endDate: endDateJS,
      }: {
        startDate: Date;
        endDate: Date;
      }) => {
        const startDate = DateTime.fromJSDate(startDateJS);
        const endDate = DateTime.fromJSDate(endDateJS);

        if (startDate > endDate) {
          return false;
        }

        // if (startDate < initialViewDateTimeStart.minus({ months: 1 })) {
        //   return false;
        // }
        //
        // const ret = endDate <= initialViewDateTimeEnd.plus({ months: 1 });
        // return ret;
        return true;
      }
    );
  }, [initialViewDateTimes]);

  const [expanded, toggleExpanded] = useToggle(false);
  const [currentTime, toggleCurrentTime] = useToggle(false);

  const handleResetView = () => {
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.timeAxis.setTimeSpan(
      initialViewDateTimes.dateTimeStart.toJSDate(),
      initialViewDateTimes.dateTimeEnd.toJSDate()
    );
  };

  return (
    <div
      className={classNames('d-flex flex-column', {
        'h-100': !expanded,
      })}
      style={{ height: expanded ? 1200 : '' }}
    >
      <JobVisitSchedulerControls
        schedulerRef={schedulerRef}
        schedulerProject={schedulerProject}
        readOnly={readOnly}
        job={job}
        initialViewDateTimeStart={initialViewDateTimes.dateTimeStart}
        initialViewDateTimeEnd={initialViewDateTimes.dateTimeEnd}
        renderUnassignedGrid={renderUnassignedGrid}
        showUnassignedGrid={showUnassignedGrid}
        expanded={expanded}
        currentTime={currentTime}
        onForward={handleOnForward}
        onBackward={handleOnBackward}
        resetView={handleResetView}
        toggleUnassignedGrid={handleToggleUnassignedGrid}
        toggleFilter={() => {}}
        toggleCurrentTime={toggleCurrentTime}
        toggleExpanded={toggleExpanded}
      />
      <Row
        className="flex-grow-1"
        style={{
          borderTop: theme.border,
          borderRadius: '0 0 0.25rem 0.25rem',
          overflow: 'hidden',
        }}
        noGutters
      >
        <Col xl={renderUnassignedGrid && showUnassignedGrid ? 10 : 12}>
          <BryntumSchedulerPro
            ref={schedulerRef}
            {...schedulerConfig}
            {...(expanded ? {} : { height: '100%' })}
            columns={[
              {
                text: 'Name',
                field: 'name',
                width: 240,
                renderer: renderColumn,
              },
            ]}
            project={schedulerProject}
            rowHeight={80}
            eventRenderer={renderEvent}
            onAfterEventDrop={handleOnAfterEventDrop}
            onEventResizeEnd={handleOnEventResizeEnd}
            onTimeAxisChange={handleOnTimeAxisChange}
            onBeforeEventDelete={handleOnDestroy}
            onBeforeTaskEdit={handleOnBeforeTaskEdit}
            onEventSelectionChange={handleOnEventSelectionChange}
            eventMenuFeature={{
              processItems: handleEventMenuProcessItems,
            }}
            eventTooltipFeature={{
              template: tooltipTemplate,
              allowOver: true,
            }}
            eventDragFeature={{
              validatorFn: handleEventDragValidate,
            }}
            sortFeature="name"
            cellEditFeature={false}
            groupFeature="category"
            readOnly={readOnly}
            stripeFeature
            timeRangesFeature={{
              showCurrentTimeLine: currentTime,
            }}
            resourceTimeRangesFeature
          />
        </Col>
        {renderUnassignedGrid && showUnassignedGrid && (
          <Col xl={2} style={{ borderLeft: theme.border }}>
            <UnassignedGridComponent
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={gridRef}
              eventStore={schedulerProject.eventStore}
              store={unassignedGridEventStore}
            />
          </Col>
        )}
      </Row>
      <JobVisitSchedulerCreateSidePanel
        job={job}
        schedulerRef={schedulerRef}
        isOpen={createSidePanelIsOpen}
        eventRecord={currentEventRecord}
        setEventRecord={setCurrentEventRecord}
        toggle={toggleCreateSidePanel}
        visitOnCreate={visitOnCreate}
      />
      <JobVisitSchedulerEditSidePanel
        job={job}
        schedulerRef={schedulerRef}
        isOpen={editSidePanelIsOpen}
        eventRecord={currentEventRecord}
        setEventRecord={setCurrentEventRecord}
        toggle={toggleEditSidePanel}
        visitOnChange={visitOnChange}
      />
    </div>
  );
};

export default JobVisitScheduler;
