import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Client, Point } from '../types';
import useClient from './useClient';

type QueryVariables = {
  clientId: Client['id'];
};

type QueryData = {
  client: Pick<Client, 'location'>;
};

const QUERY = gql`
  query ($clientId: ID!) {
    client(clientId: $clientId) {
      location {
        latitude
        longitude
      }
    }
  }
`;

const useClientLocation = () => {
  const client = useClient();
  const [clientLocation, setClientLocation] = useState<Point>();

  useQuery<QueryData, QueryVariables>(QUERY, {
    variables: { clientId: client.id },
    onCompleted: (data) => {
      setClientLocation(data.client.location);
    },
  });

  return clientLocation;
};

export default useClientLocation;
