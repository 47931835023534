import { DataType } from '../types';

const getDataTypeDisplay = (dataType: DataType) => {
  switch (dataType) {
    case 'NUMBER':
      return 'Number';
    case 'BOOLEAN':
      return 'Boolean';
    case 'CHAR':
      return 'Text';
    case 'DATE_TIME':
      return 'Date & Time';
    default:
      return 'Invalid';
  }
};

export default getDataTypeDisplay;
