import chroma from 'chroma-js';

const white = chroma('#ffffff');
const black = chroma('#000000');

export const lightTheme = {
  color: {
    primary: chroma('#106c12'),
    primaryLighter: chroma('#4c914d'),
    incoming: chroma('#00ffff'),
    amber: chroma('#ff9a09'),
    warning: chroma('#ff8000').alpha(0.75),
    danger: chroma('#ff0000').alpha(0.75),
    contentBackground: chroma('#f2f2f2'),
    cardBackground: white,
    cardText: chroma('#000000').alpha(0.75),
    sidebarBackground: chroma('#4c914d'),
    sidebarBackgroundDarker: chroma('#4c914d').darken(0.5),
    sidebarText: white,
    topbarBackground: white,
    topbarText: black,
    topbarBorder: chroma('#f4f4f4'),
    buttonBackground: white,
    buttonText: black,
    buttonHover: black.alpha(0.25),
    buttonMultiBackground: white,
    buttonMultiHover: chroma('#f0f0f0'),
    simpleButtonHover: chroma('#f0f0f0'),
    inputBackground: white,
    inputText: black,
    inputBorderColorHover: black.alpha(0.25),
    borderColor: chroma('rgba(0, 0, 0, 0.1)'),
    schedulerGridBorderColor: chroma('rgba(0, 0, 0, 0.05)'),
    scrollBarTrack: chroma('#f1f1f1'),
    scrollBarThumb: chroma('rgba(0, 0, 0, 0.05)'),
    scrollBarThumbHover: chroma('#106c12'),
    schedulerGridBackground: chroma('#f4f4f4'),
    schedulerVisitBackground: white,
    schedulerVisitBackgroundHover: white.darken(0.25),
    schedulerVisitBorderColor: black.alpha(0.1),
    tableBackgroundHover: white.darken(0.025),
    logoFill: white,
    logoFillHover: chroma('#106c12'),
  },
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '0.25rem',
  schedulerGridBorder: '1px solid rgba(0, 0, 0, 0.05)',
  cardBorderRadius: '0.25rem',
  mapboxStyle: 'mapbox://styles/mapbox/light-v10',
};

const darkContentBackground = chroma('#1e272e');
const darkCardBackground = chroma('#2b343b');

export const darkTheme = {
  color: {
    primary: chroma('#106c12'),
    primaryLighter: chroma('#4C914D'),
    incoming: chroma('#00ffff'),
    amber: chroma('#ff9a09'),
    danger: chroma('#ff0000').alpha(0.75),
    warning: chroma('#ff8000').alpha(0.75),
    contentBackground: darkContentBackground,
    cardBackground: darkCardBackground,
    cardText: white,
    sidebarBackground: darkCardBackground,
    sidebarBackgroundDarker: darkCardBackground.darken(0.5),
    sidebarText: white,
    topbarBackground: darkCardBackground,
    topbarText: white,
    topbarBorder: chroma('#1e272e'),
    buttonBackground: chroma('#1e272e'),
    buttonText: white,
    buttonHover: chroma('#f0f0f0').darken(2),
    buttonMultiBackground: darkContentBackground,
    buttonMultiHover: darkCardBackground,
    simpleButtonHover: darkContentBackground,
    inputBackground: darkContentBackground,
    inputText: white,
    inputBorderColorHover: chroma('#f0f0f0').darken(0.25),
    borderColor: chroma('#1e272e'),
    schedulerGridBorderColor: darkContentBackground.alpha(0.5),
    scrollBarTrack: darkContentBackground,
    scrollBarThumb: darkContentBackground.brighten(0.5),
    scrollBarThumbHover: darkContentBackground.darken(0.5),
    schedulerGridBackground: darkCardBackground,
    schedulerVisitBackground: darkContentBackground,
    schedulerVisitBackgroundHover: darkContentBackground.darken(0.5),
    schedulerVisitBorderColor: darkCardBackground.alpha(0.5),
    tableBackgroundHover: darkCardBackground.darken(0.25),
    logoFill: white,
    logoFillHover: chroma('#106c12'),
  },
  border: `1px solid ${darkContentBackground.hex()}`,
  borderRadius: '0.25rem',
  schedulerGridBorder: `1px solid ${darkContentBackground.alpha(0.5).hex()}`,
  cardBorderRadius: '0.25rem',
  mapboxStyle: 'mapbox://styles/cnsuk/ckn3lnas80urr17nz18lqzhlr',
};
