import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import React from 'react';
import CardWrapper from 'components/CardWrapper';
import SimpleButton from 'components/SimpleButton';
import BlockJob from '../BlockJob';
import BlockUserNotes from '../BlockUserNotes';
import BlockWelcome from '../BlockWelcome';
import { BlockProps } from './types';
import { ControlsWrapper } from './styled';
import BlockIncomingJobs from '../BlockIncomingJobs';

const Block = React.forwardRef<HTMLDivElement, BlockProps>(
  ({ block, controls, actions, ...rest }, ref) => {
    const renderBlockInner = () => {
      switch (block.type) {
        case 'WELCOME':
          return <BlockWelcome />;
        case 'INCOMING_JOBS':
          return <BlockIncomingJobs block={block} actions={actions} />;
        case 'JOB':
          return <BlockJob block={block} actions={actions} />;
        case 'USER_NOTES':
          return <BlockUserNotes />;
        default:
          return null;
      }
    };

    return (
      <CardWrapper className="h-100" padding={false} {...rest}>
        <ControlsWrapper>
          <SimpleButton
            className="react-grid-layout-drag-handle"
            style={{ cursor: 'grab' }}
            icon={faGripVertical}
          />
          <SimpleButton
            icon={faTimes}
            onClick={(event) => controls.onClose(block, event)}
          />
        </ControlsWrapper>
        {renderBlockInner()}
      </CardWrapper>
    );
  }
);

export default Block;
