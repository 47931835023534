import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { Marker, MarkerProps } from 'react-map-gl';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/pro-duotone-svg-icons/faArchive';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faSensorAlert } from '@fortawesome/pro-duotone-svg-icons/faSensorAlert';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft';
import { useClientLocation, useToast, useMap } from 'lib/hooks';
import { ClientSite } from 'lib/types';
import { useTheme } from 'styled-components';
import {
  managementTaskOnCompleted,
  useReducerContext,
} from '../ClientSettingsManagement/reducer';
import Map from '../Map';
import RadioButton from '../RadioButton';
import SelectAsync from '../SelectAsync';
import SimpleButton from '../SimpleButton';
import { Small } from '../Typography';
import { UPDATE_CLIENT_SITE_LOCATION_MUTATION } from './query';
import {
  ClientSettingsManagementSiteProps,
  FormValues,
  UpdateClientSiteLocationMutationData,
  UpdateClientSiteLocationMutationVariables,
} from './types';

const ClientSettingsManagementSite = ({
  goBackOnClick,
}: ClientSettingsManagementSiteProps) => {
  const theme = useTheme();
  const clientLocation = useClientLocation();
  const [{ managementTask }, dispatch] = useReducerContext();

  const { mapProps, updateViewState } = useMap();
  const [markers, setMarkers] = useState<MarkerProps[]>([]);

  const [geocoderResult, setGeocoderResult] =
    useState<google.maps.GeocoderResult>();

  const toast = useToast();

  const [updateClientSite, { loading: saving }] = useMutation<
    UpdateClientSiteLocationMutationData,
    UpdateClientSiteLocationMutationVariables
  >(UPDATE_CLIENT_SITE_LOCATION_MUTATION);

  const handleOnSubmit = async (values: FormValues) => {
    if (!managementTask || !values.placeId) return;
    const { data } = await updateClientSite({
      variables: {
        clientSiteId: (managementTask.instance.data as ClientSite).id,
        placeId: values.placeId.value,
      },
    });
    if (data) {
      toast('SUCCESS', 'Hello');
      dispatch(
        managementTaskOnCompleted(data.updateClientSiteLocation.managementTask)
      );
    }
  };

  if (!managementTask) return null;

  const autoCompleteService = new google.maps.places.AutocompleteService();
  const geocodingService = new google.maps.Geocoder();

  const loadOptions = async (input: string) => {
    const { predictions } = await autoCompleteService.getPlacePredictions({
      input,
      location: clientLocation
        ? new google.maps.LatLng({
            lat: clientLocation.latitude,
            lng: clientLocation.longitude,
          })
        : undefined,
      radius: 10000,
      componentRestrictions: { country: 'uk' },
    });
    return predictions.map((prediction) => ({
      label: prediction.description,
      value: prediction.place_id,
      meta: prediction,
    }));
  };

  const data = managementTask.instance?.data as ClientSite;

  const renderSearchField = ({ input }: FieldRenderProps<any>) => {
    return (
      <div>
        <div className="mb-3">
          <div className="mb-2">
            <Small>Select from the possible options</Small>
          </div>
          {(data.location.meta as any[]).map((meta) => (
            <div key={meta.placeId}>
              <RadioButton
                id={meta.placeId}
                label={meta.formattedAddress}
                checked={input.value.value === meta.placeId}
                value={meta.placeId}
                onClick={() => {
                  setGeocoderResult(undefined);
                  input.onChange(meta.placeId);
                }}
              />
            </div>
          ))}
        </div>
        <div>
          <Small>Or find address manually</Small>
          <SelectAsync
            placeholder="Search for a location..."
            loadOptions={loadOptions}
            escapeClearsValue={false}
            menuPortalTarget={document.body}
            value={input.value.value}
            onChange={(option) => {
              if (!option) return;
              geocodingService
                .geocode({ placeId: option.value })
                .then((response) => {
                  const [meta] = response.results;
                  setGeocoderResult(meta);
                  setMarkers([
                    {
                      latitude: meta.geometry.location.lat(),
                      longitude: meta.geometry.location.lng(),
                    },
                  ]);
                  updateViewState({
                    latitude: meta.geometry.location.lat(),
                    longitude: meta.geometry.location.lng(),
                    zoom: 14,
                  });
                });
              input.onChange(option);
            }}
          />
          {geocoderResult && (
            <div className="mt-3">
              <Small>Selected address</Small>
              <p>{geocoderResult.formatted_address}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getMetaByPlaceId = (placeId: string) => {
    return (data.location.meta as any[]).find(
      (meta) => meta.placeId === placeId
    );
  };

  const validate = (values: FormValues) => {
    const errors: { placeId?: string } = {};
    if (!values.placeId?.value) errors.placeId = 'Please select a location';
    return errors;
  };

  return (
    <div style={{ borderBottom: theme.border }}>
      <Row className="h-100" noGutters>
        <Col>
          <div className="px-3 pt-3 pb-2 h-100 d-flex flex-column">
            <div>
              <div className="d-flex mb-3">
                <SimpleButton
                  className="mr-2"
                  active
                  icon={faLongArrowLeft}
                  onClick={goBackOnClick}
                >
                  Go back
                </SimpleButton>
                <h5 style={{ lineHeight: '34px' }}>Enter site location</h5>
              </div>
              <div className="mb-3">
                <Small>Current details</Small>
                <div>{data.name}</div>
              </div>
            </div>
            <div className="flex-grow-1">
              <Form validate={validate} onSubmit={handleOnSubmit}>
                {({ handleSubmit, invalid }) => (
                  <form className="d-flex flex-column justify-content-between h-100">
                    <div className="mb-5">
                      <Field
                        name="placeId"
                        component={renderSearchField}
                        parse={(value) => {
                          const meta = getMetaByPlaceId(value);
                          if (meta) {
                            setMarkers([
                              {
                                latitude: meta.geometry.location.lat,
                                longitude: meta.geometry.location.lng,
                              },
                            ]);
                            updateViewState({
                              latitude: meta.geometry.location.lat,
                              longitude: meta.geometry.location.lng,
                              zoom: 14,
                            });
                          }
                          return { value };
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <SimpleButton className="mr-2" icon={faArchive}>
                          Archive
                        </SimpleButton>
                        <SimpleButton className="mr-2" icon={faSensorAlert}>
                          Escalate
                        </SimpleButton>
                      </div>
                      <SimpleButton
                        active
                        icon={faCheck}
                        disabled={invalid}
                        loading={saving}
                        onClick={handleSubmit}
                      >
                        Save
                      </SimpleButton>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </Col>
        <Col>
          <div className="p-2 h-100">
            <Map height="100%" {...mapProps}>
              {markers.map((marker) => (
                <Marker {...marker}>
                  <FontAwesomeIcon
                    className="marker-icon"
                    icon={faMapMarkerAlt}
                  />
                </Marker>
              ))}
            </Map>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ClientSettingsManagementSite;
