import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BreadcrumbLinkWrapper = styled(Link)`
  position: relative;
  z-index: 9999;

  h4 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
    transition: color 150ms;
  }

  &:hover {
    text-decoration: none;

    h4 {
      transition: color 50ms;
      color: ${({ theme }) => theme.color.cardText.hex()};
    }
  }
`;

export const BreadcrumbText = styled.h4``;

export const BreadcrumbLinkText = styled(BreadcrumbText)``;

export const BreadcrumbTerminatorWrapper = styled.div``;
