import { useCallback, useEffect, useRef, useState } from 'react';

type Options = {
  hold?: boolean;
  holdTime?: number;
};

const useHold = (value: boolean, { hold = true, holdTime = 250 }: Options) => {
  const [valueHeld, setValueHeld] = useState(value);

  const previousValueRef = useRef<boolean>();
  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);
  const previousValue = previousValueRef.current;

  const handleCallback = useCallback(() => {
    setValueHeld(!hold);
  }, [hold]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (value === hold) {
      setValueHeld(hold);
    } else {
      timeout = setTimeout(handleCallback, holdTime);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [handleCallback, previousValue, hold, holdTime, valueHeld, value]);

  return valueHeld;
};

export default useHold;
