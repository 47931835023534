import React, { Reducer, useEffect, useReducer } from 'react';
import { AnyAction } from 'redux';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useClient, useIsPathActive } from 'lib/hooks';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { useTheme } from 'styled-components';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButton from '../SimpleButton';
import { INITIAL_QUERY } from './query';
import {
  ClientDashboardState,
  InitialQueryData,
  InitialQueryParams,
} from './types';
import reducer, {
  initialQueryOnCompleted,
  initialState,
  ReducerContext,
} from './reducer';
import CardWrapper from '../CardWrapper';

const ClientDashboardWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const theme = useTheme();
  const client = useClient();
  const isPathActive = useIsPathActive();

  const [state, dispatch] = useReducer<
    Reducer<ClientDashboardState, AnyAction>
  >(reducer, initialState);

  const { stopPolling } = useQuery<InitialQueryData, InitialQueryParams>(
    INITIAL_QUERY,
    {
      pollInterval: 5000,
      variables: { clientId: client.id },
      onCompleted: (data) => {
        dispatch(initialQueryOnCompleted(data));
      },
    }
  );

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <Link to={`/clients/${client.slug}`}>
            <SimpleButton
              icon={faHome}
              inButtonToolbar
              active={isPathActive('/clients/:slug')}
            >
              Overview
            </SimpleButton>
          </Link>
          <Link to={`/clients/${client.slug}/schedule`}>
            <SimpleButton
              icon={faCalendarAlt}
              inButtonToolbar
              active={isPathActive('/clients/:slug/schedule')}
            >
              Schedule
            </SimpleButton>
          </Link>
        </CardWrapper>
      </PortalTopbarExtension>
      {children}
    </ReducerContext.Provider>
  );
};

export default ClientDashboardWrapper;
