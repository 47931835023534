import React, { useRef, useState } from 'react';
import { useSearchParam } from 'react-use';
import { useQuery } from '@apollo/client';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useNavigate } from 'react-router-dom';
import { Job, JobStatus, Option, QueryResult } from 'lib/types';
import { useClient, useSearchRefetch, useSortTable } from 'lib/hooks';
import { ValueType } from 'react-select';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { JobsQueryData, JobsQueryParams } from './types';
import { Wrapper } from './styled';
import { JOBS_QUERY } from './query';
import JobsTableFilter from '../ClientJobsAllTableFilter';
import JobsTable from '../ClientJobsAllTable';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { TableHeadNames } from '../ClientJobsAllTable/types';
import TablePageIndicator from '../TablePageIndicator';

const ClientJobsAll = () => {
  const { slug: clientSlug, ...client } = useClient();
  const [jobs, setJobs] = useState<QueryResult<Job>>({
    count: 0,
    results: [],
  });
  const [jobStatuses, setJobStatuses] = useState<JobStatus[]>([]);
  const initialFlagName = useSearchParam('flag') as string;
  const initialJobStatusId = useSearchParam('status') as JobStatus['id'];
  const { refetch } = useQuery<JobsQueryData, JobsQueryParams>(JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    refetchWritePolicy: 'overwrite',
    onCompleted: (data) => {
      setJobs(data.jobs);
      setJobStatuses(data.jobStatuses);
    },
    variables: {
      clientId: client.id,
      page: 1,
      flagName: initialFlagName,
      jobStatusId: initialJobStatusId,
      includeInstantiationSuggestion: initialFlagName === 'BLUE',
      sort: { name: 'dateTimeStart', order: false },
    },
  });

  const navigate = useNavigate();
  const [activeFlagName, setActiveFlagName] = useState<string>(
    initialFlagName || 'all'
  );
  const [nextFlagType, setNextFlagName] = useState<string>(
    initialFlagName || 'all'
  );
  const [activeStatus, setActiveStatus] = useState<JobStatus>();
  const [refetching, setRefetching] = useState(false);

  const [
    { value: searchTerm, setSearchTerm, ...rest },
    { loading: searching },
  ] = useSearchRefetch(refetch, {
    onCompleted: (response) => {
      setJobs(response.data.jobs);
      setRefetching(false);
    },
  });

  const handleFlagNameOnChange = (flagName: string) => {
    setRefetching(true);
    setNextFlagName(flagName || 'all');
    setSearchTerm('');
    setActiveStatus(undefined);
    refetch({
      flagName,
      jobStatusId: null,
      includeInstantiationSuggestion: initialFlagName === 'BLUE',
      page: 1,
    }).then((response) => {
      setJobs(response.data.jobs);
      setJobStatuses(response.data.jobStatuses);
      setRefetching(false);
      setActiveFlagName(flagName || 'all');
      if (typeof flagName === 'undefined') {
        navigate(`/clients/${clientSlug}/jobs`, {
          replace: true,
        });
      } else {
        navigate(`/clients/${clientSlug}/jobs?flag=${flagName}`, {
          replace: true,
        });
      }
    });
  };

  const handleStatusOnChange = (option: ValueType<Option<string>, false>) => {
    setRefetching(true);
    setActiveStatus(
      jobStatuses.find((jobStatus) => jobStatus.id === option?.value)
    );
    refetch({ jobStatusId: option?.value }).then((response) => {
      setJobs(response.data.jobs);
      setRefetching(false);
    });
  };

  const tableRef = useRef<HTMLTableSectionElement>(null);

  const [page, setPage] = useState<number>(1);
  const handleChangePage = (delta: number) => {
    setRefetching(true);
    setPage((prevPage) => {
      const newPage = prevPage + delta;
      refetch({ page: newPage }).then((response) => {
        setJobs(response.data.jobs);
        setRefetching(false);
        if (tableRef.current) {
          tableRef.current.scrollTo(0, 0);
        }
      });
      return newPage;
    });
  };

  const tableHeadNames: TableHeadNames = [
    'reference',
    'status',
    'name',
    'type',
    'site',
    'dateTimeStart',
    'dateTimeEnd',
  ];
  const [sortState, handleSort] = useSortTable<TableHeadNames>(tableHeadNames, {
    defaultActiveName: 'dateTimeStart',
    onSort: (sort) => {
      refetch({ sort }).then((response) => {
        setJobs(response.data.jobs);
      });
    },
  });

  const theme = useTheme();
  return (
    <>
      <PortalBreadcrumb>
        <h4 className="text-capitalize">{activeFlagName.toLowerCase()} Jobs</h4>
      </PortalBreadcrumb>
      <Wrapper className="h-100">
        <JobsTableFilter
          jobs={jobs}
          jobStatuses={jobStatuses}
          refetching={refetching || searching}
          activeFlagName={activeFlagName}
          nextFlagName={nextFlagType}
          activeStatus={activeStatus}
          flagNameOnChange={handleFlagNameOnChange}
          statusOnChange={handleStatusOnChange}
          searchBarProps={{ value: searchTerm, ...rest }}
        />
        <JobsTable
          innerRef={tableRef}
          rows={jobs?.results}
          sortState={sortState}
          sort={handleSort}
          activeFlagName={activeFlagName}
        />
        <div
          className="d-flex justify-content-between px-3 pt-3"
          style={{ borderTop: theme.border }}
        >
          <div className="d-flex flex-column justify-content-center">
            <TablePageIndicator page={page} count={jobs.count} />
          </div>
          <div>
            <Button
              className="mr-3"
              icon={faChevronLeft}
              iconSide="left"
              disabled={page === 1}
              onClick={() => handleChangePage(-1)}
            >
              Previous page
            </Button>
            <Button
              disabled={jobs.results.length < 20 || false}
              icon={faChevronRight}
              iconSide="right"
              onClick={() => handleChangePage(1)}
            >
              Next page
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ClientJobsAll;
