import React from 'react';
import { Link } from 'react-router-dom';
import { useIssueColor } from 'lib/hooks';
import { JobIssuePillProps } from './types';
import { Wrapper } from './styled';

const JobIssuePill = ({
  jobIssue,
  statusOverride,
  ...rest
}: JobIssuePillProps) => {
  const color = useIssueColor(statusOverride ?? jobIssue.status);
  const href = `/clients/${jobIssue.client.slug}/jobs/${jobIssue.job.reference}/issues/${jobIssue.number}`;
  return (
    <Link to={href}>
      <Wrapper {...rest} href={href}>
        <div className="d-flex">
          <div
            className="job-issue-pill-number"
            style={{ backgroundColor: color }}
          >
            <span className="mono text-100">#{jobIssue.number}</span>
          </div>
          <div className="job-issue-pill-type">{jobIssue.issueTypeDisplay}</div>
        </div>
      </Wrapper>
    </Link>
  );
};

export default JobIssuePill;
