import React, { Reducer, useReducer } from 'react';
import { AnyAction } from 'redux';
import { useQuery } from '@apollo/client';
import { Routes, Route } from 'react-router-dom';
import reducer, {
  initialState,
  usersQueryOnCompleted,
  ReducerContext,
} from './reducer';
import { PeopleState } from './types';
import { USERS_QUERY } from './query';
import PeopleDashboard from '../PeopleDashboard';
import PeoplePerson from '../PeoplePerson';

const People = () => {
  const [state, dispatch] = useReducer<Reducer<PeopleState, AnyAction>>(
    reducer,
    initialState
  );

  useQuery(USERS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(usersQueryOnCompleted(data));
    },
  });

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <Routes>
        <Route path="/people" element={<PeopleDashboard />} />
        <Route path="/people/:id" element={<PeoplePerson />} />
      </Routes>
    </ReducerContext.Provider>
  );
};

export default People;
