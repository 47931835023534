import { gql } from '@apollo/client';

export const SETTINGS_VARIABLES_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String!) {
    settingsVariables(
      clientId: $clientId
      searchTerm: $searchTerm
      includeGlobal: true
    ) {
      id
      name
      description
      dataType
      value
    }
  }
`;
