import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleButton from '../SimpleButton';
import { SimpleButtonCircleProps } from './types';

const SimpleButtonSquare = ({
  icon,
  iconProps,
  style,
  ...rest
}: SimpleButtonCircleProps) => {
  return (
    <SimpleButton
      className="center"
      style={{ ...style, borderRadius: '50%', height: 34, width: 34 }}
      {...rest}
    >
      <FontAwesomeIcon style={{ marginTop: 4 }} icon={icon} {...iconProps} />
    </SimpleButton>
  );
};

export default SimpleButtonSquare;
