import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% - 1rem);
  z-index: 10;

  div.inner {
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
    border: ${({ theme }) => `2px solid ${theme.color.primary.hex()}`};
    border-top: none;
    border-radius: ${({ theme }) =>
      `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
  }
`;
