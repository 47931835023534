import styled from 'styled-components';

export const TemplateBlockLogicWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  margin-left: 40px;
  z-index: 1;
`;

export const TextLogicSelectorInput = styled.input`
  display: inline-block;
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.cardText.hex()};
`;
