import { faExclamationSquare } from '@fortawesome/pro-duotone-svg-icons/faExclamationSquare';
import { faQuestionSquare } from '@fortawesome/pro-duotone-svg-icons/faQuestionSquare';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  ReportTemplateBlock as ReportTemplateBlockType,
  ReportTemplateBlockLogic as ReportTemplateBlockLogicType,
  ReportTemplateResponse as ReportTemplateResponseType,
} from 'lib/types';
import { batch } from 'react-redux';
import { useToggle } from 'react-use';
import { useTheme } from 'styled-components';
import { OnLogicChange } from 'lib/tree';
import { generateId, generateSlateDefault } from 'lib/utils';
import { DepthLine } from 'lib/tree/components/Tree';
import { ButtonMultiInner } from '../ButtonMulti';
import Checkbox from '../Checkbox';
import Context from '../ReportTemplateBlock/context';
import ReportTemplateBlockDerivationEditor from '../ReportTemplateBlockDerivationEditor';
import {
  addBlockToLogic,
  reportTemplateBlockLogicAddTrigger,
  reportTemplateBlockLogicOnChange,
  toggleReportTemplateLogicConditionResponse,
  updateReportTemplateBlockLogic,
  useReducerContext,
} from '../ReportTemplateEditor/reducer';
import SimpleButton from '../SimpleButton';
import SimpleButtonLarge from '../SimpleButtonLarge';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { WindowChildrenProps } from '../SimpleButtonWithWindow/types';
import { TemplateBlockLogicWrapper, TextLogicSelectorInput } from './styled';
import { ReportTemplateResponse } from '../ReportTemplateResponseSet';

type WrapperRendererProps = {
  updateLogicConditionType: (type: string) => void;
  renderConditionTypeWindowChildren: React.FC<WindowChildrenProps>;
};

type WrapperProps = {
  children: React.FC<WrapperRendererProps>;
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
  logicConditionTypes: ReportTemplateBlockLogicType['logicConditionType'][];
  getLogicConditionTypeText: (
    logicConditionType: ReportTemplateBlockLogicType['logicConditionType']
  ) => string;
};

const Wrapper = ({
  children,
  item,
  logic,
  logicConditionTypes,
  getLogicConditionTypeText,
}: WrapperProps) => {
  const [, dispatch] = useReducerContext();

  const updateLogicConditionType = (type: string) => {
    dispatch(
      reportTemplateBlockLogicOnChange(item.id, logic.id, {
        logicConditionType: type,
      })
    );
  };

  const renderConditionTypeWindowChildren = ({
    close,
  }: WindowChildrenProps) => {
    return (
      <div className="p-1">
        <ButtonMultiInner
          options={[
            ...logicConditionTypes.map((logicConditionType) => ({
              label: getLogicConditionTypeText(logicConditionType),
              onClick: () => {
                updateLogicConditionType(logicConditionType);
                close();
              },
            })),
            {
              label: 'Custom',
              onClick: () => {
                updateLogicConditionType('DERIVED');
                close();
              },
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      {children({
        updateLogicConditionType,
        renderConditionTypeWindowChildren,
      })}
    </>
  );
};

const TextLogicSelector = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [, dispatch] = useReducerContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);

  const handleLogicConditionValueOnClick = () => {
    setFocused(true);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  };

  const handleLogicConditionValueOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    dispatch(
      reportTemplateBlockLogicOnChange(item.id, logic.id, {
        logicConditionValue: value,
      })
    );
  };

  const handleLogicConditionValueOnBlur = () => {
    setFocused(false);
  };

  const getLogicConditionTypeText = (logicConditionType: string) => {
    switch (logicConditionType) {
      case 'CHAR_IS':
        return 'is';
      case 'CHAR_IS_NOT':
        return 'is not';
      default:
        return 'bad';
    }
  };

  return (
    <Wrapper
      item={item}
      logic={logic}
      logicConditionTypes={['CHAR_IS', 'CHAR_IS_NOT']}
      getLogicConditionTypeText={getLogicConditionTypeText}
    >
      {({ renderConditionTypeWindowChildren }) => (
        <>
          If answer{' '}
          <SimpleButtonWithWindow
            small
            openMode="click"
            hoverStyles={false}
            windowChildren={renderConditionTypeWindowChildren}
          >
            {getLogicConditionTypeText(logic.logicConditionType)}
          </SimpleButtonWithWindow>{' '}
          {focused ? (
            <TextLogicSelectorInput
              ref={inputRef}
              value={logic.logicConditionValue}
              placeholder="blank"
              onChange={handleLogicConditionValueOnChange}
              onBlur={handleLogicConditionValueOnBlur}
            />
          ) : (
            <SimpleButton
              small
              hoverStyles={false}
              onClick={handleLogicConditionValueOnClick}
            >
              {logic.logicConditionValue || 'blank'}
            </SimpleButton>
          )}
        </>
      )}
    </Wrapper>
  );
};

const NumberLogicSelector = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [, dispatch] = useReducerContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const [focusedLower, setFocusedLower] = useState(false);
  const [focusedUpper, setFocusedUpper] = useState(false);

  const handleLogicConditionValueOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    dispatch(
      reportTemplateBlockLogicOnChange(item.id, logic.id, {
        logicConditionValue: value,
      })
    );
  };

  const handleLogicConditionLowerLimitOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    dispatch(
      updateReportTemplateBlockLogic(logic.id, {
        logicConditionValue: {
          $apply: (
            logicConditionValue: ReportTemplateBlockLogicType['logicConditionValue']
          ) =>
            logicConditionValue
              ? [
                  parseFloat(value),
                  logicConditionValue?.[1] ?? parseFloat(value) + 1,
                ]
              : [parseFloat(value), parseFloat(value) + 1],
        },
      })
    );
  };

  const handleLogicConditionUpperLimitOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    dispatch(
      updateReportTemplateBlockLogic(logic.id, {
        logicConditionValue: {
          $apply: (
            logicConditionValue: ReportTemplateBlockLogicType['logicConditionValue']
          ) => {
            return logicConditionValue
              ? [
                  logicConditionValue?.[0] ??
                    Math.max(0, parseFloat(value) - 1),
                  parseFloat(value),
                ]
              : [Math.max(0, parseFloat(value) - 1), parseFloat(value)];
          },
        },
      })
    );
  };

  const handleLogicConditionValueOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;

    setFocused(true);
    if (value === 'lower') setFocusedLower(true);
    if (value === 'upper') setFocusedUpper(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 10);
  };

  const handleLogicConditionValueOnBlur = () => {
    setFocused(false);
    setFocusedLower(false);
    setFocusedUpper(false);
  };

  const getLogicConditionTypeText = (
    logicConditionType: ReportTemplateBlockLogicType['logicConditionType']
  ) => {
    switch (logicConditionType) {
      case 'NUMBER_IS_EQUAL_TO':
        return 'is equal to';
      case 'NUMBER_IS_NOT_EQUAL_TO':
        return 'is not equal to';
      case 'NUMBER_IS_LESS_THAN':
        return 'is less than';
      case 'NUMBER_IS_LESS_THAN_OR_EQUAL_TO':
        return 'is less than or equal to';
      case 'NUMBER_IS_GREATER_THAN':
        return 'is greater than';
      case 'NUMBER_IS_GREATER_THAN_OR_EQUAL_TO':
        return 'is greater than or equal to';
      case 'NUMBER_IS_BETWEEN':
        return 'is between';
      case 'NUMBER_IS_NOT_BETWEEN':
        return 'is not between';
      default:
        return 'bad';
    }
  };

  const renderField = () => {
    if (focused) {
      return (
        <TextLogicSelectorInput
          ref={inputRef}
          type="number"
          value={logic.logicConditionValue}
          defaultValue={0}
          onChange={handleLogicConditionValueOnChange}
          onBlur={handleLogicConditionValueOnBlur}
        />
      );
    }

    return (
      <SimpleButton
        className="mono"
        small
        hoverStyles={false}
        onClick={() => {
          setFocused(true);
        }}
      >
        {logic.logicConditionValue || 0}
      </SimpleButton>
    );
  };

  const renderRangeFields = () => {
    if (focused && focusedLower) {
      return (
        <>
          <TextLogicSelectorInput
            ref={inputRef}
            type="number"
            value={logic.logicConditionValue?.[0]}
            defaultValue={0}
            onChange={handleLogicConditionLowerLimitOnChange}
            onBlur={handleLogicConditionValueOnBlur}
          />
          {' and '}
          <SimpleButton
            className="mono"
            small
            hoverStyles={false}
            value="upper"
            onClick={handleLogicConditionValueOnClick}
          >
            {logic.logicConditionValue?.[1] || 0}
          </SimpleButton>
        </>
      );
    }

    if (focused && focusedUpper) {
      return (
        <>
          <SimpleButton
            className="mono"
            small
            hoverStyles={false}
            value="lower"
            onClick={handleLogicConditionValueOnClick}
          >
            {logic.logicConditionValue?.[0] || 0}
          </SimpleButton>
          {' and '}
          <TextLogicSelectorInput
            ref={inputRef}
            type="number"
            value={logic.logicConditionValue?.[1]}
            defaultValue={0}
            onChange={handleLogicConditionUpperLimitOnChange}
            onBlur={handleLogicConditionValueOnBlur}
          />
        </>
      );
    }

    return (
      <>
        <SimpleButton
          className="mono"
          small
          hoverStyles={false}
          value="lower"
          onClick={handleLogicConditionValueOnClick}
        >
          {logic.logicConditionValue?.[0] || 0}
        </SimpleButton>
        {' and '}
        <SimpleButton
          className="mono"
          small
          hoverStyles={false}
          value="upper"
          onClick={handleLogicConditionValueOnClick}
        >
          {logic.logicConditionValue?.[1] || 0}
        </SimpleButton>
      </>
    );
  };

  const isRange = ['NUMBER_IS_BETWEEN', 'NUMBER_IS_NOT_BETWEEN'].includes(
    logic.logicConditionType
  );

  return (
    <Wrapper
      item={item}
      logic={logic}
      logicConditionTypes={[
        'NUMBER_IS_EQUAL_TO',
        'NUMBER_IS_NOT_EQUAL_TO',
        'NUMBER_IS_LESS_THAN',
        'NUMBER_IS_LESS_THAN_OR_EQUAL_TO',
        'NUMBER_IS_GREATER_THAN',
        'NUMBER_IS_GREATER_THAN_OR_EQUAL_TO',
        'NUMBER_IS_BETWEEN',
        'NUMBER_IS_NOT_BETWEEN',
      ]}
      getLogicConditionTypeText={getLogicConditionTypeText}
    >
      {({ renderConditionTypeWindowChildren }) => (
        <>
          If answer{' '}
          <SimpleButtonWithWindow
            small
            openMode="click"
            hoverStyles={false}
            windowChildren={renderConditionTypeWindowChildren}
          >
            {getLogicConditionTypeText(logic.logicConditionType)}
          </SimpleButtonWithWindow>{' '}
          {isRange ? renderRangeFields() : renderField()}
        </>
      )}
    </Wrapper>
  );
};

const ChoiceLogicSelector = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [, dispatch] = useReducerContext();

  const getLogicConditionTypeText = (logicConditionType: string) => {
    switch (logicConditionType) {
      case 'CHOICE_IS':
        return 'is';
      case 'CHOICE_IS_NOT':
        return 'is not';
      case 'CHOICE_IS_ONE_OF':
        return 'is one of';
      case 'CHOICE_IS_NOT_ONE_OF':
        return 'is not one of';
      default:
        return 'bad';
    }
  };

  const toggleLogicConditionResponse = (
    response: ReportTemplateResponseType
  ) => {
    dispatch(toggleReportTemplateLogicConditionResponse(item, logic, response));
  };

  const renderLogicConditionResponsesWindowChildren = () => {
    if (!item.responseSet) return null;

    return (
      <div className="p-2">
        {item.responseSet.responses.map((response, index) => {
          if (!item.responseSet) return null;
          const last = index === item.responseSet.responses.length - 1;
          return (
            <div key={response.id}>
              <Checkbox
                wrapperProps={{ className: classNames({ 'mb-0': last }) }}
                checked={logic.logicConditionResponses
                  .map((innerResponse) => innerResponse.id)
                  .includes(response.id)}
                onChange={() => toggleLogicConditionResponse(response)}
                label={
                  <ReportTemplateResponse color={response.color}>
                    {response.value}
                  </ReportTemplateResponse>
                }
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Wrapper
      item={item}
      logic={logic}
      logicConditionTypes={[
        'CHOICE_IS',
        'CHOICE_IS_NOT',
        'CHOICE_IS_ONE_OF',
        'CHOICE_IS_NOT_ONE_OF',
      ]}
      getLogicConditionTypeText={getLogicConditionTypeText}
    >
      {({ renderConditionTypeWindowChildren }) => (
        <>
          If selection{' '}
          <SimpleButtonWithWindow
            small
            openMode="click"
            hoverStyles={false}
            windowChildren={renderConditionTypeWindowChildren}
          >
            {getLogicConditionTypeText(logic.logicConditionType)}
          </SimpleButtonWithWindow>
          <SimpleButtonWithWindow
            small
            openMode="click"
            hoverStyles={false}
            windowChildren={renderLogicConditionResponsesWindowChildren}
          >
            {(logic.logicConditionResponses ?? []).map((response) => (
              <ReportTemplateResponse
                className="ml-1 mr-0"
                key={response.id}
                color={response.color}
              >
                {response.value}
              </ReportTemplateResponse>
            ))}
          </SimpleButtonWithWindow>
        </>
      )}
    </Wrapper>
  );
};

const BooleanLogicSelector = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [, dispatch] = useReducerContext();

  const getLogicConditionTypeText = () => {
    switch (logic.logicConditionType) {
      case 'BOOLEAN_IS_TRUE':
        return 'is true';
      case 'BOOLEAN_IS_FALSE':
        return 'is false';
      default:
        return 'bad';
    }
  };

  const updateLogicConditionType = (type: string) => {
    dispatch(
      reportTemplateBlockLogicOnChange(item.id, logic.id, {
        logicConditionType: type,
        logicConditionValue: type === 'BOOLEAN_IS_TRUE',
      })
    );
  };

  const renderConditionTypeWindowChildren = ({
    close,
  }: WindowChildrenProps) => {
    return (
      <div className="p-1">
        <ButtonMultiInner
          options={[
            {
              label: 'is true',
              onClick: () => {
                updateLogicConditionType('BOOLEAN_IS_TRUE');
                close();
              },
            },
            {
              label: 'is false',
              onClick: () => {
                updateLogicConditionType('BOOLEAN_IS_FALSE');
                close();
              },
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      If selection{' '}
      <SimpleButtonWithWindow
        small
        openMode="click"
        hoverStyles={false}
        windowChildren={renderConditionTypeWindowChildren}
      >
        {getLogicConditionTypeText()}
      </SimpleButtonWithWindow>
    </>
  );
};

const DerivedLogicSelector = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [derivationEditorIsOpen, toggleDerivationEditorIsOpen] =
    useToggle(false);

  const handleDerivationOnClick = () => {
    toggleDerivationEditorIsOpen(true);
  };

  return (
    <>
      <>
        If{' '}
        <SimpleButton
          small
          hoverStyles={false}
          onClick={handleDerivationOnClick}
        >
          derivation
        </SimpleButton>{' '}
        returns true
      </>

      <ReportTemplateBlockDerivationEditor
        isOpen={derivationEditorIsOpen}
        toggle={toggleDerivationEditorIsOpen}
        item={logic}
      />
    </>
  );
};

const LogicSelectorRouter = ({
  item,
  logic,
}: {
  item: ReportTemplateBlockType;
  logic: ReportTemplateBlockLogicType;
}) => {
  const [, dispatch] = useReducerContext();

  let prefix: React.ReactNode;

  if (logic.logicConditionType === 'DERIVED') {
    prefix = <DerivedLogicSelector item={item} logic={logic} />;
  } else {
    switch (item.valueType) {
      case 'CHAR':
        prefix = <TextLogicSelector item={item} logic={logic} />;
        break;
      case 'NUMBER':
        prefix = <NumberLogicSelector item={item} logic={logic} />;
        break;
      case 'CHOICE':
        prefix = <ChoiceLogicSelector item={item} logic={logic} />;
        break;
      case 'BOOLEAN':
        prefix = <BooleanLogicSelector item={item} logic={logic} />;
        break;
      default:
        prefix = <></>;
        break;
    }
  }

  const askQuestionsOnClick = () => {
    if (!logic.logicTriggers.includes('ASK_QUESTIONS')) {
      const newBlock: ReportTemplateBlockType = {
        id: generateId(),
        label: generateSlateDefault('New question'),
        children: [],
        logicConditionOrder: [],
        logic: [],
        parent: item,
        valueType: 'CHAR',
        responseSet: null,
        isPrivate: false,
        isRepeated: false,
        isRequired: false,
        isDerived: false,
        derivation: [],
        isMultipleSelection: false,
      };

      batch(() => {
        dispatch(
          reportTemplateBlockLogicAddTrigger(item.id, logic.id, 'ASK_QUESTIONS')
        );
        dispatch(addBlockToLogic(newBlock, item, logic));
      });
    }
  };

  const renderWindowChildren = ({ close }: WindowChildrenProps) => {
    return (
      <div className="p-2">
        {!logic.logicTriggers.includes('ASK_QUESTIONS') && (
          <div className="w-100">
            <SimpleButtonLarge
              className="d-block w-100"
              icon={faQuestionSquare}
              onClick={() => {
                askQuestionsOnClick();
                close();
              }}
            >
              <h5 className="mb-0">Ask questions</h5>
              <p className="mb-0">
                If the condition is met, ask further questions.
              </p>
            </SimpleButtonLarge>
          </div>
        )}
        {!logic.logicTriggers.includes('OPEN_ISSUE') && (
          <div className="w-100">
            <SimpleButtonLarge
              className="d-block w-100"
              icon={faExclamationSquare}
              onClick={() => {
                askQuestionsOnClick();
                close();
              }}
            >
              <h5 className="mb-0">Open issue</h5>
              <p className="mb-0">
                If the condition is met, open an issue on the job.
              </p>
            </SimpleButtonLarge>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {prefix} <span>then</span>{' '}
      {logic.logicTriggers.map((logicTrigger) => (
        <SimpleButton key={logicTrigger} small hoverStyles={false}>
          {logicTrigger.replace(/_/g, ' ').toLowerCase()}
        </SimpleButton>
      ))}
      {logic.logicTriggers.length > 0 && ' '}
      <SimpleButtonWithWindow
        small
        openMode="click"
        hoverStyles={false}
        icon={faPlus}
        windowChildren={renderWindowChildren}
      >
        trigger
      </SimpleButtonWithWindow>
    </div>
  );
};

const ReportTemplateBlockLogic = ({
  item,
  onLogicChange,
}: {
  item: ReportTemplateBlockType;
  onLogicChange: OnLogicChange;
}) => {
  const theme = useTheme();
  const [{ reportTemplateState }] = useReducerContext();

  const getLogicText = (logic: ReportTemplateBlockLogicType) => {
    switch (logic.logicConditionType) {
      case 'IS_NOT_NULL':
        return 'If answer is not blank';

      case 'CHAR_IS':
        return `Is ${logic.logicConditionValue || 'blank'}`;
      case 'CHAR_IS_NOT':
        return `Is not ${logic.logicConditionValue || 'blank'}`;

      case 'CHOICE_IS': {
        const choices = logic.logicConditionResponses
          .map((response) => response.value)
          .join(', ');

        return `Is ${choices}`;
      }
      case 'CHOICE_IS_NOT': {
        const choices = logic.logicConditionResponses
          .map((response) => response.value)
          .join(', ');

        return `Is not ${choices}`;
      }
      case 'CHOICE_IS_ONE_OF': {
        const choices = logic.logicConditionResponses
          .map((response) => response.value)
          .join(', ');

        return `Is one of ${choices}`;
      }
      case 'CHOICE_IS_NOT_ONE_OF': {
        const choices = logic.logicConditionResponses
          .map((response) => response.value)
          .join(', ');

        return `Is not one of ${choices}`;
      }

      case 'NUMBER_IS_EQUAL_TO':
        return (
          <>
            <span>=</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_NOT_EQUAL_TO':
        return (
          <>
            <span>&ne;</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_LESS_THAN':
        return (
          <>
            <span>&lt;</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_LESS_THAN_OR_EQUAL_TO':
        return (
          <>
            <span>&#8924;</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_GREATER_THAN':
        return (
          <>
            <span>&gt;</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_GREATER_THAN_OR_EQUAL_TO':
        return (
          <>
            <span>&#8925;</span>{' '}
            <span className="mono">{logic.logicConditionValue || 0}</span>
          </>
        );
      case 'NUMBER_IS_BETWEEN':
        return 'is between';
      case 'NUMBER_IS_NOT_BETWEEN':
        return 'is not between';

      case 'BOOLEAN_IS_TRUE':
        return 'True';

      case 'BOOLEAN_IS_FALSE':
        return 'False';

      case 'DERIVED':
        return 'Derived';

      default:
        return logic.logicConditionValue;
    }
  };

  const value =
    reportTemplateState.value[item.id] ||
    reportTemplateState.valueDefaults[item.id];

  const selectedLogic = item.logic.find((logic) => logic.id === value);
  const { depth, draggableSnapshot } = useContext(Context);

  return (
    <>
      <TemplateBlockLogicWrapper>
        {!draggableSnapshot.isDragging &&
          Array.from({ length: depth + 1 }).map((_, depthIndex) => (
            <DepthLine
              // eslint-disable-next-line react/no-array-index-key
              key={depthIndex}
              depth={depthIndex}
              style={{
                left: 20 + (depthIndex - depth - 1) * 40,
              }}
            />
          ))}
        {item.logic.length > 1 && (
          <div className="pl-3 pt-2">
            {item.logic.map((logic, index) => {
              const text = getLogicText(logic);
              const last = index === item.logic.length - 1;
              return (
                <SimpleButton
                  key={logic.id}
                  small
                  hoverStyles={false}
                  className={classNames({ 'mr-3': !last })}
                  active={value === logic.id}
                  secondary={value !== logic.id}
                  style={{
                    textDecoration: 'none',
                    borderBottom: `2px solid ${
                      value === logic.id
                        ? theme.color.primary.hex()
                        : 'transparent'
                    }`,
                  }}
                  value={logic.id}
                  onClick={onLogicChange}
                >
                  {text}
                </SimpleButton>
              );
            })}
          </div>
        )}
        {selectedLogic && (
          <div
            className="pl-3 py-2"
            style={{
              fontSize: 12,
              borderTop: item.logic.length > 1 ? theme.border : 'none',
              borderColor: theme.color.cardBackground.brighten(0.05).hex(),
            }}
          >
            <LogicSelectorRouter item={item} logic={selectedLogic} />
          </div>
        )}
      </TemplateBlockLogicWrapper>
    </>
  );
};

export default ReportTemplateBlockLogic;
