import { gql } from '@apollo/client';

export const UPDATE_CLIENT_SITE_LOCATION_MUTATION = gql`
  mutation ($clientSiteId: ID!, $placeId: String!) {
    updateClientSiteLocation(clientSiteId: $clientSiteId, placeId: $placeId) {
      clientSiteLocation {
        id
      }
      managementTask {
        id
        type
        name
        description
      }
    }
  }
`;
