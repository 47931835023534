import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useClient, useTablePagination } from 'lib/hooks';
import CardWrapper from '../CardWrapper';
import JobTag from '../JobTag';
import Table from '../Table';
import TableFooter from '../TableFooter';
import { IMMEDIATE_ACTION_JOBS_QUERY } from './query';
import {
  immediateActionJobsQueryOnCompleted,
  useReducerContext,
} from '../ClientDashboard/reducer';
import {
  ImmediateActionJobsQueryData,
  ImmediateActionJobsQueryVariables,
} from '../ClientDashboard/types';

const ClientDashboardImmediateActions = () => {
  const client = useClient();
  const [{ immediateActionJobs }, dispatch] = useReducerContext();

  const { refetch } = useQuery<
    ImmediateActionJobsQueryData,
    ImmediateActionJobsQueryVariables
  >(IMMEDIATE_ACTION_JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id, size: 10 },
    onCompleted: (data) => {
      dispatch(immediateActionJobsQueryOnCompleted(data));
    },
  });

  const [page, handleChangePage] = useTablePagination({
    onChange: async (newPage) => {
      const { data } = await refetch({ page: newPage });
      if (data) dispatch(immediateActionJobsQueryOnCompleted(data));
    },
  });

  return (
    <>
      <CardWrapper
        className="h-100 p-0 d-flex flex-column"
        style={{ minHeight: 0 }}
      >
        {immediateActionJobs && (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5 className="mb-0">Immediate Actions</h5>
              <small style={{ lineHeight: '24px' }}>
                <span className="mono text-75">
                  {immediateActionJobs.count}
                </span>{' '}
                <span className="text-50">immediate actions</span>
              </small>
            </div>
            <Table
              flexBases={[26, 24, 50]}
              className="flex-grow-1"
              style={{ minHeight: 0 }}
            >
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Name</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {immediateActionJobs.results.map((job) => (
                  <tr key={job.id}>
                    <td className="mono py-2">{job.reference}</td>
                    <td className="py-2 pr-3">
                      <Link
                        to={`/clients/${client.slug}/jobs/${job.reference}`}
                      >
                        {job.name}
                      </Link>
                    </td>
                    <td className="py-2">
                      <div className="mr-2 d-flex" style={{ fontSize: 12 }}>
                        {[
                          {
                            id: job.status.id,
                            name: job.status.name,
                            nameDisplay: job.status.nameDisplay,
                            flag: job.status.flag,
                            saliency: -1,
                            color: '',
                          },
                          ...job.tags,
                        ].map((tag, index) => {
                          const last = index === job.tags.length;
                          return (
                            <JobTag
                              key={job.id}
                              job={job}
                              tag={tag}
                              last={last}
                            />
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter
              page={page}
              size={10}
              length={immediateActionJobs.results.length}
              count={immediateActionJobs.count}
              small
              handleChangePage={handleChangePage}
            />
          </>
        )}
      </CardWrapper>
    </>
  );
};

export default ClientDashboardImmediateActions;
