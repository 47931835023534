import React from 'react';
import BaseSelectAsyncCreatable, { Props } from 'react-select/async-creatable';
import { NamedProps } from 'react-select';
import { Option } from 'lib/types';
import { useTheme } from 'styled-components';
import { styles, getTheme } from '../Select';

const SelectAsyncCreatable = <
  T extends Option,
  IsMulti extends boolean = false
>({
  active = false,
  customStyles = {},
  height = 38,
  loadOptions,
  hasError = false,
  ...props
}: NamedProps<T, IsMulti> &
  Props<T, IsMulti> & {
    active?: boolean;
    hasError?: boolean;
    customStyles?: any;
    height?: number;
  }) => {
  const theme = useTheme();
  return (
    <BaseSelectAsyncCreatable
      styles={styles<T, IsMulti>({ active, customStyles, height, hasError })}
      theme={getTheme(theme, height)}
      loadOptions={loadOptions}
      {...props}
    />
  );
};

export default SelectAsyncCreatable;
