import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import classNames from 'classnames';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {
  BreadcrumbWrapperProps,
  BreadcrumbLinkProps,
  BreadcrumbTerminatorProps,
} from './types';
import {
  Wrapper,
  BreadcrumbLinkWrapper,
  BreadcrumbLinkText,
  BreadcrumbText,
  BreadcrumbTerminatorWrapper,
} from './styled';

const BreadcrumbWrapper = ({
  children,
  className,
  ...rest
}: BreadcrumbWrapperProps) => {
  return (
    <Wrapper className={classNames('d-flex', className)} {...rest}>
      {children}
    </Wrapper>
  );
};

const BreadcrumbLink = ({ children, ...rest }: BreadcrumbLinkProps) => {
  return (
    <BreadcrumbLinkWrapper {...rest}>
      <BreadcrumbLinkText>{children}</BreadcrumbLinkText>
    </BreadcrumbLinkWrapper>
  );
};

const BreadcrumbSeparator = () => {
  return (
    <FontAwesomeIcon
      className="mx-2"
      style={{ marginTop: 6 }}
      icon={faChevronRight}
      fixedWidth
    />
  );
};

const BreadcrumbLoader = () => {
  return (
    <FontAwesomeIcon
      style={{ fontSize: '1.25rem', marginTop: 5 }}
      icon={faSpinnerThird}
      fixedWidth
      spin
    />
  );
};

const BreadcrumbTerminator = ({
  children,
  loading,
  ...rest
}: BreadcrumbTerminatorProps) => {
  if (loading) return <BreadcrumbLoader />;
  return (
    <BreadcrumbTerminatorWrapper {...rest}>
      <BreadcrumbText>{children}</BreadcrumbText>
    </BreadcrumbTerminatorWrapper>
  );
};

const Breadcrumb = {
  Wrapper: BreadcrumbWrapper,
  Link: BreadcrumbLink,
  Separator: BreadcrumbSeparator,
  Loader: BreadcrumbLoader,
  Terminator: BreadcrumbTerminator,
};

export default Breadcrumb;
