import React from 'react';
import { Handle, Node, Position } from 'react-flow-renderer';
import { serialize } from 'lib/slate';
import { renderValueType } from '../../utils';
import NodeBase from '../NodeBase';
import { Small } from '../../../Typography';

const NodeInput = (node: Node) => {
  const { id, data } = node;
  return (
    <NodeBase {...node}>
      <div className="d-flex justify-content-between">
        <Small className="mr-4" dark>
          Input
        </Small>
        <small>{renderValueType(data.dataType)}</small>
      </div>
      <div>
        <strong>{serialize(data.label)}</strong>
      </div>
      <Handle id={`source-1-${id}`} type="source" position={Position.Bottom} />
    </NodeBase>
  );
};

export default NodeInput;
