import React, { useCallback, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragUpdate,
  DragStart,
  OnDragEndResponder,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  getAllItems,
  getItem,
  getItemDepth as getItemDepth_,
} from '../../utils';
import { Item, ItemChildren, PlaceholderPosition, TreeProps } from './types';
import { Placeholder, DepthLine } from './styled';

const Tree = <T extends Item>({
  root,
  collapsed,
  logic,
  depthMargin = 40,
  getShouldItemNest,
  renderItem,
  renderPlaceholder,
  onChange,
  onCollapse,
  onLogicChange,
}: TreeProps<T>) => {
  const [currentItemDepth, setCurrentItemDepth] = useState<number>();
  const [placeholderPosition, setPlaceholderPosition] =
    useState<PlaceholderPosition>({});

  const dragEventRef = useRef<DragUpdate>();

  const getParentItem = useCallback(
    (childItemId: string) => {
      return root.items.find((item) => item.children.includes(childItemId));
    },
    [root.items]
  );

  const getIsItemCollapsed = useCallback(
    (itemId: T['id']) => {
      return collapsed.includes(itemId);
    },
    [collapsed]
  );

  const getIsParentItemCollapsed = useCallback(
    (itemId: T['id']) => {
      const parentItem = getParentItem(itemId);
      if (!parentItem) return false;
      return getIsItemCollapsed(parentItem.id);
    },
    [getParentItem, getIsItemCollapsed]
  );

  const getDraggedDOM = useCallback((draggableId: string) => {
    return document.querySelector(
      `[data-rbd-draggable-id='${draggableId}']`
    ) as Element;
  }, []);

  const getItemDepth = useCallback(
    (itemId: T['id']): number => getItemDepth_(itemId, root.items),
    [root.items]
  );

  const getDraggableStyle = (
    reportTemplateBlock: T,
    snapshot: DraggableStateSnapshot,
    style: React.CSSProperties = {}
  ) => {
    if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
      return style;
    }

    const previousDepth = getItemDepth(reportTemplateBlock.id);
    const diffX = ((currentItemDepth ?? 0) - previousDepth) * depthMargin;

    const { moveTo } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x + diffX}px, ${moveTo.y}px)`;

    const width = `calc(${
      style?.width ? `${style.width}px` : '100%'
    } - ${diffX}px)`;

    return {
      ...style,
      transform: translate,
      width,
    };
  };

  const getActiveLogic = useCallback(
    (item: T) => {
      return item.logic.find(
        (innerLogic) => innerLogic.id === logic[item.id as T['id']]
      ) as T;
    },
    [logic]
  );

  const canSeeRoot = useCallback(
    (itemId: T['id']): boolean => {
      const parentItem = getParentItem(itemId);

      if (!parentItem) {
        return true;
      }

      const activeLogic = getActiveLogic(parentItem);

      if (activeLogic && !activeLogic.children.includes(itemId)) {
        return false;
      }

      return canSeeRoot(parentItem.id);
    },
    [getParentItem, getActiveLogic]
  );

  const tree = useMemo(() => {
    return root.items.filter((item) => {
      const isParentItemCollapsed = getIsParentItemCollapsed(item.id);
      if (isParentItemCollapsed) return false;
      return canSeeRoot(item.id);
    });
  }, [root.items, getIsParentItemCollapsed, canSeeRoot]);

  const handleOnMouseMove = useCallback(() => {
    if (!dragEventRef.current) return;
    if (!dragEventRef.current?.destination) return;

    const { draggableId, destination } = dragEventRef.current;

    const draggedDOM = getDraggedDOM(draggableId);
    const matrixString = window.getComputedStyle(draggedDOM).transform;
    const matrix = new WebKitCSSMatrix(matrixString);
    const depth = Math.floor(
      (getItemDepth(draggableId) * depthMargin + matrix.e) / depthMargin
    );

    const otherBlocks = tree.filter((block) => block.id !== draggableId);

    const aboveItem = otherBlocks[destination.index - 1];
    const aboveItemDepth = aboveItem ? getItemDepth(aboveItem.id) : 0;

    const belowItem = otherBlocks[destination.index];
    const belowItemDepth = belowItem ? getItemDepth(belowItem.id) : 0;

    const shouldItemNest =
      aboveItem &&
      (getShouldItemNest?.(aboveItem) ?? true) &&
      !getIsItemCollapsed(aboveItem.id);

    const nextItemDepth = Math.max(
      belowItemDepth,
      Math.min(Math.max(0, depth), aboveItemDepth + (shouldItemNest ? 1 : 0))
    );

    setCurrentItemDepth(nextItemDepth);
  }, [
    getDraggedDOM,
    getItemDepth,
    depthMargin,
    tree,
    getShouldItemNest,
    getIsItemCollapsed,
  ]);

  const startTrackingMouse = () => {
    document.addEventListener('mousemove', handleOnMouseMove);
  };

  const stopTrackingMouse = () => {
    document.removeEventListener('mousemove', handleOnMouseMove);
  };

  const handleOnDragStart = (event: DragStart) => {
    dragEventRef.current = {
      ...event,
      destination: event.source,
    };

    startTrackingMouse();
    const draggedDOM = getDraggedDOM(event.draggableId);

    if (!draggedDOM || !draggedDOM.parentNode) {
      return;
    }

    const { clientWidth, clientHeight } = draggedDOM;
    const clientX = parseFloat(
      window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
    );
    const clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      [...draggedDOM.parentNode.children]
        .slice(0, event.source.index)
        .reduce((total, current) => {
          const style = window.getComputedStyle(current);
          const marginBottom = parseFloat(style.marginBottom);
          return total + current.clientHeight + marginBottom;
        }, 0);

    setPlaceholderPosition({
      width: clientWidth,
      height: clientHeight,
      top: clientY,
      left: clientX,
    });
  };

  const handleOnDragUpdate = (event: DragUpdate) => {
    stopTrackingMouse();
    startTrackingMouse();
    dragEventRef.current = event;

    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDOM(event.draggableId);

    if (!draggedDOM || !draggedDOM.parentNode) {
      return;
    }

    const { clientWidth, clientHeight } = draggedDOM;
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[event.source.index];
    childrenArray.splice(event.source.index, 1);

    const updatedArray = [
      ...childrenArray.slice(0, event.destination.index),
      movedItem,
      ...childrenArray.slice(event.destination.index),
    ];

    const clientX = parseFloat(
      window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
    );
    const clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      updatedArray
        .slice(0, event.destination.index)
        .reduce((total, current) => {
          const style = window.getComputedStyle(current);
          const marginBottom = parseFloat(style.marginBottom);
          return total + current.clientHeight + marginBottom;
        }, 0);

    setPlaceholderPosition({
      width: clientWidth,
      height: clientHeight,
      top: clientY,
      left: clientX,
    });
  };

  const orderChildren = (children: ItemChildren<T>, newTree: T[]) => {
    const itemIds = newTree.map((item) => item.id);
    return [...children].sort(
      (a, b) => itemIds.indexOf(a) - itemIds.indexOf(b)
    );
  };

  const handleOnDragEnd: OnDragEndResponder = ({ source, destination }) => {
    stopTrackingMouse();

    if (!destination) return;

    const newTree = [...tree];
    const item = tree[source.index];

    // We first need to determine the new parent based on
    // the drop destination. To do this, take a slice of
    // the tree, removing all elements below the dragged
    // item. From this slice we then move up the tree in
    // search for the nearest item one level shallower.

    const oldParentItem = getParentItem(item.id) || null;
    let newParentItem: null | T = null;
    if (currentItemDepth && currentItemDepth > 0) {
      const potentialParentItems = newTree
        .slice(
          0,
          // If the dragged item has moved down the tree, i.e. its
          // index has increased, we need to add one to the slice
          // index as we also need to remove the item that moved
          // up the tree in place of the dragged item.
          destination.index + (destination.index > source.index ? 1 : 0)
        )
        .filter((innerItem) => innerItem.id !== item.id)
        .filter(
          (innerItem) => getItemDepth(innerItem.id) === currentItemDepth - 1
        );
      [newParentItem] = [...potentialParentItems].reverse();
    }

    // Follow the usual procedure when moving an item in the tree.
    const [removed] = newTree.splice(source.index, 1);
    newTree.splice(destination.index, 0, removed);

    let rootChildren: ItemChildren<T> = orderChildren(root.children, newTree);
    let items: T[] = [...newTree];

    // Now we need to update the tree to reflect the drop action
    // that has been performed. There are five main cases here:
    //
    // 1. Both the new parent and old parent are not null and
    //    their ids are different: an item has been moved from
    //    one parent to another.

    // 2. The new parent is not null and the old parent is null:
    //    here the item has been dragged from root to a parent.

    // 3. The new parent is null and the old parent is not null:
    //    this means the item has been dragged from a parent to
    //    the root level.

    // 4. Both the new parent and old parent are not null yet
    //    their ids are the same: the item has been dragged to
    //    the same parent, this represents a simple reordering
    //    within a single parent.

    // 5. Both the new parent and old parent are null: this
    //    represents a simple reordering at the root level.
    //
    // We also need to take care of the conditional branches, we
    // do this by looking at the currently active branch for the
    // new parent and adding the item to its children, whilst also
    // removing the item from its previous logical container if it
    // was a child of one.

    if (newParentItem?.id !== oldParentItem?.id) {
      if (newParentItem) {
        // If we get here then we are in either case 1 or 2, we need
        // to add the dragged item id to the parent's children array.

        items = items.map((innerItem) => {
          if (!newParentItem) return innerItem;

          if (innerItem.id === newParentItem.id) {
            let ret: T = {
              ...innerItem,
              children: orderChildren(
                [...innerItem.children, item.id],
                newTree
              ),
            };

            // We also need to add the dragged item id to the
            // children array of the currently active logical
            // container of the new parent, if it exists.

            if (ret.logic.length > 0) {
              ret = {
                ...ret,
                logic: ret.logic.map((innerLogic) => {
                  if (innerLogic.id === logic[ret.id as T['id']]) {
                    return {
                      ...innerLogic,
                      children: [...innerLogic.children, item.id],
                    };
                  }

                  return innerLogic;
                }),
              };
            }

            return ret;
          }

          return innerItem;
        });
      } else {
        // This represents the more simple case 3, we just
        // need to add the dragged item id to the root level.

        rootChildren = [...rootChildren, item.id];
      }

      if (oldParentItem) {
        // If we get to this point then we are in case 1 or 3,
        // we need to remove the dragged item id from the old
        // parent's children array.

        items = items.map((innerItem) => {
          if (!oldParentItem) return innerItem;

          if (innerItem.id === oldParentItem.id) {
            let ret: T = {
              ...innerItem,
              children: orderChildren(
                oldParentItem.children.filter(
                  (childItemId) => childItemId !== item.id
                ),
                newTree
              ),
            };

            // We also need to remove the dragged item id
            // from the active logical container if the
            // old parent has one.

            if (ret.logic.length > 0) {
              ret = {
                ...ret,
                logic: ret.logic.map((innerLogic) => {
                  if (innerLogic.id === logic[ret.id as T['id']]) {
                    return {
                      ...innerLogic,
                      children: innerLogic.children.filter(
                        (childItemId) => childItemId !== item.id
                      ),
                    };
                  }

                  return innerLogic;
                }),
              };
            }

            return ret;
          }

          return innerItem;
        });
      } else {
        // This represents the other simple case 2, we
        // need to remove the item id from root level.

        rootChildren = rootChildren.filter(
          (childItemId) => childItemId !== item.id
        );
      }
    } else if (newParentItem) {
      // If we have reached here then we are in case 4, we need to
      // simply reorder the children of the current parent based on
      // the new global order of the tree.

      items = items.map((innerItem) =>
        innerItem.id === newParentItem?.id
          ? {
              ...innerItem,
              children: orderChildren(innerItem.children, newTree),
            }
          : innerItem
      );
    } else {
      // This final branch represents case 5, again, we just need
      // to reorder the children based on the new global order.

      rootChildren = [...new Set([...rootChildren, item.id])];
    }

    // We now need to reconstruct the expanded item array based on
    // the newly updated order of the tree. We begin by updating
    // the items in the array to reflect the changes in the children
    // arrays.

    const updatedItems: T[] = root.items.map((innerItem) => {
      const newItem = items.find((findItem) => findItem.id === innerItem.id);
      if (newItem) {
        return { ...innerItem, ...newItem, children: newItem.children };
      }
      return innerItem;
    });

    // If the new parent item is not null remove the dragged item
    // from the array temporarily. TODO: Why do this?

    items = newParentItem
      ? updatedItems.filter((innerItem) => innerItem.id !== item.id)
      : updatedItems;

    rootChildren = orderChildren(rootChildren, newTree);

    items = (
      !newParentItem && newParentItem !== oldParentItem
        ? [
            ...items.filter((innerItem) => getItemDepth(innerItem.id) === 0),
            item,
          ]
        : items.filter((innerItem) => getItemDepth(innerItem.id) === 0)
    )
      .sort((a, b) => rootChildren.indexOf(a.id) - rootChildren.indexOf(b.id))
      .flatMap((innerItem) => getAllItems(innerItem.id, updatedItems));

    // Finally, update the item's parent.
    items = items.map((innerItem) =>
      innerItem.id === item.id
        ? {
            ...innerItem,
            parent: newParentItem ? { id: newParentItem.id } : { id: root.id },
          }
        : innerItem
    );

    onChange({
      ...root,
      children: rootChildren,
      items,
    });
  };

  const handleToggleCollapseItem = (itemId: T['id']) => () => {
    const item = getItem(itemId, root.items);
    if (!item || item.children.length === 0) return;

    const children = getAllItems(item.id, root.items).filter(
      (innerItem) => innerItem.id !== item.id
    );

    onCollapse(
      collapsed.includes(itemId)
        ? collapsed.filter((collapsedItemId) => collapsedItemId !== itemId)
        : [
            ...new Set([
              ...collapsed,
              item.id,
              ...children
                .filter((childItem) => childItem.children.length > 0)
                .map((childItem) => childItem.id),
            ]),
          ]
    );
  };

  const handleOnLogicChange =
    (itemId: T['id']) => (event: React.MouseEvent<HTMLButtonElement>) => {
      const { value: logicId } = event.currentTarget;
      onLogicChange(itemId, logicId);
    };

  return (
    <DragDropContext
      onDragStart={handleOnDragStart}
      onDragUpdate={handleOnDragUpdate}
      onDragEnd={handleOnDragEnd}
    >
      <Droppable droppableId="top">
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {tree.map((item, index) => {
              const isItemCollapsed = getIsItemCollapsed(item.id);
              const isDragDisabled =
                item.children.length > 0 && !isItemCollapsed;
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(draggableProvided, draggableSnapshot) => {
                    const itemDepth = getItemDepth(item.id);
                    const itemStyle = getDraggableStyle(
                      item,
                      draggableSnapshot,
                      draggableProvided.draggableProps.style
                    );
                    return (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={itemStyle}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          {!draggableSnapshot.isDragging &&
                            Array.from({ length: itemDepth }).map(
                              (_, depthIndex) => (
                                <DepthLine
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={depthIndex}
                                  depth={depthIndex}
                                  style={{
                                    left:
                                      depthMargin / 2 +
                                      depthIndex * depthMargin,
                                  }}
                                />
                              )
                            )}
                        </div>
                        <div
                          style={{
                            height: '100%',
                            marginLeft: itemDepth * depthMargin,
                          }}
                        >
                          {renderItem({
                            item,
                            depth: itemDepth,
                            isCollapsed: isItemCollapsed,
                            activeLogicId: logic[item.id as T['id']],
                            draggableProvided,
                            draggableSnapshot,
                            toggleCollapse: handleToggleCollapseItem(item.id),
                            onLogicChange: handleOnLogicChange(item.id),
                          })}
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
            {!isEmpty(placeholderPosition) && droppableSnapshot.isDraggingOver && (
              <div
                style={{
                  position: 'absolute',
                  ...placeholderPosition,
                  left: (currentItemDepth ?? 0) * depthMargin,
                  width:
                    (placeholderPosition.width as number) -
                    (currentItemDepth ?? 0) * depthMargin,
                }}
              >
                {Array.from({ length: currentItemDepth ?? 0 }).map(
                  (_, depthIndex) => (
                    <DepthLine
                      // eslint-disable-next-line react/no-array-index-key
                      key={depthIndex}
                      depth={depthIndex}
                      style={{
                        left:
                          depthMargin / 2 +
                          (depthIndex - (currentItemDepth ?? 0)) * depthMargin,
                      }}
                    />
                  )
                )}
                {renderPlaceholder ? renderPlaceholder({}) : <Placeholder />}
              </div>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DepthLine } from './styled';

export default Tree;
