import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../index.css';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from 'lib/theme';
import { msalApplication } from 'lib/msal';
import Login from '../components/Login';
import AppInner from '../AppInner';

const RequireAuth = ({ children }: React.PropsWithChildren<unknown>) => {
  const account = msalApplication.getAccount();
  if (!account) return <Navigate to="/login" />;
  return <>{children}</>;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <ThemeProvider theme={{ mode: 'DARK', ...darkTheme }}>
              <Login />
            </ThemeProvider>
          }
        />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <AppInner />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
