import React from 'react';
import { RadioButtonProps } from './types';
import { Wrapper, Input, Label } from './styled';

const RadioButton = ({
  id,
  label,
  checked,
  value,
  onClick,
}: RadioButtonProps) => {
  const inputId = `radio-button-${id}`;
  return (
    <Wrapper>
      <Input
        id={inputId}
        checked={checked}
        type="radio"
        value={value}
        onClick={onClick}
      />
      <Label htmlFor={inputId}>
        <span>{label}</span>
      </Label>
    </Wrapper>
  );
};

export default RadioButton;
