import { gql } from '@apollo/client';

export const REPORT_TEMPLATES_QUERY = gql`
  query ($clientId: ID!) {
    reportTemplates(clientId: $clientId) {
      id
      name
    }
  }
`;

export const CREATE_REPORT_TEMPLATE_MUTATION = gql`
  mutation ($clientId: ID!, $data: CreateReportTemplateInput!) {
    createReportTemplate(clientId: $clientId, reportTemplateData: $data) {
      reportTemplate {
        id
        name
      }
    }
  }
`;
