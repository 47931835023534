import { Item } from './components/Tree/types';

export const getItem = <T extends Item>(itemId: T['id'], items: T[]) => {
  return items.find((item) => item.id === itemId) as T;
};

export const getAllItems = <T extends Item>(
  startingBlockId: T['id'],
  items: T[]
): T[] => {
  const item = getItem(startingBlockId, items);

  if (!item) {
    return [];
  }

  if (item.children.length === 0) {
    return [item];
  }

  return [
    item,
    ...item.children.flatMap((childItemId) => getAllItems(childItemId, items)),
  ];
};

export const getItemDescendants = <T extends Item>(
  itemId: T['id'],
  items: T[]
) => {
  return getAllItems(itemId, items).filter((item) => item.id !== itemId);
};

export const getParentItem = <T extends Item>(
  childItemId: T['id'],
  items: T[]
) => {
  return items.find((item) => item.children.includes(childItemId));
};

export const getItemIsCollapsed = <T extends Item>(
  itemId: T['id'],
  collapsed: T['id'][]
) => {
  return collapsed.includes(itemId);
};

export const getItemDepth = <T extends Item>(
  itemId: T['id'],
  items: T[]
): number => {
  let parentItem = getParentItem(itemId, items);
  if (!parentItem) return 0;

  let depth = 0;
  while (parentItem.children.length > 0) {
    depth += 1;
    parentItem = getParentItem(parentItem.id, items);
    if (!parentItem) return depth;
  }

  return depth;
};
