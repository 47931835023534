import { matchPath, useLocation } from 'react-router-dom';

function useIsPathActive(path: string): boolean;

function useIsPathActive(): (path: string) => boolean;

function useIsPathActive(path?: string) {
  const location = useLocation();

  if (path) {
    return !!matchPath(path, location.pathname);
  }

  return (pattern: string) => {
    return !!matchPath(pattern, location.pathname);
  };
}

export default useIsPathActive;
