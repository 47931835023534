import { gql } from '@apollo/client';

export const MANAGEMENT_TASKS_QUERY = gql`
  query ($clientId: ID!, $managementTaskType: String) {
    managementTaskTypes(clientId: $clientId) {
      results
      counts
    }
    managementTasks(
      clientId: $clientId
      managementTaskType: $managementTaskType
    ) {
      results {
        id
        type
        name
        description
        status
        priority
        instance {
          ... on ManagementTaskClientSite {
            type
            data {
              id
              name
              location {
                id
                address
                meta
              }
            }
          }
          ... on ManagementTaskJobIssue {
            type
            data {
              id
              status
              issueType
              issueTypeDisplay
              job {
                reference
              }
            }
          }
        }
      }
    }
  }
`;
