import React from 'react';
import { Layout } from 'lib/layout';
import { usePageLayout } from 'lib/hooks';
import PortalBreadcrumb from '../PortalBreadcrumb';

const HomeDashboard = () => {
  const [pageLayout, handlePageLayoutOnChange] = usePageLayout(
    'HomeDashboard',
    [{ type: 'INCOMING_JOBS', data: {}, x: 0, y: 0, w: 2, h: 2 }]
  );

  return (
    <>
      <PortalBreadcrumb>
        <h4>Dashboard</h4>
      </PortalBreadcrumb>
      <div className="h-100" style={{ margin: -10 }}>
        <Layout
          cols={12}
          layout={pageLayout}
          onLayoutChange={handlePageLayoutOnChange}
        />
      </div>
    </>
  );
};

export default HomeDashboard;
