import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useClient } from 'lib/hooks';
import { ReportTemplate } from 'lib/types';
import { generateId } from 'lib/utils';
import Breadcrumb from '../Breadcrumb';
import PortalBreadcrumb from '../PortalBreadcrumb';
import SimpleButton from '../SimpleButton';
import {
  CREATE_REPORT_TEMPLATE_MUTATION,
  REPORT_TEMPLATES_QUERY,
} from './query';

const ClientSettingsReportTemplates = () => {
  const client = useClient();
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);

  useQuery(REPORT_TEMPLATES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id },
    onCompleted: (data) => {
      setReportTemplates(data.reportTemplates);
    },
  });

  const [createReportTemplate, { loading: creating }] = useMutation(
    CREATE_REPORT_TEMPLATE_MUTATION
  );

  const handleCreateReportTemplateOnClick = async () => {
    const { data } = await createReportTemplate({
      variables: { clientId: client.id, data: { name: generateId() } },
    });
    if (data) {
      const { reportTemplate: createdReportTemplate } =
        data.createReportTemplate;
      setReportTemplates((prevReportTemplates) => [
        ...prevReportTemplates,
        createdReportTemplate,
      ]);
    }
  };

  return (
    <>
      <PortalBreadcrumb>
        <Breadcrumb.Wrapper>
          <Breadcrumb.Link to={`/clients/${client.slug}/settings`}>
            Settings
          </Breadcrumb.Link>
          <Breadcrumb.Separator />
          <Breadcrumb.Terminator>Report templates</Breadcrumb.Terminator>
        </Breadcrumb.Wrapper>
      </PortalBreadcrumb>
      <div>
        <div>
          <SimpleButton
            loading={creating}
            onClick={handleCreateReportTemplateOnClick}
          >
            Create template
          </SimpleButton>
        </div>
        <div>
          {reportTemplates.map((reportTemplate) => (
            <Link
              key={reportTemplate.id}
              to={`/clients/${client.slug}/settings/report-templates/${reportTemplate.id}`}
            >
              <div>{reportTemplate.name}</div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClientSettingsReportTemplates;
