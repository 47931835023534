import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  useClient,
  useScrollBeyond,
  useScrollTo,
  useSetTaskbarData,
} from 'lib/hooks';
import CardWrapper from '../CardWrapper';
import ClientJobInformation from '../ClientJobInformation';
import ClientJobScheduler from '../ClientJobScheduler';
import { useReducerContext } from '../ClientJob/reducer';
import ClientJobHomeMap from '../ClientJobHomeMap';
import ClientJobTabBarExtension from '../ClientJobTabBarExtension';
import Transition from '../Transition';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import ClientJobHistory from '../ClientJobHistory';
import LogBlock from '../LogBlock';

const ClientJobHome = () => {
  const client = useClient();
  const [{ job }] = useReducerContext();

  useSetTaskbarData({
    type: 'JOB',
    label: `Job ${job?.reference}`,
    path: `/clients/${client.slug}/jobs/${job?.reference}`,
  });

  const scrollTo = useScrollTo();
  const [scrolledBeyond, scrollRef] = useScrollBeyond<HTMLDivElement>({
    offset: 132,
    detectionEdge: 'bottom',
  });

  return (
    <>
      <div className="h-100 d-flex flex-column">
        <div ref={scrollRef}>
          <Row noGutters style={{ height: 300 }} className="mb-4">
            <Col xl={7} className="h-100 mr-4">
              <CardWrapper className="h-100 p-0">
                <ClientJobInformation />
              </CardWrapper>
            </Col>
            <Col className="h-100">
              <CardWrapper className="h-100 p-1">
                <ClientJobHomeMap />
              </CardWrapper>
            </Col>
          </Row>
        </div>
        <div className="flex-grow-1">
          <CardWrapper className="p-0 h-100" style={{ borderWidth: 4 }}>
            <ClientJobScheduler readOnly={false} />
          </CardWrapper>
        </div>
      </div>
      <ClientJobTabBarExtension>
        <Transition.Fade show={scrolledBeyond}>
          {({ show, delay }) => (
            <div
              className="ml-2"
              style={{
                opacity: show ? 1 : 0,
                transition: `opacity ${delay}ms`,
              }}
            >
              <span className="text-50 mr-2" style={{ lineHeight: '32px' }}>
                Last update:
              </span>
              <SimpleButtonWithWindow
                active
                className="py-0 pl-0 pr-2 overflow-hidden"
                onClick={() => scrollTo(0, 0)}
                windowChildren={() => (
                  <div style={{ height: 400 }}>
                    <ClientJobHistory />
                  </div>
                )}
              >
                {job && <LogBlock simple logBlock={job.logBlocks[0]} />}
              </SimpleButtonWithWindow>
            </div>
          )}
        </Transition.Fade>
      </ClientJobTabBarExtension>
    </>
  );
};

export default ClientJobHome;
