import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  LayoutSidebarIconProps,
  LayoutSidebarMenuProps,
  LayoutSidebarMenuOverlayProps,
} from './types';

export const LayoutSidebarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.sidebarBackground.hex()};
  z-index: 15;
  transform: translateX(0);

  .layout-sidebar__name {
    color: white;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 1px;
  }
`;

export const LayoutSidebarNav = styled.nav`
  margin-top: 1rem;
  height: calc(100vh - 1rem - 80px);
`;

export const LayoutSidebarIcon = styled(Link)<LayoutSidebarIconProps>`
  display: block;
  position: relative;
  font-weight: ${({ active }) => (active ? 'normal' : 'lighter')};
  text-align: center;
  cursor: pointer;
  transition: background-color 100ms, color 100ms;
  color: ${({ theme }) => theme.color.sidebarText.hex()};
  background-color: ${({ theme, active }) =>
    active ? theme.color.sidebarBackground.darken(0.5).hex() : 'transparent'};
  border-radius: 0.25rem;

  width: 40px;
  height: 40px;
  margin: 20px;
  padding: 8px;

  .icon {
    display: block;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.sidebarBackground.darken(0.5).hex()};
    color: ${({ theme }) => theme.color.sidebarText.hex()};
    text-decoration: none;
  }

  div.label-tooltip {
    position: absolute;
    left: calc(100% + 0.5rem);
    top: 50%;
    transform: translateY(-50%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) =>
      theme.color.sidebarBackground.darken(0.5).hex()};
    color: ${({ theme }) => theme.color.sidebarText.hex()};
  }
`;

export const LayoutSidebarIconButtonStyled = styled(
  LayoutSidebarIcon
)<LayoutSidebarIconProps>`
  border: none;
  //width: ${({ active }) => (active ? '94px' : '78px')};

  &:focus {
    outline: none;
  }

  .decoration.upper {
    bottom: 100%;
  }

  .decoration.lower {
    top: 100%;
  }
`;

export const LayoutSidebarIconButton = ({
  children,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>> &
  LayoutSidebarIconProps) => (
  <LayoutSidebarIconButtonStyled as="button" {...props}>
    {children}
  </LayoutSidebarIconButtonStyled>
);

export const LayoutSidebarMenu = styled.div<LayoutSidebarMenuProps>`
  position: absolute;
  top: 0;
  left: 80px;
  width: 330px;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  border-right: ${({ theme }) => theme.border};
  border-left: ${({ theme }) => theme.border};
  z-index: 14;
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : '-100%')});
  transition: transform 100ms;

  .layout-sidebar__menu-header {
    height: calc(70px + 2rem + 1px);
    border-bottom: ${({ theme }) => theme.border};

    h4 {
      margin-bottom: 0;
    }
  }

  .layout-sidebar__menu-inner {
    padding-top: 2rem;
  }
`;

export const LayoutSidebarMenuOverlay = styled.div<LayoutSidebarMenuOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 13;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 100ms;
`;
