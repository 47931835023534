import classNames from 'classnames';
import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useClient, useTable } from 'lib/hooks';
import { MANAGEMENT_TASKS_QUERY } from '../ClientSettingsManagement/query';
import {
  managementTasksQueryOnCompleted,
  managementTasksRefetchOnCompleted,
  useReducerContext,
} from '../ClientSettingsManagement/reducer';
import {
  ManagementTasksQueryData,
  ManagementTasksQueryVariables,
  ManagementTaskTypesQueryData,
  ManagementTaskTypesQueryVariables,
} from './types';
import SearchBar from '../SearchBar';
import Table from '../Table';
import { MANAGEMENT_TASK_TYPES_QUERY } from './query';
import Number from '../Number';

const ClientSettingsManagementTaskTypeTable = () => {
  const client = useClient();
  const [{ managementTaskTypes, managementTaskType }, dispatch] =
    useReducerContext();

  const [getManagementTaskTypes] = useLazyQuery<
    ManagementTaskTypesQueryData,
    ManagementTaskTypesQueryVariables
  >(MANAGEMENT_TASK_TYPES_QUERY);

  const { searchInputProps, refetching } = useTable(
    ['name'],
    (variables) =>
      getManagementTaskTypes({
        variables: { ...variables, clientId: client.id },
      }),
    {
      defaultActiveName: 'name',
      sortOnCompleted: () => {},
      searchOnCompleted: () => {},
      changePageCompleted: () => {},
    }
  );

  const { refetch, loading } = useQuery<
    ManagementTasksQueryData,
    ManagementTasksQueryVariables
  >(MANAGEMENT_TASKS_QUERY, {
    variables: { clientId: client.id },
    onCompleted: (data) => {
      dispatch(managementTasksQueryOnCompleted(data));
    },
  });

  const handleManagementTaskTypeOnClick = async (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    const { managementTaskType: managementTaskTypeOrNull } =
      event.currentTarget.dataset;
    const managementTaskType =
      managementTaskTypeOrNull === 'ALL'
        ? null
        : (managementTaskTypeOrNull as string);
    const { data } = await refetch({ managementTaskType });
    dispatch(managementTasksRefetchOnCompleted(managementTaskType, data));
  };

  return (
    <div>
      <div className="m-3">
        <SearchBar
          wrapperClassName="mr-0"
          {...searchInputProps}
          loading={loading || refetching}
          placeholder="Search..."
        />
      </div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr
            className={classNames({ active: !managementTaskType })}
            onClick={handleManagementTaskTypeOnClick}
          >
            <td className="d-flex justify-content-between pr-3 table-cell-with-button">
              <div style={{ lineHeight: '34px' }}>All management tasks</div>
              <div>
                <Number value={managementTaskTypes.counts.TOTAL} />
              </div>
            </td>
          </tr>
          {managementTaskTypes.results.map((innerManagementTaskType) => (
            <tr
              key={innerManagementTaskType}
              className={classNames({
                active: managementTaskType === innerManagementTaskType,
              })}
              data-management-task-type={innerManagementTaskType}
              onClick={handleManagementTaskTypeOnClick}
            >
              <td className="d-flex justify-content-between pr-3 table-cell-with-button">
                <div className="text-capitalize" style={{ lineHeight: '34px' }}>
                  {innerManagementTaskType.replace(/_/g, ' ').toLowerCase()}
                </div>
                <div>
                  <Number
                    value={managementTaskTypes.counts[innerManagementTaskType]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ClientSettingsManagementTaskTypeTable;
