import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 0.25rem;

  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
`;
