import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import UserNotes from 'components/UserNotes';
import { INITIAL_QUERY } from './query';
import { useUser } from '../../../hooks';
import { UserNote } from '../../../types';

export const BlockToolboxCardUserNotes = () => {
  return (
    <div>
      <h1>Notes</h1>
    </div>
  );
};

const BlockUserNotes = () => {
  const [userNotes, setUserNotes] = useState<UserNote[]>([]);
  const [mode, setMode] = useState<'list' | 'create' | 'edit'>('list');

  useQuery(INITIAL_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserNotes(data.userNotes);
    },
  });

  return (
    <div className="position-relative">
      <UserNotes
        mode={mode}
        setMode={setMode}
        userNotes={userNotes}
        setUserNotes={setUserNotes}
      />
    </div>
  );
};

export default BlockUserNotes;
