import styled from 'styled-components';
import Button from '../Button';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 80px;
  height: 500px;
  width: 500px;
  z-index: 14;
  overflow: hidden;
`;

export const WindowHeader = styled.div`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  overflow: hidden;
`;

export const WindowButton = styled(Button)`
  border: none;
  border-radius: 0;

  &:hover {
    border: none;
    background-color: #ff0000aa;
  }
`;
