import { DateTime } from 'luxon';
import { DataType } from '../types';

const getValueDisplay = (dataType: DataType, value: any): string => {
  if (!dataType) return 'Invalid data type';
  if (typeof value === 'undefined') return 'Not set';

  switch (dataType) {
    case 'NUMBER':
      return value;
    case 'BOOLEAN':
      return value ? 'True' : 'False';
    case 'DATE_TIME':
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL);
    default:
      return 'Invalid data type';
  }
};

export default getValueDisplay;
