import { useLazyQuery, useMutation } from '@apollo/client';
import { faAbacus } from '@fortawesome/pro-duotone-svg-icons/faAbacus';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons/faCheckSquare';
import { faMapPin } from '@fortawesome/pro-duotone-svg-icons/faMapPin';
import { faPen } from '@fortawesome/pro-duotone-svg-icons/faPen';
import { faText } from '@fortawesome/pro-duotone-svg-icons/faText';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { useTheme } from 'styled-components';
import {
  ReportTemplateBlock,
  ReportTemplateResponse as ReportTemplateResponseType,
  ValueType,
} from 'lib/types';
import { useClickedOutside, useDebounceCallback } from 'lib/hooks';
import ColorPicker from '../ColorPicker';
import {
  CREATE_REPORT_TEMPLATE_RESPONSE_MUTATION,
  DELETE_REPORT_TEMPLATE_RESPONSE_MUTATION,
  REPORT_TEMPLATE_RESPONSE_SETS_QUERY,
  UPDATE_REPORT_TEMPLATE_RESPONSE_MUTATION,
  UPDATE_REPORT_TEMPLATE_RESPONSE_SET_MUTATION,
} from './query';
import {
  createReportTemplateResponseMutationOnCompleted,
  deleteReportTemplateResponseMutationOnCompleted,
  reportTemplateBlockOnChange,
  reportTemplateResponseOrderOnChange,
  reportTemplateResponseSetsQueryOnCompleted,
  reportTemplateResponseValueOnChange,
  selectReportTemplateResponseSetById,
  useReducerContext,
} from '../ReportTemplateEditor/reducer';
import { ChoicePill, Response, Input } from './styled';
import {
  CreateReportTemplateResponseMutationData,
  CreateReportTemplateResponseMutationVariables,
  DeleteReportTemplateResponseMutationData,
  DeleteReportTemplateResponseMutationVariables,
  ReportTemplateResponseSetsQueryData,
  UpdateReportTemplateResponseMutationData,
  UpdateReportTemplateResponseMutationVariables,
  UpdateReportTemplateResponseSetMutationData,
  UpdateReportTemplateResponseSetMutationVariables,
} from './types';
import SearchBar from '../SearchBar';
import SidePanel from '../SidePanel';
import SimpleButton from '../SimpleButton';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { Small } from '../Typography';
import { Color } from '../ColorPicker/styled';
import { ReportTemplateResponse } from '../ReportTemplateResponseSet';

const ResponseContent = ({
  inputRef,
  response,
  focusedResponse,
  setFocusedResponse,
}: {
  inputRef?: React.RefObject<HTMLInputElement>;
  response: ReportTemplateResponseType;
  focusedResponse: undefined | ReportTemplateResponseType;
  setFocusedResponse: Dispatch<
    SetStateAction<undefined | ReportTemplateResponseType>
  >;
}) => {
  const theme = useTheme();
  const [, dispatch] = useReducerContext();

  const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);

  const [updateReportTemplateResponse] = useMutation<
    UpdateReportTemplateResponseMutationData,
    UpdateReportTemplateResponseMutationVariables
  >(UPDATE_REPORT_TEMPLATE_RESPONSE_MUTATION);

  const updateReportTemplateResponseDebounced = useDebounceCallback(
    updateReportTemplateResponse
  );

  const [deleteReportTemplateResponse] = useMutation<
    DeleteReportTemplateResponseMutationData,
    DeleteReportTemplateResponseMutationVariables
  >(DELETE_REPORT_TEMPLATE_RESPONSE_MUTATION);

  const handleResponseValueOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    dispatch(reportTemplateResponseValueOnChange(response.id, { value }));
    updateReportTemplateResponseDebounced({
      variables: { reportTemplateResponseId: response.id, data: { value } },
    });
  };

  const handleResponseColorOnChange = (color: string) => {
    setColorPickerIsOpen(false);
    dispatch(reportTemplateResponseValueOnChange(response.id, { color }));
    updateReportTemplateResponseDebounced({
      variables: { reportTemplateResponseId: response.id, data: { color } },
    });
  };

  const handleDeleteResponseOnClick = async () => {
    const { data } = await deleteReportTemplateResponse({
      variables: { reportTemplateResponseId: response.id },
    });
    if (data) {
      dispatch(deleteReportTemplateResponseMutationOnCompleted(data));
    }
  };

  const handleColorOnClick = () => {
    setFocusedResponse(response);
    setColorPickerIsOpen(true);
  };

  const handleResponseOnFocus = () => {
    setFocusedResponse(response);
    setColorPickerIsOpen(false);
  };

  const isFocused = response.id === focusedResponse?.id;

  return (
    <div className="position-relative ml-1 flex-grow-1 mr-2">
      <Input
        ref={inputRef}
        value={response.value}
        isFocused={isFocused}
        onFocus={handleResponseOnFocus}
        onChange={handleResponseValueOnChange}
      />
      {isFocused && (
        <div className="mt-2 d-flex justify-content-end">
          <SimpleButton icon={faTrashAlt} onClick={handleDeleteResponseOnClick}>
            Delete
          </SimpleButton>
        </div>
      )}
      <div className="position-absolute" style={{ top: 5, right: 4 }}>
        <SimpleButtonWithWindow
          openMode="controlled"
          position="right"
          isOpen={colorPickerIsOpen && isFocused}
          windowChildren={() => (
            <ColorPicker onChange={handleResponseColorOnChange} />
          )}
          windowProps={{
            style: { zIndex: 9999 },
            tabIndex: 0,
          }}
          windowInnerProps={{
            style: { backgroundColor: theme.color.contentBackground.hex() },
          }}
          renderButton={(props: any) => (
            <Color
              ref={props.innerRef}
              style={{
                backgroundColor: response.color,
                width: 24,
                height: 24,
              }}
              {...props}
              onClick={handleColorOnClick}
            />
          )}
        />
      </div>
    </div>
  );
};

const ReportTemplateBlockValueTypeEditor = ({
  item,
}: {
  item: ReportTemplateBlock;
}) => {
  const theme = useTheme();
  const [{ reportTemplateResponseSets, reportTemplateResponseSet }, dispatch] =
    useReducerContext();
  const [isOpen, setIsOpen] = useState(false);
  const [sidePanelIsOpen, setSidePanelIsOpen] = useState(false);

  const [getReportTemplateResponseSets] = useLazyQuery<
    ReportTemplateResponseSetsQueryData,
    null
  >(REPORT_TEMPLATE_RESPONSE_SETS_QUERY);

  const handleOnClick = () => {
    setIsOpen((prevIsOpen) => {
      const ret = !prevIsOpen;
      if (ret) {
        (async () => {
          const { data } = await getReportTemplateResponseSets();
          if (data) {
            dispatch(reportTemplateResponseSetsQueryOnCompleted(data));
          }
        })();
      }
      return ret;
    });
  };

  const handleResponseSetOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: responseSetId } = event.currentTarget;
    dispatch(
      reportTemplateBlockOnChange(item.id, {
        valueType: 'CHOICE',
        responseSet: reportTemplateResponseSets.find(
          (responseSet) => responseSet.id === responseSetId
        ),
        logic: [],
        children: [],
      })
    );
    setIsOpen(false);
  };

  const handleValueTypeOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: valueType } = event.currentTarget;
    dispatch(
      reportTemplateBlockOnChange(item.id, {
        valueType: valueType as ValueType,
        logic: [],
        children: [],
      })
    );
    setIsOpen(false);
  };

  const handleToggleSidePanel = () => {
    setSidePanelIsOpen((prevSidePanelIsOpen) => !prevSidePanelIsOpen);
  };

  const handleEditResponseSetOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: reportTemplateResponseSetId } = event.currentTarget;
    dispatch(selectReportTemplateResponseSetById(reportTemplateResponseSetId));
    handleToggleSidePanel();
  };

  const renderChoicePill = (response: ReportTemplateResponseType) => {
    return <ChoicePill color={response.color}>{response.value}</ChoicePill>;
  };

  const renderWindowChildren = () => {
    return (
      <div className="d-flex">
        <div style={{ borderRight: theme.border }}>
          <div className="m-3">
            <SearchBar wrapperClassName="mr-0" />
          </div>
          <div>
            <Small className="ml-3">Multiple choice responses</Small>
            {reportTemplateResponseSets.map(
              (innerReportTemplateResponseSet) => (
                <div className="position-relative" style={{ width: 400 }}>
                  <SimpleButton
                    className="d-block w-100 text-left rounded-0 px-3"
                    style={{ overflow: 'hidden', minWidth: 0 }}
                    value={innerReportTemplateResponseSet.id}
                    onClick={handleResponseSetOnClick}
                  >
                    {innerReportTemplateResponseSet.responses.map(
                      renderChoicePill
                    )}
                  </SimpleButton>
                  <SimpleButton
                    className="position-absolute"
                    style={{ top: 0, right: 0, borderRadius: '50%' }}
                    icon={faPen}
                    value={innerReportTemplateResponseSet.id}
                    onClick={handleEditResponseSetOnClick}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <div className="mx-3 mt-3">
            <Small>Other responses</Small>
          </div>
          <SimpleButton
            className="d-block w-100 text-left rounded-0 px-3"
            value="CHAR"
            icon={faText}
            onClick={handleValueTypeOnClick}
          >
            Text
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0 px-3"
            value="NUMBER"
            icon={faAbacus}
            onClick={handleValueTypeOnClick}
          >
            Number
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0 px-3"
            value="BOOLEAN"
            icon={faCheckSquare}
            onClick={handleValueTypeOnClick}
          >
            Checkbox
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0 px-3"
            value="DATETIME"
            icon={faCalendarAlt}
            onClick={handleValueTypeOnClick}
          >
            Date & Time
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0 px-3"
            value="LOCATION"
            icon={faMapPin}
            onClick={handleValueTypeOnClick}
          >
            Location
          </SimpleButton>
        </div>
      </div>
    );
  };

  const getValueTypeLabel = (valueType: ValueType) => {
    switch (valueType) {
      case 'CONTAINER':
        return 'Section';
      case 'CHAR':
        return 'Text';
      case 'NUMBER':
        return 'Number';
      case 'BOOLEAN':
        return 'Checkbox';
      case 'DATETIME':
        return 'Date & Time';
      case 'CHOICE':
        if (item.responseSet) {
          return item.responseSet.responses.map((response, index) => {
            if (!item.responseSet) return null;
            const last = index === item.responseSet.responses.length - 1;
            return (
              <ReportTemplateResponse
                key={response.id}
                className={classNames({ 'mr-1': !last })}
                color={response.color}
              >
                {response.value}
              </ReportTemplateResponse>
            );
          });
        }
        return null;
      default:
        return '';
    }
  };

  const responseInputRef = useRef<HTMLInputElement>(null);
  const [focusedResponse, setFocusedResponse] =
    useState<ReportTemplateResponseType>();

  const [createReportTemplateResponse, { loading: creatingResponse }] =
    useMutation<
      CreateReportTemplateResponseMutationData,
      CreateReportTemplateResponseMutationVariables
    >(CREATE_REPORT_TEMPLATE_RESPONSE_MUTATION);

  const handleCreateResponseOnClick = async () => {
    if (!reportTemplateResponseSet) return;

    const { data } = await createReportTemplateResponse({
      variables: {
        reportTemplateResponseSetId: reportTemplateResponseSet.id,
      },
    });

    if (data) {
      dispatch(createReportTemplateResponseMutationOnCompleted(data));
      setFocusedResponse(
        data.createReportTemplateResponse.reportTemplateResponse
      );
      if (responseInputRef.current) {
        responseInputRef.current.focus();
        responseInputRef.current.select();
      }
    }
  };

  const [updateReportTemplateResponseSet] = useMutation<
    UpdateReportTemplateResponseSetMutationData,
    UpdateReportTemplateResponseSetMutationVariables
  >(UPDATE_REPORT_TEMPLATE_RESPONSE_SET_MUTATION);

  const handleOnDragEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!reportTemplateResponseSet) return;
    if (!destination) return;

    const result = [...reportTemplateResponseSet.responses];
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    dispatch(reportTemplateResponseOrderOnChange(result));
    updateReportTemplateResponseSet({
      variables: {
        reportTemplateResponseSetId: reportTemplateResponseSet.id,
        data: {
          order: result.map((response) => response.id),
        },
      },
    });
  };

  const windowRef = useRef<HTMLDivElement>(null);
  const sidePanelRef = useRef<HTMLDivElement>(null);

  useClickedOutside([windowRef], () => {
    if (sidePanelIsOpen) return;
    setIsOpen(false);
  });

  useClickedOutside([sidePanelRef], () => {
    setSidePanelIsOpen(false);
  });

  return (
    <>
      <SimpleButtonWithWindow
        className="text-capitalize"
        openMode="controlled"
        windowChildren={renderWindowChildren}
        position="right"
        isOpen={isOpen}
        windowProps={{ innerRef: windowRef }}
        onClick={handleOnClick}
      >
        {getValueTypeLabel(item.valueType)}
      </SimpleButtonWithWindow>
      {isOpen && (
        <SidePanel
          innerRef={sidePanelRef}
          wrapperClassName="p-0"
          isOpen={sidePanelIsOpen}
          toggle={handleToggleSidePanel}
        >
          {reportTemplateResponseSet && (
            <div>
              <div className="m-3">
                <h5>Edit response set</h5>
              </div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable
                  droppableId={reportTemplateResponseSet.id}
                  direction="vertical"
                  renderClone={(draggableProvided, _, rubric) => {
                    const reportTemplateResponse =
                      reportTemplateResponseSet?.responses[rubric.source.index];
                    if (!reportTemplateResponse) return <></>;
                    return (
                      <div
                        key={reportTemplateResponse.id}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={{
                          ...draggableProvided.draggableProps.style,
                          color: theme.color.cardText.hex(),
                          zIndex: 9999,
                        }}
                      >
                        <SimpleButton
                          style={{
                            width: 34,
                            borderRadius: '50%',
                            cursor: 'grab',
                          }}
                          className="text-center"
                          icon={faGripVertical}
                          iconProps={{
                            fixedWidth: false,
                            style: { marginLeft: 2 },
                          }}
                          {...draggableProvided.dragHandleProps}
                        />
                        {reportTemplateResponse.value}
                      </div>
                    );
                  }}
                >
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {reportTemplateResponseSet.responses.map(
                        (reportTemplateResponse, index) => (
                          <Draggable
                            draggableId={reportTemplateResponse.id}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <Response
                                key={reportTemplateResponse.id}
                                ref={draggableProvided.innerRef}
                                className="pl-1 d-flex"
                                {...draggableProvided.draggableProps}
                              >
                                <SimpleButton
                                  style={{
                                    width: 34,
                                    borderRadius: '50%',
                                    cursor: 'grab',
                                  }}
                                  className="text-center"
                                  icon={faGripVertical}
                                  iconProps={{
                                    fixedWidth: false,
                                    style: { marginLeft: 2 },
                                  }}
                                  {...draggableProvided.dragHandleProps}
                                />
                                <ResponseContent
                                  inputRef={
                                    focusedResponse?.id ===
                                    reportTemplateResponse.id
                                      ? responseInputRef
                                      : undefined
                                  }
                                  response={reportTemplateResponse}
                                  focusedResponse={focusedResponse}
                                  setFocusedResponse={setFocusedResponse}
                                />
                              </Response>
                            )}
                          </Draggable>
                        )
                      )}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="m-3">
                <SimpleButton
                  icon={faPlus}
                  loading={creatingResponse}
                  onClick={handleCreateResponseOnClick}
                >
                  Create new response
                </SimpleButton>
              </div>
            </div>
          )}
        </SidePanel>
      )}
    </>
  );
};

export default ReportTemplateBlockValueTypeEditor;
