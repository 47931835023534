import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useMatch } from 'react-router';
import { Row, Col } from 'reactstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { SizeMe } from 'react-sizeme';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons/faThumbsUp';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { useMutation, useQuery } from '@apollo/client';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons/faTimesSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { JobReport, JobReportVersion, JobReportVersionPage } from 'lib/types';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import { getId } from 'lib/utils';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';
import CardWrapper from '../CardWrapper';
import Loading from '../Loading';
import {
  SendReportToClientMutationData,
  SendReportToClientMutationVariables,
  UpdateReportVersionPageMutationData,
  UpdateReportVersionPageMutationVariables,
} from './types';
import SimpleButton from '../SimpleButton';
import { Small } from '../Typography';
import {
  sendReportToClientMutationOnCompleted,
  useReducerContext,
} from '../ClientJob/reducer';
import {
  REPORT_QUERY,
  SEND_REPORT_TO_CLIENT_MUTATION,
  UPDATE_REPORT_VERSION_PAGE_MUTATION,
} from './query';
import Table from '../Table';
import SimpleConfirmButton from '../SimpleConfirmButton';
import SlateField from '../SlateField';

const getApprovalIcon = (status: JobReportVersionPage['status']) => {
  switch (status) {
    case 'UNCHECKED':
      return <FontAwesomeIcon icon={faSquare} />;
    case 'APPROVED':
      return <FontAwesomeIcon icon={faCheckSquare} color="green" />;
    case 'REJECTED':
      return <FontAwesomeIcon icon={faTimesSquare} color="red" />;
    default:
      return null;
  }
};

const getStatusText = (status: JobReportVersionPage['status']) => {
  switch (status) {
    case 'UNCHECKED':
      return 'Not checked';
    case 'APPROVED':
      return 'Approved';
    case 'REJECTED':
      return 'Rejected';
    default:
      return '';
  }
};

const ClientJobReport = () => {
  const match = useMatch(
    '/clients/:slug/jobs/:reference/reports/:reportNumber'
  );

  const [{ job }, dispatch] = useReducerContext();
  const [report, setReport] = useState<JobReport>();
  const [version, setVersion] = useState<JobReportVersion | null>(null);
  const [page, setPage] = useState<JobReportVersionPage>();

  useQuery(REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      jobId: job?.id,
      jobReportNumber: parseInt(match?.params.reportNumber as string, 10),
    },
    onCompleted: (data) => {
      setReport(data.jobReport);
      if (data.jobReport.versions.length > 0) {
        const [latestVersion] = data.jobReport.versions;
        setVersion(latestVersion);
        setPage(latestVersion.pages[0]);
      } else {
        setVersion(null);
      }
    },
  });

  const handleTableRowOnClick = (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    if (!report) return;
    const id = getId(event);
    setVersion(
      report.versions.find((innerVersion) => innerVersion.id === id) || null
    );
  };

  const clampPageNumber = (pageNumber: number) => {
    if (!version) return pageNumber;
    return Math.max(1, Math.min(pageNumber, version.pages.length));
  };

  const getAdjacentPage = (delta: number) => {
    if (!version) return;
    setPage((prevPage) => {
      if (!prevPage) return prevPage;
      return version.pages.find(
        (innerPage) =>
          innerPage.pageNumber === clampPageNumber(prevPage.pageNumber + delta)
      );
    });
  };

  const handlePreviousPageOnClick = () => {
    getAdjacentPage(-1);
  };

  const handleNextPageOnClick = () => {
    getAdjacentPage(1);
  };

  const [updateReportVersionPage] = useMutation<
    UpdateReportVersionPageMutationData,
    UpdateReportVersionPageMutationVariables
  >(UPDATE_REPORT_VERSION_PAGE_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { jobReportVersionPage: updatedPage } =
        data.updateJobReportVersionPage;
      setVersion((prevVersion) => {
        if (!prevVersion) return prevVersion;
        return {
          ...prevVersion,
          pages: prevVersion.pages.map((innerPage) =>
            innerPage.id === updatedPage.id ? updatedPage : innerPage
          ),
        };
      });
    },
  });

  const handleOnRejectPage = () => {
    if (!version || !page) return;
    updateReportVersionPage({
      variables: {
        sessionId: '',
        jobReportVersionPageId: page.id,
        status: 'REJECTED',
      },
    }).then(() => {
      getAdjacentPage(1);
    });
  };

  const handleOnApprovePage = () => {
    if (!version || !page) return;
    updateReportVersionPage({
      variables: {
        sessionId: '',
        jobReportVersionPageId: page.id,
        status: 'APPROVED',
      },
    }).then(() => {
      getAdjacentPage(1);
    });
  };

  const [sendReportToClient, { loading: sendingToClient }] = useMutation<
    SendReportToClientMutationData,
    SendReportToClientMutationVariables
  >(SEND_REPORT_TO_CLIENT_MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const handleSendReportToClient = async () => {
    if (!report || !version) return;
    const { data } = await sendReportToClient({ variables: { id: report.id } });
    if (!data) return;
    dispatch(sendReportToClientMutationOnCompleted(data));
    setReport((prevReport) => ({
      ...prevReport,
      ...data.sendJobReportToClient.jobReport,
    }));
    if (data.sendJobReportToClient.jobReport.latestVersion) {
      setVersion(data.sendJobReportToClient.jobReport.latestVersion);
    }
  };

  const theme = useTheme();

  if (!job || !report) return null;
  const approvedPages = (version?.pages || []).filter(
    (innerPage) => innerPage.status === 'APPROVED'
  );
  const rejectedPages = (version?.pages || []).filter(
    (innerPage) => innerPage.status === 'REJECTED'
  );
  const reportIsValid =
    !!page &&
    !!version &&
    approvedPages.length === (version?.pages || []).length;
  return (
    <Row className="h-100">
      <Col xl={3}>
        <div className="position-sticky" style={{ top: 0, maxHeight: '100%' }}>
          <CardWrapper className="h-100 p-0">
            <div className="m-3">
              <h5 className="mb-0">
                <span className="mono text-75">#{report.number}</span>{' '}
                {report.template.name}
              </h5>
            </div>
            <div className="mx-3">
              <Small>Versions</Small>
            </div>
            <Table flexBases={[50, 50]}>
              <thead>
                <tr>
                  <th>Number</th>
                  <th className="text-right pr-3">Progress</th>
                </tr>
              </thead>
              <tbody>
                {report.versions.length > 0 ? (
                  report.versions.map((innerVersion) => (
                    <tr
                      key={innerVersion.id}
                      className={classNames({
                        active: innerVersion.id === version?.id,
                      })}
                      data-id={innerVersion.id}
                      onClick={handleTableRowOnClick}
                    >
                      <td className="pr-2" style={{ lineHeight: '34px' }}>
                        {innerVersion.versionNumber}
                      </td>
                      <td className="text-right pr-3 mono">
                        {innerVersion.pages.reduce(
                          (count, innerPage) =>
                            innerPage.status === 'APPROVED' ? count + 1 : count,
                          0
                        )}{' '}
                        / {innerVersion.pageCount}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="empty-message text-75 m-3">
                    <td>No versions to show</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardWrapper>
        </div>
      </Col>
      <Col xl={6}>
        <CardWrapper
          className="p-0 mb-5"
          style={{
            borderRadius: '0.25rem 0.25rem 0 0',
          }}
        >
          <div
            className="d-flex justify-content-between p-3"
            style={{ borderBottom: theme.border }}
          >
            {version ? (
              <>
                <div className="d-flex">
                  <h5 className="mb-0 mr-3" style={{ lineHeight: '34px' }}>
                    <span
                      className="d-inline-block text-nowrap"
                      style={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {version
                        ? `Version ${version?.versionNumber}`
                        : 'No version available'}
                    </span>
                  </h5>
                  <SimpleButton
                    icon={faArrowLeft}
                    onClick={handlePreviousPageOnClick}
                  />
                  <SimpleButton
                    icon={faArrowRight}
                    iconSide="right"
                    onClick={handleNextPageOnClick}
                  />
                  <span className="ml-3" style={{ lineHeight: '34px' }}>
                    Page{' '}
                    <span style={{ fontFamily: 'JetBrains Mono, monospace' }}>
                      {page?.pageNumber}
                    </span>{' '}
                    of{' '}
                    <span style={{ fontFamily: 'JetBrains Mono, monospace' }}>
                      {version?.pageCount}
                    </span>
                  </span>
                </div>
                {version?.isLatest && !report.sentToClient && (
                  <div>
                    {page?.status === 'APPROVED' ? (
                      <SimpleButton
                        className="mr-2"
                        icon={faTimes}
                        style={{ color: 'red' }}
                        disabled={!page}
                        onClick={handleOnRejectPage}
                      >
                        Reject page
                      </SimpleButton>
                    ) : (
                      <SimpleButton
                        icon={faCheck}
                        style={{ color: 'green' }}
                        disabled={!page}
                        onClick={handleOnApprovePage}
                      >
                        Approve page
                      </SimpleButton>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div>No version available</div>
            )}
          </div>
          <Document
            className="h-100 p-0"
            file={version?.pdfUrl}
            loading={
              <div className="m-3 text-75">
                <Loading className="mr-1" />
                Loading pdf...
              </div>
            }
            error={<div className="m-3 text-75">Error loading pdf.</div>}
            noData={
              <div className="m-3 text-75">
                No PDF has has been generated for this report as it has not yet
                been completed.
              </div>
            }
          >
            <SizeMe>
              {({ size }) => (
                <div
                  style={{
                    borderRadius: '0 0 0.25rem 0.25rem',
                    overflow: 'hidden',
                  }}
                >
                  <Page width={size.width || 0} pageNumber={page?.pageNumber} />
                </div>
              )}
            </SizeMe>
          </Document>
        </CardWrapper>
      </Col>
      <Col xl={3}>
        <div className="position-sticky" style={{ top: 0, maxHeight: '100%' }}>
          {version && (
            <CardWrapper className="mb-4">
              <div>
                <div className="mb-3">
                  <a
                    href={version.pdfUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    download="report"
                  >
                    <SimpleButton active icon={faDownload}>
                      Download version
                    </SimpleButton>
                  </a>
                </div>
                <Small className="ml-2">Pages</Small>
                <div className={classNames({ 'mb-3': version.isLatest })}>
                  {version?.pages?.map((innerPage) => (
                    <SimpleButton
                      key={innerPage.id}
                      active={innerPage.id === page?.id}
                      className="w-100 text-left d-flex justify-content-between mb-1"
                      onClick={() => setPage(innerPage)}
                    >
                      <span>
                        Page{' '}
                        <span
                          style={{ fontFamily: 'JetBrains Mono, monospace' }}
                        >
                          {innerPage.pageNumber}
                        </span>
                      </span>
                      {version.isLatest && (
                        <span>
                          <span className="mr-2">
                            {getStatusText(innerPage.status)}
                          </span>
                          {getApprovalIcon(innerPage.status)}
                        </span>
                      )}
                    </SimpleButton>
                  ))}
                </div>
                {version.isLatest && (
                  <>
                    {page ? (
                      <div className="ml-2 text-75">
                        {reportIsValid ? (
                          <div>
                            All pages have been approved, the report can now be
                            sent to the client.
                          </div>
                        ) : (
                          <div>
                            Cannot send to client as {rejectedPages.length} page
                            {rejectedPages.length === 1
                              ? ' has'
                              : 's have'}{' '}
                            issues.
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="ml-2 text-75">
                        No page has been loaded.
                      </div>
                    )}
                  </>
                )}
              </div>
            </CardWrapper>
          )}
          {version?.isLatest && (
            <CardWrapper>
              {report.sentToClient ? (
                <div>
                  <p className="mb-0">This report was sent to the client.</p>
                </div>
              ) : (
                <>
                  <Small className={classNames({ 'ml-2': !reportIsValid })}>
                    {reportIsValid ? 'Send to client' : 'Controls'}
                  </Small>
                  <Form
                    initialValues={{ message: report.defaultMessage }}
                    onSubmit={() => {}}
                  >
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        {reportIsValid && (
                          <div className="my-2">
                            <Field name="message" component={SlateField} />
                          </div>
                        )}
                        <SimpleConfirmButton
                          confirmMessage="Are you sure you want to send this report to the client?"
                          className="w-100 text-left"
                          icon={faThumbsUp}
                          loading={sendingToClient}
                          disabled={!reportIsValid}
                          onClick={handleSendReportToClient}
                        >
                          Send to client
                        </SimpleConfirmButton>
                      </form>
                    )}
                  </Form>
                  {!reportIsValid && (
                    <a
                      href={version?.editUrl || ''}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <SimpleButton
                        className="w-100 text-left"
                        icon={faEdit}
                        disabled={
                          job.reports.length === 0 ||
                          reportIsValid ||
                          !version?.editUrl
                        }
                      >
                        Edit in iAuditor
                      </SimpleButton>
                    </a>
                  )}
                </>
              )}
            </CardWrapper>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ClientJobReport;
