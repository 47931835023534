import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIsPathActive } from 'lib/hooks';
import { TabProps, TabWrapperProps } from './types';

export const TabBarWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  color: ${({ theme }) => theme.color.topbarText.hex()};

  h4 {
    margin-bottom: 24px;
  }

  .text-75 {
    color: ${({ theme }) => theme.color.topbarText.alpha(0.75).hex()};
  }

  .client__tab-bar-breadcrumb-link {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.color.topbarText.hex()};
    }
  }
`;

export const TabBarInner = styled.div`
  position: absolute;
  bottom: 0;
`;

export const TabWrapper = styled.a<TabWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  border-bottom: ${({ theme, active }) =>
    active
      ? `3px solid ${theme.color.cardText.alpha(0.25).hex()}`
      : '3px solid transparent'};
  padding-bottom: 0.5rem;
  margin-right: 2rem;
  color: ${({ theme }) => theme.color.topbarText.hex()};
  transition: all 150ms;

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;

    color: ${({ theme, active }) =>
      active
        ? theme.color.topbarText.hex()
        : theme.color.topbarText.alpha(0.75).hex()};
  }

  &:hover {
    text-decoration: none;
    border-bottom: 3px solid
      ${({ theme }) => theme.color.cardText.alpha(0.15).hex()};
    transition: all 50ms;
  }
`;

export const Tab = ({ name, tab, path }: TabProps) => {
  const active = useIsPathActive(path);
  return (
    <Link to={tab}>
      <TabWrapper active={active}>
        <span>{name}</span>
      </TabWrapper>
    </Link>
  );
};
