import { faRedo } from '@fortawesome/pro-duotone-svg-icons/faRedo';
import { faUndo } from '@fortawesome/pro-duotone-svg-icons/faUndo';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { DateTime } from 'luxon';
import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faBrowser } from '@fortawesome/pro-duotone-svg-icons/faBrowser';
import { faQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion';
import { Tree, Root } from 'lib/tree';
import { ReportTemplateBlock as ReportTemplateBlockType } from 'lib/types';
import ReactJson from 'react-json-view';
import { Col, Row } from 'reactstrap';
import { AnyAction } from 'redux';
import {
  generateId,
  generateSlateDefault,
  parseDateTime,
  removeTypename,
} from 'lib/utils';
import { useDebounceCallback } from 'lib/hooks';
import PortalTopbarExtension from '../PortalTopbarExtension';
import ReportTemplateBlock from '../ReportTemplateBlock';
import SimpleButton from '../SimpleButton';
import SimpleButtonSquare from '../SimpleButtonSquare';
import {
  REPORT_TEMPLATE_QUERY,
  UNDO_REPORT_TEMPLATE_UPDATE,
  UPDATE_REPORT_TEMPLATE_MUTATION,
} from './query';
import reducer, {
  initialQueryOnCompleted,
  initialState,
  reportTemplateBlockOnCollapse,
  reportTemplateRootOnChange,
  ReducerContext,
  reportTemplateStateOnChange,
  addBlockToRoot,
  undoReportTemplateUpdateMutationOnCompleted,
} from './reducer';
import {
  InitialQueryData,
  InitialQueryVariables,
  NewReportTemplateBlock,
  ReportTemplateEditorProps,
  State,
  UpdateReportTemplateMutationData,
  UpdateReportTemplateMutationVariables,
} from './types';
import CardWrapper from '../CardWrapper';
import {
  ReportTemplateEditorHeader,
  PlaceholderWrapper,
  ReportTemplateEditorFooter,
} from './styled';
import { Small } from '../Typography';

const ReportTemplateEditor = ({
  reportTemplateId,
  breadcrumb,
}: ReportTemplateEditorProps) => {
  const [state, dispatch] = useReducer<Reducer<State, AnyAction>>(
    reducer,
    initialState
  );
  const { reportTemplate, reportTemplateState } = state;

  useQuery<InitialQueryData, InitialQueryVariables>(REPORT_TEMPLATE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { reportTemplateId },
    onCompleted: (data) => {
      dispatch(initialQueryOnCompleted(data));
    },
  });

  const getActiveLogicId = (item: ReportTemplateBlockType) => {
    return (
      reportTemplateState.value[item.id] ||
      reportTemplateState.valueDefaults[item.id]
    );
  };

  const getShouldItemNest = (aboveItem: ReportTemplateBlockType) => {
    if (aboveItem.valueType === 'CONTAINER') {
      return true;
    }

    if (aboveItem.logic.length > 0) {
      const activeLogic = aboveItem.logic.find(
        (logic) => logic.id === getActiveLogicId(aboveItem)
      );
      return (
        !!activeLogic && activeLogic.logicTriggers.includes('ASK_QUESTIONS')
      );
    }

    return false;
  };

  const [updateReportTemplate] = useMutation<
    UpdateReportTemplateMutationData,
    UpdateReportTemplateMutationVariables
  >(UPDATE_REPORT_TEMPLATE_MUTATION);

  const handleOnChange = (newRoot: Root<ReportTemplateBlockType>) => {
    if (!reportTemplate) return;

    dispatch(
      reportTemplateRootOnChange({
        ...newRoot,
        blocks: newRoot.items,
      })
    );
  };

  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (!reportTemplate) return;
    if (!canUpdate) {
      setCanUpdate(true);
      return;
    }

    updateReportTemplate({
      variables: {
        reportTemplateId: reportTemplate.id,
        reportTemplateRoot: {
          id: reportTemplate.root.id,
          children: reportTemplate.root.children,
          blocks: removeTypename(reportTemplate.root.blocks),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTemplate]);

  const handleOnCollapse = (collapsed: ReportTemplateBlockType['id'][]) => {
    dispatch(reportTemplateBlockOnCollapse(collapsed));
  };

  const handleOnLogicChange = (
    itemId: ReportTemplateBlockType['id'],
    newLogicId: ReportTemplateBlockType['id']
  ) => {
    dispatch(reportTemplateStateOnChange(itemId, newLogicId));
  };

  const renderPlaceHolder = () => {
    return <PlaceholderWrapper />;
  };

  const handleAddContainerOnClick = () => {
    if (!reportTemplate) return;
    const newBlock: NewReportTemplateBlock = {
      id: generateId(),
      label: generateSlateDefault('New section'),
      children: [],
      logicConditionOrder: [],
      logic: [],
      parent: reportTemplate.root,
      valueType: 'CONTAINER',
    };
    dispatch(addBlockToRoot(newBlock));
  };

  const handleAddQuestionOnClick = () => {
    if (!reportTemplate) return;
    const newBlock: NewReportTemplateBlock = {
      id: generateId(),
      label: generateSlateDefault('New question'),
      children: [],
      logicConditionOrder: [],
      logic: [],
      parent: reportTemplate.root,
      valueType: 'CHAR',
    };
    dispatch(addBlockToRoot(newBlock));
  };

  const [undo] = useMutation(UNDO_REPORT_TEMPLATE_UPDATE);

  const handleUndoOnClick = async () => {
    if (!reportTemplate) return;
    setCanUpdate(false);
    const { data } = await undo({
      variables: { reportTemplateId: reportTemplate.id },
    });
    if (data) {
      dispatch(undoReportTemplateUpdateMutationOnCompleted(data));
      setCanUpdate(true);
    }
  };

  if (!reportTemplate) return null;

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      {breadcrumb?.({ reportTemplate })}
      <Row className="position-relative" style={{ marginBottom: 600 }}>
        <Col xs={6} className="offset-3">
          <ReportTemplateEditorHeader className="d-flex justify-content-between">
            <div>Question</div>
            <div>Response type</div>
          </ReportTemplateEditorHeader>
          <div className="position-relative">
            {reportTemplate.root.blocks.length === 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 'calc(100% + 16px)',
                  zIndex: 10,
                }}
              >
                <CardWrapper className="p-1 shadow text-center">
                  <Small>
                    <FontAwesomeIcon className="mr-1" icon={faPlus} />
                    Add
                  </Small>
                  <SimpleButtonSquare
                    style={{ width: '100%' }}
                    icon={faBrowser}
                    onClick={handleAddContainerOnClick}
                  >
                    Section
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: '100%' }}
                    icon={faQuestion}
                    onClick={handleAddQuestionOnClick}
                  >
                    Question
                  </SimpleButtonSquare>
                </CardWrapper>
              </div>
            )}
            <Tree
              root={{
                ...reportTemplate.root,
                children: reportTemplate.root.children,
                items: reportTemplate.root.blocks,
              }}
              collapsed={reportTemplateState.collapsed}
              logic={{
                ...reportTemplateState.valueDefaults,
                ...reportTemplateState.value,
              }}
              renderItem={ReportTemplateBlock}
              renderPlaceholder={renderPlaceHolder}
              getShouldItemNest={getShouldItemNest}
              onChange={handleOnChange}
              onCollapse={handleOnCollapse}
              onLogicChange={handleOnLogicChange}
            />
          </div>
          <ReportTemplateEditorFooter>
            <div>Add page</div>
          </ReportTemplateEditorFooter>
        </Col>
        <Col>
          <CardWrapper>
            <div>
              <SimpleButton icon={faUndo} onClick={handleUndoOnClick}>
                Undo
              </SimpleButton>
              <SimpleButton icon={faRedo}>Redo</SimpleButton>
            </div>
            <div>
              {reportTemplate.logBlocks.map((logBlock) => (
                <div key={logBlock.id} className="mb-5">
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={logBlock.diffGroup.undone ? faCheckSquare : faSquare}
                  />
                  {DateTime.fromISO(logBlock.dateTime).toLocaleString(
                    DateTime.TIME_WITH_SECONDS
                  )}
                  <div>
                    <Small>Bases</Small>
                    {logBlock.diffGroup.bases.map((base) => (
                      <div key={base.id}>
                        <ReactJson theme="twilight" src={base.data} />
                      </div>
                    ))}
                  </div>
                  <div>
                    <Small>Diffs</Small>
                    {logBlock.diffGroup.diffs.map((diff) => (
                      <div key={diff.id}>
                        <ReactJson theme="twilight" src={diff.data} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </ReducerContext.Provider>
  );
};

export default ReportTemplateEditor;
