import React from 'react';
import { msalApplication } from 'lib/msal';
import { useNavigate } from 'react-router-dom';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';

const Profile = () => {
  const navigate = useNavigate();
  const handleOnLogout = () => {
    msalApplication.logout();
    navigate('/login');
  };
  return (
    <div>
      <CardWrapper>
        <SimpleButton onClick={handleOnLogout}>Logout</SimpleButton>
      </CardWrapper>
    </div>
  );
};

export default Profile;
