import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { parseDateTime, parseTimeAgo } from 'lib/utils';
import { useClientSlug } from 'lib/hooks';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useTheme } from 'styled-components';
import CardWrapper from '../CardWrapper';
import {
  jobIssuesQueryOnCompleted,
  useReducerContext,
} from '../ClientJob/reducer';
import { JOB_ISSUES_QUERY } from './query';
import {
  ClientJobIssueCardProps,
  JobIssuesQueryData,
  JobIssuesQueryVariables,
} from './types';
import ClientJobIssueStatusPill from '../ClientJobIssueStatusPill';
import { ClientJobIssueCardHeader } from './styled';
import { Small } from '../Typography';
import ClientJobIssueTag from '../ClientJobIssueTag';
import SearchBar from '../SearchBar';
import Select from '../Select';
import SimpleButton from '../SimpleButton';
import LogBlockSummary from '../LogBlockSummary';
import ClientJobIssueCardBody from '../ClientJobIssueCardBody';

const ClientJobIssueCard = ({ last, ...issue }: ClientJobIssueCardProps) => {
  const [{ job }] = useReducerContext();
  const clientSlug = useClientSlug();
  const theme = useTheme();
  if (!job) return null;
  return (
    <CardWrapper className={classNames('p-0', { 'mb-4': !last })}>
      <ClientJobIssueCardHeader className="d-flex justify-content-between">
        <div className="d-flex">
          <div style={{ backgroundColor: theme.color.contentBackground.hex() }}>
            <span className="mono text-50 mx-2" style={{ lineHeight: '32px' }}>
              #{issue.number}
            </span>
          </div>
          <ClientJobIssueStatusPill
            className="rounded-0"
            status={issue?.status}
          />
          <Link
            to={`/clients/${clientSlug}/jobs/${job.reference}/issues/${issue.number}`}
          >
            <a
              href={`/clients/${clientSlug}/jobs/${job.reference}/issues/${issue.number}`}
            >
              <p
                className={classNames('ml-2', {
                  'font-weight-bold': issue.status === 'OPEN',
                })}
              >
                {issue.issueTypeDisplay}{' '}
              </p>
            </a>
          </Link>
        </div>
        <div>
          <p className="mr-2">
            <small>
              <span className="text-50">Opened: </span>
              <span className="text-75 mono">
                {parseDateTime(issue.dateTimeCreated)}
              </span>{' '}
              <span className="text-50">
                ({parseTimeAgo(issue.dateTimeCreated)})
              </span>
            </small>
          </p>
        </div>
      </ClientJobIssueCardHeader>
      {issue?.tags?.length > 0 && (
        <div className="d-flex px-3 pt-3">
          <Small className="mr-2" style={{ lineHeight: '24px' }}>
            Tags:
          </Small>
          {issue.tags.map((tag, index) => {
            const lastTag = index === issue?.tags?.length - 1;
            return (
              <ClientJobIssueTag
                key={tag.id}
                className={classNames({ 'mr-2': !lastTag })}
                {...tag}
              >
                <span className="text-75">{tag.name}</span>
              </ClientJobIssueTag>
            );
          })}
        </div>
      )}
      <div className="p-3">
        <ClientJobIssueCardBody {...issue} job={job} />
        <div className="d-flex">
          {issue.openedBy && (
            <div className="mr-3 mt-3">
              <Small>Opened by</Small>
              <LogBlockSummary logBlock={issue.openedBy} />
            </div>
          )}
          {issue.closedBy && (
            <div className="mt-3">
              <Small>Closed by</Small>
              <LogBlockSummary logBlock={issue.closedBy} />
            </div>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};

const ClientJobIssues = () => {
  const [{ job }, dispatch] = useReducerContext();

  useQuery<JobIssuesQueryData, JobIssuesQueryVariables>(JOB_ISSUES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { jobId: job?.id },
    onCompleted: (data) => {
      dispatch(jobIssuesQueryOnCompleted(data));
    },
  });

  if (!job) return null;
  return (
    <div>
      <Row>
        <Col xs={3}>
          <div
            className="position-sticky"
            style={{ top: 0, maxHeight: '100%' }}
          >
            <CardWrapper className="h-100 p-0">
              <div className="m-3">
                <h5 className="mb-0">Issues</h5>
                <small className="text-75">
                  When an operative completes a report it will appear here.
                </small>
              </div>
            </CardWrapper>
          </div>
        </Col>
        <Col xs={6}>
          <CardWrapper className="p-0 mb-3">
            <div className="d-flex">
              <div className="d-flex w-100">
                <div style={{ width: 150, marginRight: 1 }}>
                  <Select
                    placeholder="Status"
                    defaultValue={{ label: 'Open', value: 'OPEN' }}
                    isSearchable={false}
                    isClearable
                    options={[
                      { label: 'Open', value: 'OPEN' },
                      { label: 'Closed', value: 'CLOSED' },
                    ]}
                    menuPortalTarget={document.body}
                  />
                </div>
                <SearchBar
                  wrapperClassName="flex-grow-1"
                  wrapperStyle={{ marginRight: 1 }}
                  placeholder="Search issues..."
                />
              </div>
              <SimpleButton icon={faPlus} active style={{ height: 38 }}>
                New Issue
              </SimpleButton>
            </div>
          </CardWrapper>
          {job.issues?.map((issue, index) => {
            const last = index === job.issues.length - 1;
            return <ClientJobIssueCard {...issue} last={last} />;
          })}
        </Col>
        <Col xs={3} />
      </Row>
    </div>
  );
};

export default ClientJobIssues;
