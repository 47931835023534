import { faValueAbsolute } from '@fortawesome/pro-light-svg-icons/faValueAbsolute';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from 'react-flow-renderer';
import NodeUnary from '../NodeUnary';

const NodeAbsolute = (node: Node) => {
  return (
    <NodeUnary {...node}>
      <FontAwesomeIcon icon={faValueAbsolute} fixedWidth />
    </NodeUnary>
  );
};

export default NodeAbsolute;
