import { DateTime } from 'luxon';

const getTimeOfDay = () => {
  const { hour } = DateTime.local();

  if (hour > 6 && hour < 12) {
    return 'morning';
  }

  if (hour >= 12 && hour < 18) {
    return 'afternoon';
  }

  return 'evening';
};

export default getTimeOfDay;
