import React, { Reducer, useReducer } from 'react';
import { useMatch } from 'react-router';
import { useTheme } from 'styled-components';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { Routes, Route, Link } from 'react-router-dom';
import { faWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import { useClient, useIsPathActive, useSetTaskbarData } from 'lib/hooks';
import { AnyAction } from 'redux';
import { useQuery } from '@apollo/client';
import Portal from '../Portal';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';
import {
  ClientJobGroupState,
  JobGroupQueryData,
  JobGroupQueryVariables,
} from './types';
import reducer, {
  initialState,
  jobGroupQueryOnCompleted,
  ReducerContext,
} from './reducer';
import ClientJobGroupBreadcrumb from '../ClientJobGroupBreadcrumb';
import { JOB_GROUP_QUERY } from './query';
import ClientJobGroupHome from '../ClientJobGroupHome';

const ClientJobGroup = () => {
  const match = useMatch('/clients/:slug/job-groups/:reference/*');
  const reference = match?.params.reference as string;

  const theme = useTheme();
  const client = useClient();

  const [state, dispatch] = useReducer<Reducer<ClientJobGroupState, AnyAction>>(
    reducer,
    initialState
  );

  const { jobGroup } = state;

  useSetTaskbarData({
    type: 'JOB_GROUP',
    label: `Job group ${jobGroup?.reference}`,
    path: `/clients/${client.slug}/job-groups/${jobGroup?.reference}`,
  });

  const { loading } = useQuery<JobGroupQueryData, JobGroupQueryVariables>(
    JOB_GROUP_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { jobGroupReference: reference },
      onCompleted: (data) => {
        dispatch(jobGroupQueryOnCompleted(data));
      },
    }
  );

  const isPathActive = useIsPathActive();

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientJobGroupBreadcrumb loading={loading} />
      <Portal selector="layout-topbar-extension">
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <Link
                to={`/clients/${client.slug}/job-groups/${jobGroup?.reference}`}
              >
                <SimpleButton
                  icon={faHome}
                  inButtonToolbar
                  active={isPathActive('/clients/:slug/job-groups/:reference')}
                >
                  Home
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/job-groups/${jobGroup?.reference}/jobs`}
              >
                <SimpleButton
                  icon={faWrench}
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/job-group/:reference/jobs'
                  )}
                >
                  Jobs
                </SimpleButton>
              </Link>
            </div>
          </div>
        </CardWrapper>
      </Portal>
      <Routes>
        <Route
          path="/clients/:slug/job-groups/:reference"
          element={<ClientJobGroupHome />}
        />
        <Route
          path="/clients/:slug/job-groups/:reference"
          element={<ClientJobGroupHome />}
        />
      </Routes>
    </ReducerContext.Provider>
  );
};

export default ClientJobGroup;
