import { Color } from 'chroma-js';
import styled from 'styled-components';
import { TemplateBlockWrapperProps } from '../ReportTemplateEditor/types';

export const TemplateBlockWrapper = styled.div.attrs<
  TemplateBlockWrapperProps,
  { backgroundColor: Color }
>(({ theme, valueType }) => {
  return {
    backgroundColor:
      valueType === 'CONTAINER'
        ? theme.color.cardBackground.darken(0.125)
        : theme.color.cardBackground,
  };
})<TemplateBlockWrapperProps>`
  color: ${({ theme }) => theme.color.cardText.hex()};

  div.inner {
    width: 100%;
    border: ${({ theme }) => theme.border};
    border-top: none;

    background-color: ${({ backgroundColor }) => backgroundColor.hex()};
    cursor: text;
    transition: height 1000ms;
  }

  p {
    margin-bottom: 0;
  }

  div.decoration-depth {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) =>
      theme.color.cardBackground.darken(0.125).hex()};
    z-index: 1;
  }

  div.decoration-selected {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.color.primary.hex()};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    pointer-events: none;
    border-radius: 0.25rem;
  }

  div.decoration-collapse,
  div.decoration-handle {
    padding: 0.25rem;
    background-color: ${({ backgroundColor }) => backgroundColor.hex()};
    z-index: 99;
  }
`;
