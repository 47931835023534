import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { CheckboxProps } from './types';
import { CheckboxWrapper, InputStyled, CheckboxButton } from './styled';

const Checkbox = ({
  label,
  checked,
  small = false,
  wrapperProps = {},
  ...props
}: CheckboxProps) => (
  <CheckboxWrapper {...wrapperProps} small={small}>
    <InputStyled type="checkbox" {...props} />
    <CheckboxButton className="rounded" checked={checked} small={small}>
      <FontAwesomeIcon icon={faCheck} />
    </CheckboxButton>
    {label && <span className="pl-2 checkbox-label">{label}</span>}
  </CheckboxWrapper>
);

export default Checkbox;
