import styled from 'styled-components';
import { CardWrapperProps } from './types';

const CardWrapper = styled.div<CardWrapperProps>`
  padding: ${({ padding = true }) => (padding ? '1rem' : 0)};
  border-radius: 0.25rem;
  background-color: ${({ theme, invert = false }) =>
    invert
      ? theme.color.contentBackground.hex()
      : theme.color.cardBackground.hex()};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.cardBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};

  .text-100 {
    color: ${({ theme }) => theme.color.cardText.hex()};
  }

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }

  .text-50 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }
`;

export default CardWrapper;
