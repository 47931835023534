import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getId } from '../utils';

const useTableRowOnClick = (
  urlMethod: (id: string) => string,
  callback?: (event: React.MouseEvent<HTMLTableRowElement>) => void
) => {
  const navigate = useNavigate();
  return (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event) as string;
    navigate(urlMethod(id));
    if (callback) {
      callback(event);
    }
  };
};

export default useTableRowOnClick;
