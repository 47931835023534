import React from 'react';
import classNames from 'classnames';
import { useHovered } from 'lib/hooks';
import Button from '../Button';
import SimpleButton from '../SimpleButton';
import { ButtonMultiProps } from './types';
import { Wrapper } from './styled';

const getBorderRadius = (first: boolean, last: boolean) => {
  if (first) {
    return '0.25rem 0.25rem 0.1rem 0.1rem';
  }
  if (last) {
    return '0.1rem 0.1rem 0.25rem 0.25rem';
  }
  return '0.25rem';
};

export const ButtonMultiInner = ({
  options,
}: Pick<ButtonMultiProps, 'options'>) => {
  return (
    <>
      {options.map((option, index) => {
        const first = index === 0;
        const last = index === options.length - 1;
        return (
          <SimpleButton
            key={option.label}
            className={classNames('d-block text-left w-100 text-nowrap', {
              'mb-1': !last,
            })}
            style={{
              minWidth: 100,
              borderRadius: getBorderRadius(first, last),
            }}
            icon={option.icon}
            onClick={option.onClick}
          >
            {option.label}
          </SimpleButton>
        );
      })}
    </>
  );
};

const ButtonMulti = ({ label, icon, options }: ButtonMultiProps) => {
  const [hovered, hoveredCallbacks] = useHovered();
  return (
    <Wrapper {...hoveredCallbacks}>
      <Button className="w-100 button-main" icon={icon}>
        {label}
      </Button>
      <div className="options-list-outer">
        <div className="options-list-inner shadow">
          {hovered && <ButtonMultiInner options={options} />}
        </div>
      </div>
    </Wrapper>
  );
};

export default ButtonMulti;
