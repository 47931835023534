import React from 'react';
import { useMatch } from 'react-router';
import { useClient } from 'lib/hooks';
import Breadcrumb from '../Breadcrumb';
import PortalBreadcrumb from '../PortalBreadcrumb';
import ReportTemplateEditor from '../ReportTemplateEditor';

const ClientSettingsReportTemplate = () => {
  const client = useClient();
  const match = useMatch('/clients/:slug/settings/report-templates/:id');
  const reportTemplateId = match?.params.id as string;

  return (
    <div>
      <ReportTemplateEditor
        reportTemplateId={reportTemplateId}
        breadcrumb={({ reportTemplate }) => (
          <PortalBreadcrumb>
            <Breadcrumb.Wrapper>
              <Breadcrumb.Link to={`/clients/${client.slug}/settings`}>
                Settings
              </Breadcrumb.Link>
              <Breadcrumb.Separator />
              <Breadcrumb.Link
                to={`/clients/${client.slug}/settings/report-templates`}
              >
                Report templates
              </Breadcrumb.Link>
              <Breadcrumb.Separator />
              <Breadcrumb.Terminator>
                {reportTemplate.name ?? 'Untitled template'}
              </Breadcrumb.Terminator>
            </Breadcrumb.Wrapper>
          </PortalBreadcrumb>
        )}
      />
    </div>
  );
};

export default ClientSettingsReportTemplate;
