import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Draggable from 'react-draggable';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useLazyQuery } from '@apollo/client';
import { UserNote } from 'lib/types';
import { State } from 'lib/redux/types';
import UserNotes from '../UserNotes';
import { Wrapper, WindowHeader, WindowButton } from './styled';
import CardWrapper from '../CardWrapper';
import { INITIAL_QUERY } from './query';

const UserNotesWindow = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state: State) => state.layout.notesMeta);
  const [dragged, setDragged] = useState(false);

  const [userNotes, setUserNotes] = useState<UserNote[]>([]);
  const [mode, setMode] = useState<'list' | 'create' | 'edit'>('list');

  const [getUserNotes, { called }] = useLazyQuery(INITIAL_QUERY, {
    onCompleted: (data) => {
      setUserNotes(data.userNotes);
    },
  });

  useEffect(() => {
    if (isOpen && !called) getUserNotes();
  }, [isOpen, called, getUserNotes]);

  useEffect(() => {
    if (isOpen) setDragged(false);
  }, [isOpen]);

  const handleDragOnStart = () => {
    setDragged(true);
  };

  const handleCloseOnClick = () => {
    dispatch({
      type: 'LAYOUT__NOTES_META',
      payload: { isOpen: false },
    });
    setMode('list');
  };

  if (!isOpen) return null;
  return (
    <Draggable handle="#drag-handle" onStart={handleDragOnStart}>
      <Wrapper>
        <CardWrapper
          className="shadow h-100 p-0"
          style={{
            borderRadius: dragged ? '0.25rem' : '0 0.25rem 0 0',
            borderLeft: theme.border,
          }}
        >
          <WindowHeader
            id="drag-handle"
            className="d-flex justify-content-between pl-3"
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              width: '100%',
              zIndex: 100,
              borderRadius: dragged ? '0.25rem 0.25rem 0 0' : '0 0.25rem 0 0',
            }}
          >
            <div>
              <h5 className="mb-0 mr-2" style={{ lineHeight: '38px' }}>
                Notes
              </h5>
            </div>
            <div>
              <WindowButton icon={faTimes} onClick={handleCloseOnClick}>
                Close
              </WindowButton>
            </div>
          </WindowHeader>
          <div
            className="position-relative"
            style={{ marginTop: 38, height: 462 }}
          >
            <UserNotes
              cellStyle={{ height: 446 }}
              mode={mode}
              userNotes={userNotes}
              setMode={setMode}
              setUserNotes={setUserNotes}
            />
          </div>
        </CardWrapper>
      </Wrapper>
    </Draggable>
  );
};

export default UserNotesWindow;
