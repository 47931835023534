import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query {
    userNotes {
      id
      dateTime
      title
      body
      path
    }
  }
`;
