import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { ManagementTask } from 'lib/types';
import {
  ManagementTasksQueryData,
  ManagementTaskQueryData,
  State,
} from './types';

export const initialState: State = {
  managementTaskTypes: {
    results: [] as ManagementTask['type'][],
    counts: {},
  },
  managementTaskType: null,
  managementTasks: {
    results: [] as ManagementTask[],
    count: 0,
  },
  managementTask: null,
};

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [initialState, () => {}]
);

export const useReducerContext = () => useContext(ReducerContext);

export const managementTasksQueryOnCompleted = (
  data: ManagementTasksQueryData
) => ({
  type: 'MANAGEMENT_TASKS_QUERY_ON_COMPLETED',
  managementTaskTypes: data.managementTaskTypes,
  managementTasks: data.managementTasks,
});

export const managementTasksRefetchOnCompleted = (
  managementTaskType: ManagementTask['type'] | null,
  data: ManagementTasksQueryData
) => ({
  type: 'MANAGEMENT_TASKS_REFETCH_ON_COMPLETED',
  managementTaskTypes: data.managementTaskTypes,
  managementTaskType,
  managementTasks: data.managementTasks,
});

export const managementTaskQueryOnCompleted = (
  data: ManagementTaskQueryData
) => ({
  type: 'MANAGEMENT_TASK_QUERY_ON_COMPLETED',
  managementTask: data.managementTask,
});

export const goBack = () => ({
  type: 'GO_BACK',
});

export const managementTaskOnCompleted = (managementTask: ManagementTask) => ({
  type: 'MANAGEMENT_TASK_ON_COMPLETED',
  managementTask,
});

const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'MANAGEMENT_TASKS_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTasksQueryOnCompleted
      >;
      return {
        ...state,
        managementTaskTypes: thisAction.managementTaskTypes,
        managementTasks: thisAction.managementTasks,
      };
    }

    case 'MANAGEMENT_TASKS_REFETCH_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTasksRefetchOnCompleted
      >;
      return {
        ...state,
        managementTaskType: thisAction.managementTaskType,
        managementTasks: thisAction.managementTasks,
        managementTask: null,
      };
    }

    case 'MANAGEMENT_TASK_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTaskQueryOnCompleted
      >;
      return {
        ...state,
        managementTaskType: thisAction.managementTask.type,
        managementTask: thisAction.managementTask,
      };
    }

    case 'GO_BACK':
      return {
        ...state,
        managementTask: null,
      };

    case 'MANAGEMENT_TASK_ON_COMPLETED': {
      const thisAction = action as ReturnType<typeof managementTaskOnCompleted>;

      const newCount =
        state.managementTaskTypes.counts[thisAction.managementTask.type] - 1;

      return {
        ...state,
        managementTaskTypes: {
          results: state.managementTaskTypes.results.filter(
            (managementTaskType) =>
              newCount === 0
                ? managementTaskType !== thisAction.managementTask.type
                : true
          ),
          counts: {
            ...state.managementTaskTypes.counts,
            [thisAction.managementTask.type]: newCount,
          },
        },
        managementTasks: {
          ...state.managementTasks,
          results: state.managementTasks.results.filter(
            (managementTask) =>
              managementTask.id !== thisAction.managementTask.id
          ),
        },
        managementTask: null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
