import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(5 * 32px + 4 * 8px);
`;

export const Color = styled.button`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: none;
  border: none;
`;
