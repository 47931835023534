import { DirectedGraph } from 'graphology';
import { bfsFromNode } from 'graphology-traversal';
import { DateTime } from 'luxon';
import {
  Elements,
  isNode,
  isEdge,
  Node,
  FlowElement,
  Edge,
} from 'react-flow-renderer';

const getInputNodes = (derivation: Elements) => {
  return derivation.filter(
    (element) => isNode(element) && element.type === 'INPUT'
  );
};

const getOutputNode = (derivation: Elements) => {
  return derivation.find(
    (element) => isNode(element) && element.type === 'OUTPUT'
  ) as FlowElement;
};

const getEdges = (derivation: Elements) => {
  return derivation.filter((element) => isEdge(element));
};

const orderDerivation = (derivation: Elements) => {
  const inputNodes = getInputNodes(derivation);
  const outputNode = getOutputNode(derivation);
  const edges = getEdges(derivation);

  const otherNodes = derivation.filter(
    (element) =>
      isNode(element) &&
      !(element.type === 'INPUT' || element.type === 'OUTPUT')
  );

  return [...inputNodes, ...otherNodes, outputNode, ...edges];
};

const inferBinarydataType = (inputOneData: any, inputTwoData: any) => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  return inputOneData.dataType;
};

const add = (inputOneData: any, inputTwoData: any) => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  console.log(valueOne, valueTwo);

  switch (inputOneData.dataType) {
    case 'NUMBER':
    case 'CHAR':
      return parseFloat(valueOne) + parseFloat(valueTwo);

    case 'CHOICE':
      return [...new Set(...valueOne.responses, ...valueTwo.responses)];

    default:
      throw Error();
  }
};

const subtract = (inputOneData: any, inputTwoData: any) => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  switch (inputOneData.dataType) {
    case 'NUMBER':
    case 'CHAR':
      return valueOne - valueTwo;

    case 'DATE_TIME':
      return DateTime.fromISO(valueOne).diff(DateTime.fromISO(valueTwo));

    case 'CHOICE': {
      const responsesOne = [...valueOne.responses];
      const responsesTwo = [...valueTwo.responses];
      return responsesOne.filter(
        (response) => !responsesTwo.includes(response)
      );
    }

    default:
      throw Error();
  }
};

const multiply = (inputOneData: any, inputTwoData: any) => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  switch (inputOneData.dataType) {
    case 'NUMBER':
      return valueOne * valueTwo;

    default:
      throw Error();
  }
};

const divide = (inputOneData: any, inputTwoData: any) => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  switch (inputOneData.dataType) {
    case 'NUMBER':
      return valueOne / valueTwo;

    default:
      throw Error();
  }
};

const abs = (inputOneData: any) => {
  const { value: valueOne } = inputOneData;

  switch (inputOneData.dataType) {
    case 'NUMBER':
      return Math.abs(valueOne);

    default:
      throw Error();
  }
};

const not = (inputOneData: any) => {
  const { value: valueOne } = inputOneData;

  switch (inputOneData.dataType) {
    case 'BOOLEAN':
      return !valueOne;

    default:
      throw Error();
  }
};

const and = (inputOneData: any, inputTwoData: any): boolean => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  switch (inputOneData.dataType) {
    case 'BOOLEAN':
      return valueOne && valueTwo;

    default:
      throw Error();
  }
};

const or = (inputOneData: any, inputTwoData: any): boolean => {
  if (inputOneData.dataType !== inputTwoData.dataType) {
    throw Error();
  }

  const { value: valueOne } = inputOneData;
  const { value: valueTwo } = inputTwoData;

  switch (inputOneData.dataType) {
    case 'BOOLEAN':
      return valueOne || valueTwo;

    default:
      throw Error();
  }
};

const lessThan = (inputOneData: any, condition: any) => {
  const { value: valueOne } = inputOneData;
  const { value: conditionValue } = condition;

  switch (inputOneData.dataType) {
    case 'NUMBER':
      return parseFloat(valueOne) < parseFloat(conditionValue);

    case 'DATE_TIME':
      return DateTime.fromISO(valueOne) < DateTime.fromISO(conditionValue);

    default:
      throw Error();
  }
};

const lessThanEqual = (inputOneData: any, condition: any) => {
  const { value: valueOne } = inputOneData;
  const { value: conditionValue } = condition;

  switch (inputOneData.dataType) {
    case 'NUMBER':
      return valueOne <= conditionValue;

    case 'DATE_TIME':
      return DateTime.fromISO(valueOne) <= DateTime.fromISO(condition);

    default:
      throw Error();
  }
};

const evaluateNode = (
  graph: DirectedGraph<Node, Edge>,
  key: string,
  attributes: Node,
  depth: number
) => {
  if (attributes.type === 'INPUT') return;

  if (attributes.type === 'OUTPUT') {
    const [inputEdge] = graph.inEdgeEntries(key);
    graph.setNodeAttribute(key, 'data', {
      ...attributes.data,
      value: inputEdge.sourceAttributes.data.value,
    });
    return;
  }

  let retType: any;
  let ret: any;

  if (attributes.type === 'ADD') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = add(inputOneData, inputTwoData);
  }

  if (attributes.type === 'SUBTRACT') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = subtract(inputOneData, inputTwoData);
  }

  if (attributes.type === 'MULTIPLY') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = multiply(inputOneData, inputTwoData);
  }

  if (attributes.type === 'DIVIDE') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = divide(inputOneData, inputTwoData);
  }

  if (attributes.type === 'ABSOLUTE') {
    const [inputOneEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = abs(inputOneData);
  }

  if (attributes.type === 'NOT') {
    const [inputOneEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    retType = inputOneData.dataType;
    ret = not(inputOneData);
  }

  if (attributes.type === 'AND') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = 'BOOLEAN';
    ret = and(inputOneData, inputTwoData);
  }

  if (attributes.type === 'OR') {
    const [inputOneEdge, inputTwoEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const inputTwoData = inputTwoEdge.sourceAttributes.data;
    retType = 'BOOLEAN';
    ret = or(inputOneData, inputTwoData);
  }

  if (attributes.type === 'LESS_THAN') {
    const [inputOneEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const nodeData = inputOneEdge.targetAttributes.data;
    retType = 'BOOLEAN';
    console.log(nodeData);
    ret = lessThan(inputOneData, nodeData.select);
  }

  if (attributes.type === 'LESS_THAN_EQUAL') {
    const [inputOneEdge] = graph.inEdgeEntries(key);
    const inputOneData = inputOneEdge.sourceAttributes.data;
    const nodeData = inputOneEdge.targetAttributes.data;
    retType = 'BOOLEAN';
    ret = lessThanEqual(inputOneData, nodeData.select);
  }

  graph.setNodeAttribute(key, 'data', {
    ...attributes.data,
    dataType: retType,
    value: ret,
  });
};

const executeDerivation = (
  derivation: Elements,
  inputs: Record<string, any>
) => {
  console.log(derivation, inputs);
  const orderedDerivation = orderDerivation(derivation);
  const graph = new DirectedGraph<Node, Edge>({ allowSelfLoops: false });
  const startNode = graph.addNode('start');
  const outputNode = getOutputNode(derivation);

  orderedDerivation.forEach((element) => {
    if (isNode(element)) {
      const node = graph.addNode(element.id, element);
      if (element.type === 'INPUT') {
        graph.addEdge(startNode, node);
        graph.setNodeAttribute(node, 'data', {
          ...element.data,
          value: inputs[node],
        });
      }
    }
    if (isEdge(element)) graph.addEdge(element.source, element.target, element);
  });

  bfsFromNode(graph, startNode, (...params) => evaluateNode(graph, ...params));
  const { value } = graph.getNodeAttribute(outputNode.id, 'data');

  console.log(value);

  return [value, graph];
};

export const renderValueType = (dataType: string) => {
  switch (dataType) {
    case 'NUMBER':
      return 'Number';
    case 'BOOLEAN':
      return 'Boolean';
    case 'CHAR':
      return 'Text';
    case 'DATE_TIME':
      return 'Date & Time';
    default:
      return null;
  }
};

export const renderValue = (dataType: string, value: any): string | null => {
  if (!dataType) return null;
  if (typeof value === 'undefined') return null;

  switch (dataType) {
    case 'NUMBER':
      return value;
    case 'BOOLEAN':
      return value ? 'True' : 'False';
    default:
      return null;
  }
};

export default executeDerivation;
