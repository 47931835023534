import React from 'react';
import { Routes, Route, Link, matchPath, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { truncateReference } from 'lib/utils';
import { useClient } from 'lib/hooks';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { useReducerContext } from '../ClientJob/reducer';
import Breadcrumb from '../Breadcrumb';

const ClientJobBreadcrumb = ({ loading }: { loading: boolean }) => {
  const client = useClient();

  const [{ job }] = useReducerContext();

  const location = useLocation();

  if (!job) {
    return (
      <PortalBreadcrumb>
        <Breadcrumb.Wrapper>
          <Breadcrumb.Link to={`/clients/${client.slug}/jobs`}>
            Jobs
          </Breadcrumb.Link>
          <Breadcrumb.Separator />
          <Breadcrumb.Loader />
        </Breadcrumb.Wrapper>
      </PortalBreadcrumb>
    );
  }

  const jobReference = job.parent
    ? `${job.parent.reference}/${truncateReference(job.reference)}`
    : job.reference;

  return (
    <PortalBreadcrumb>
      <Routes>
        <Route
          index
          element={
            <Breadcrumb.Wrapper>
              <Breadcrumb.Link to={`/clients/${job.client.slug}/jobs`}>
                Jobs
              </Breadcrumb.Link>
              <Breadcrumb.Separator />
              {loading ? (
                <Breadcrumb.Loader />
              ) : (
                <>
                  <Breadcrumb.Terminator className="mono text-75">
                    {jobReference}
                  </Breadcrumb.Terminator>
                  <Breadcrumb.Separator />
                  <Breadcrumb.Terminator>{job.name}</Breadcrumb.Terminator>
                </>
              )}
            </Breadcrumb.Wrapper>
          }
        />
        <Route
          path="reports"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Reports</h4>
            </div>
          }
        />
        <Route
          path="reports/:reportNumber"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link
                to={`/clients/${job.client.slug}/jobs/${job.reference}/reports`}
              >
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Reports
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4 className="mono">
                #
                {
                  matchPath(
                    '/clients/:slug/jobs/:reference/reports/:reportNumber',
                    location.pathname
                  )?.params.reportNumber
                }
              </h4>
            </div>
          }
        />
        <Route
          path="issues"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Issues</h4>
            </div>
          }
        />
        <Route
          path="issues/:number"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link
                to={`/clients/${job.client.slug}/jobs/${job.reference}/issues`}
              >
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Issues
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4 className="mono">
                #
                {
                  matchPath(
                    '/clients/:slug/jobs/:reference/issues/:number',
                    location.pathname
                  )?.params.number
                }
              </h4>
            </div>
          }
        />
        <Route
          path="logs"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job?.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Logs</h4>
            </div>
          }
        />
        <Route
          path="map"
          element={
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job?.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Map</h4>
            </div>
          }
        />
      </Routes>
    </PortalBreadcrumb>
  );
};

export default ClientJobBreadcrumb;
