import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import classNames from 'classnames';
import { useClient, useHoveredId, useMiniSearch } from 'lib/hooks';
import { SettingsVariable } from 'lib/types';
import { getValueDisplay, removeKeys } from 'lib/utils';
import { useTheme } from 'styled-components';
import BooleanField from '../BooleanField';
import Button from '../Button';
import CardWrapper from '../CardWrapper';
import CharField from '../CharField';
import DataTypeField from '../DataTypeField';
import SearchBar from '../SearchBar';
import SimpleButton from '../SimpleButton';
import SimpleButtonCircle from '../SimpleButtonCircle';
import SlateField from '../SlateField';
import SlateViewer from '../SlateViewer';
import {
  CREATE_SETTINGS_VARIABLE_MUTATION,
  DELETE_SETTINGS_VARIABLE_MUTATION,
  SETTINGS_VARIABLES_QUERY,
  UPDATE_SETTINGS_VARIABLE_MUTATION,
} from './query';
import {
  CreateSettingsVariableMutationData,
  CreateSettingsVariableMutationVariables,
  DeleteSettingsVariableMutationData,
  DeleteSettingsVariableMutationVariables,
  FormValues,
  SettingsVariablesQueryData,
  SettingsVariablesQueryVariables,
  UpdateSettingsVariableMutationData,
  UpdateSettingsVariableMutationVariables,
} from './types';

const ClientSettingsVariables = () => {
  const theme = useTheme();
  const client = useClient();
  const [variables, setVariables] = useState<SettingsVariable[]>([]);
  const [creatingVariable, setCreatingVariable] = useState(false);
  const [updatingVariable, setUpdatingVariable] = useState<SettingsVariable>();

  useQuery<SettingsVariablesQueryData, SettingsVariablesQueryVariables>(
    SETTINGS_VARIABLES_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { clientId: client.id },
      onCompleted: (data) => {
        setVariables(data.settingsVariables);
      },
    }
  );

  const handleUpdateVariableOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;
    setUpdatingVariable(variables.find((variable) => variable.id === value));
  };

  const [deleteSettingsVariable] = useMutation<
    DeleteSettingsVariableMutationData,
    DeleteSettingsVariableMutationVariables
  >(DELETE_SETTINGS_VARIABLE_MUTATION);

  const handleDeleteVariableOnClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;
    const { data } = await deleteSettingsVariable({
      variables: { settingsVariableId: value },
    });

    if (data) {
      const { deleted, deletedId } = data.deleteSettingsVariable;

      if (deleted) {
        setVariables((prevVariables) =>
          prevVariables.filter((variable) => variable.id !== deletedId)
        );
      }
    }
  };

  const [[hoveredId], hoveredCallbacks] = useHoveredId('-1');

  const renderVariableDisplay = (variable: SettingsVariable) => {
    const isHovered = hoveredId === variable.id;

    return (
      <div data-id={variable.id} {...hoveredCallbacks}>
        <div className="d-flex justify-content-between">
          <div className="center-vertically">
            <h6 className="mb-0">{variable.name}</h6>
          </div>
          <div>
            <strong>
              {getValueDisplay(variable.dataType, variable.value)}
            </strong>
            <SimpleButtonCircle
              className="ml-2"
              style={{ opacity: isHovered ? 1 : 0.5 }}
              icon={faPen}
              value={variable.id}
              onClick={handleUpdateVariableOnClick}
            />
            <SimpleButtonCircle
              icon={faTrash}
              style={{ opacity: isHovered ? 1 : 0.5 }}
              value={variable.id}
              onClick={handleDeleteVariableOnClick}
            />
          </div>
        </div>
        <div className="text-75" style={{ fontSize: '0.8rem' }}>
          <SlateViewer value={variable.description} />
        </div>
      </div>
    );
  };

  const [createSettingsVariable, { loading: creating }] = useMutation<
    CreateSettingsVariableMutationData,
    CreateSettingsVariableMutationVariables
  >(CREATE_SETTINGS_VARIABLE_MUTATION);

  const [updateSettingsVariable, { loading: updating }] = useMutation<
    UpdateSettingsVariableMutationData,
    UpdateSettingsVariableMutationVariables
  >(UPDATE_SETTINGS_VARIABLE_MUTATION);

  const handleCreateOnClick = () => {
    setCreatingVariable(true);
    setUpdatingVariable(undefined);
  };

  const handleCreateOnSubmit = async (values: FormValues) => {
    const { data } = await createSettingsVariable({
      variables: {
        clientId: client.id,
        data: values,
      },
    });

    if (data) {
      const { settingsVariable: createdVariable } = data.createSettingsVariable;
      setVariables((prevVariables) => [...prevVariables, createdVariable]);
      setCreatingVariable(false);
    }
  };

  const handleUpdateOnSubmit = async (values: FormValues) => {
    if (!updatingVariable) return;

    const { data } = await updateSettingsVariable({
      variables: {
        settingsVariableId: updatingVariable.id,
        data: removeKeys<SettingsVariable>(values, ['id', '__typename']),
      },
    });

    if (data) {
      const { settingsVariable: updatedVariable } = data.updateSettingsVariable;
      setVariables((prevVariables) =>
        prevVariables.map((variable) =>
          variable.id === updatedVariable.id
            ? { ...variable, ...updatedVariable }
            : variable
        )
      );
      setUpdatingVariable(undefined);
    }
  };

  const handleOnCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCreatingVariable(false);
    setUpdatingVariable(undefined);
  };

  const renderVariableEditor = (variable?: SettingsVariable) => {
    const isCreating = !variable;

    const getValueFieldComponent = (
      dataType: string
    ): React.FC<FieldRenderProps<any>> => {
      switch (dataType) {
        case 'NUMBER':
          return CharField;
        case 'BOOLEAN':
          return BooleanField;
        case 'DATE_TIME':
          return CharField;
        default:
          return CharField;
      }
    };

    const getDefaultValue = (dataType: string) => {
      switch (dataType) {
        case 'NUMBER':
          return 0;
        case 'BOOLEAN':
          return false;
        case 'DATE_TIME':
          return DateTime.local;
        default:
          return '';
      }
    };

    return (
      <>
        <Form
          initialValues={isCreating ? { dataType: 'NUMBER' } : variable}
          onSubmit={isCreating ? handleCreateOnSubmit : handleUpdateOnSubmit}
        >
          {({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between mb-3">
                <Field name="name" component={CharField} />
                <div className="d-flex">
                  <div className="mr-2" style={{ width: 200 }}>
                    <Field
                      name="dataType"
                      component={DataTypeField}
                      parse={(value) => {
                        form.change('value', getDefaultValue(value));
                        return value;
                      }}
                    />
                  </div>
                  <Field
                    name="value"
                    label="Value"
                    component={getValueFieldComponent(values.dataType)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Field
                  name="description"
                  component={SlateField}
                  placeholder="Write a short description for this variable..."
                />
              </div>
              <div className="d-flex justify-content-end">
                <SimpleButton
                  className="mr-2"
                  icon={faTimes}
                  secondary
                  onClick={handleOnCancel}
                >
                  Cancel
                </SimpleButton>
                <SimpleButton icon={faCheck} loading={creating || updating}>
                  {isCreating ? 'Create' : 'Save'}
                </SimpleButton>
              </div>
            </form>
          )}
        </Form>
      </>
    );
  };

  const [results, searchBarProps] = useMiniSearch(
    { fields: ['name', 'description', 'value'] },
    variables
  );

  return (
    <CardWrapper padding={false}>
      <div
        className="d-flex justify-content-between p-2"
        style={{ borderBottom: theme.border }}
      >
        <div className="center-vertically">
          <h5 className="mb-0">Variables</h5>
        </div>
        <div className="d-flex">
          <SearchBar
            wrapperClassName="mr-2"
            {...searchBarProps}
            placeholder="Search..."
          />
          <Button icon={faPlus} onClick={handleCreateOnClick}>
            Create
          </Button>
        </div>
      </div>
      {results.length === 0 ? (
        <div className="p-2">
          <span>No results found for</span>{' '}
          <strong>{searchBarProps.value}</strong>
        </div>
      ) : (
        results.map((variable, index) => {
          const last = index === results.length - 1;
          const isUpdating = variable.id === updatingVariable?.id;
          return (
            <div
              key={variable.id}
              className={classNames('p-2', {
                'border-bottom-0': !isUpdating && last,
              })}
              style={
                isUpdating
                  ? { border: `2px solid ${theme.color.primary.hex()}` }
                  : { borderBottom: theme.border }
              }
            >
              {isUpdating ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="center-vertically">
                      <h5 className="mb-0">Edit variable</h5>
                    </div>
                    <div>
                      <SimpleButtonCircle
                        icon={faTrash}
                        style={{ marginTop: -2, marginRight: -2 }}
                        value={variable.id}
                        onClick={handleDeleteVariableOnClick}
                      />
                    </div>
                  </div>
                  {renderVariableEditor(variable)}
                </>
              ) : (
                renderVariableDisplay(variable)
              )}
            </div>
          );
        })
      )}
      {creatingVariable && (
        <div
          className="p-2"
          style={{ border: `2px solid ${theme.color.primary.hex()}` }}
        >
          <div>
            <h5>Create new variable</h5>
          </div>
          <div>{renderVariableEditor()}</div>
        </div>
      )}
    </CardWrapper>
  );
};

export default ClientSettingsVariables;
