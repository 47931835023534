import React, { Dispatch, SetStateAction, useState } from 'react';

type Return = [
  [string, Dispatch<SetStateAction<string>>],
  React.InputHTMLAttributes<HTMLInputElement>
];

const useSearchBar = (
  searchTermOnChange?: (searchTerm: string) => void
): Return => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: newSearchTerm } = event.currentTarget;
    setSearchTerm(newSearchTerm);
    if (searchTermOnChange) searchTermOnChange(newSearchTerm);
  };

  return [
    [searchTerm, setSearchTerm],
    { value: searchTerm, onChange: handleSearchTermOnChange },
  ];
};

export default useSearchBar;
