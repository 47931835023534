import Graph from 'graphology';
import React, { useContext } from 'react';
import { Spec } from 'immutability-helper';
import { ReportTemplateBlock } from 'lib/types';
import { Edge, Node } from 'react-flow-renderer';

type ContextType = {
  derivationExecution?: Graph<Node, Edge>;
  derivationOnChange: (updateSpec: Spec<ReportTemplateBlock>) => void;
  searchFields: (searchTerm: string) => ReportTemplateBlock[];
};

const Context = React.createContext<ContextType>({
  derivationOnChange: () => {},
  searchFields: () => [],
});

export const useDerivationEditorContext = () => useContext(Context);

export default Context;
