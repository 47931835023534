import React from 'react';
import {
  getIncomers,
  Handle,
  Node,
  Position,
  useStoreState,
} from 'react-flow-renderer';
import NodeBase from '../NodeBase';

const NodeOutput = (node: Node) => {
  const { id } = node;
  const elements = useStoreState((state) => state.elements);
  const connected = getIncomers(node, elements);
  const isConnectable = connected.length === 0;

  return (
    <NodeBase {...node}>
      <span>Output</span>
      <Handle
        id={`target-1-${id}`}
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
    </NodeBase>
  );
};

export default NodeOutput;
