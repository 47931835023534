import React from 'react';
import { SearchBarResultsProps } from './types';
import { Wrapper } from './styled';

const SearchBarResults = ({
  innerRef,
  children,
  isOpen,
  searchTerm,
  results,
}: SearchBarResultsProps) => {
  return (
    <Wrapper ref={innerRef} className="shadow mx-2">
      {isOpen && (
        <div className="inner">
          {results.length === 0 ? (
            <>
              {searchTerm ? (
                <div className="p-2">
                  <span className="text-75">No results for</span>{' '}
                  <strong>{searchTerm}</strong>
                </div>
              ) : (
                <div className="p-2 text-75">
                  Search for fields to use as inputs to this derivation...
                </div>
              )}
            </>
          ) : (
            children({})
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default SearchBarResults;
