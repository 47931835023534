import styled from 'styled-components';
import chroma from 'chroma-js';

export const ReportTemplateResponse = styled.div`
  display: inline-block;
  border-radius: 999px;
  padding: 0 0.5rem;

  background-color: ${({ color }) =>
    chroma(color as string)
      .alpha(0.15)
      .hex()};
  color: ${({ color }) => color};
`;
