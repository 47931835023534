import { gql } from '@apollo/client';

export const SETTINGS_VARIABLES_QUERY = gql`
  query ($clientId: ID!) {
    settingsVariables(clientId: $clientId) {
      id
      name
      description
      value
      dataType
    }
  }
`;

export const CREATE_SETTINGS_VARIABLE_MUTATION = gql`
  mutation ($clientId: ID!, $data: CreateSettingsVariableInput!) {
    createSettingsVariable(clientId: $clientId, data: $data) {
      settingsVariable {
        id
        name
        description
        value
        dataType
      }
    }
  }
`;

export const UPDATE_SETTINGS_VARIABLE_MUTATION = gql`
  mutation ($settingsVariableId: ID!, $data: UpdateSettingsVariableInput!) {
    updateSettingsVariable(
      settingsVariableId: $settingsVariableId
      data: $data
    ) {
      settingsVariable {
        id
        name
        description
        value
        dataType
      }
    }
  }
`;

export const DELETE_SETTINGS_VARIABLE_MUTATION = gql`
  mutation ($settingsVariableId: ID!) {
    deleteSettingsVariable(settingsVariableId: $settingsVariableId) {
      deleted
      deletedId
    }
  }
`;
