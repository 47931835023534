import React, { useState } from 'react';
import { Job, JobPriority, Option, QueryResult } from 'lib/types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { useClient, useTable } from 'lib/hooks';
import { useQuery } from '@apollo/client';
import { ValueType } from 'react-select';
import { parseDateTime, parseTimeAgo } from 'lib/utils';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import Figure from '../Figure';
import Table from '../Table';
import CardWrapper from '../CardWrapper';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { INITIAL_QUERY } from './query';
import { InitialQueryData, InitialQueryVariables } from './types';
import TableHeadButton from '../TableHeadButton';
import SearchBar from '../SearchBar';
import Select from '../Select';
import TableFooter from '../TableFooter';
import SimpleButtonLink from '../SimpleButtonLink';
import JobPriorityPill from '../JobPriorityPill';

const ClientImmediateActionsUnassigned = () => {
  const client = useClient();

  const [priorities, setPriorities] = useState<JobPriority[]>([]);
  const [jobs, setJobs] = useState<QueryResult<Job>>({
    results: [],
    count: 0,
  });

  const { loading, refetch } = useQuery<
    InitialQueryData,
    InitialQueryVariables
  >(INITIAL_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      clientId: client.id,
      sort: { name: 'priority', order: true },
      includePriorities: true,
    },
    onCompleted: (data) => {
      if (data.jobPriorities) {
        setPriorities(data.jobPriorities);
      }
      setJobs(data.jobs);
    },
  });

  const handlePriorityOnChange = (
    selected: ValueType<Option<string>, false>
  ) => {
    refetch({ priorityId: selected?.value, includePriorities: false }).then(
      (response) => {
        setJobs(response.data.jobs);
      }
    );
  };

  const tableHeadNames = ['reference', 'name', 'priority'] as const;
  const {
    sortState,
    page,
    refetching,
    handleOnSort,
    searchInputProps,
    handleChangePage,
  } = useTable<typeof tableHeadNames, InitialQueryData, InitialQueryVariables>(
    tableHeadNames,
    refetch,
    {
      defaultActiveName: 'priority',
      sortVariables: { includePriorities: false },
      searchVariables: { includePriorities: false },
      paginationVariables: { includePriorities: false },
      sortOnCompleted: (response) => {
        setJobs(response.data.jobs);
      },
      searchOnCompleted: (response) => {
        setJobs(response.data.jobs);
      },
      changePageCompleted: (response) => {
        setJobs(response.data.jobs);
      },
    }
  );

  return (
    <>
      <PortalBreadcrumb>
        <div className="d-flex">
          <Link to={`/clients/${client.slug}/jobs`}>
            <h4 className="text-75 client__tab-bar-breadcrumb-link">
              Immediate Actions
            </h4>
          </Link>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          {loading ? (
            <FontAwesomeIcon
              style={{ fontSize: '1.25rem', marginTop: 5 }}
              icon={faSpinnerThird}
              fixedWidth
              spin
            />
          ) : (
            <h4>
              Unassigned <Figure value={jobs.count} />
            </h4>
          )}
        </div>
      </PortalBreadcrumb>
      <div className="h-100">
        <CardWrapper className="h-100 p-0 d-flex flex-column">
          <div className="m-3 d-flex">
            <div className="flex-grow-1">
              <p className="mb-2 text-50">Search</p>
              <SearchBar
                placeholder="Search unassigned jobs..."
                loading={loading || refetching}
                {...searchInputProps}
              />
            </div>
            <div className="w-25">
              <p className="mb-2 text-50">Filter by priority</p>
              <Select
                isClearable
                options={priorities.map((priority) => ({
                  value: priority.id,
                  label: priority.code,
                }))}
                onChange={handlePriorityOnChange}
              />
            </div>
          </div>
          <Table
            className="flex-grow-1"
            style={{ minHeight: 0 }}
            flexBases={[10, 30, 15, 25, 20]}
          >
            <thead>
              <tr>
                <th>
                  <TableHeadButton
                    {...sortState.columns.reference}
                    onClick={() => handleOnSort('reference')}
                  >
                    Reference
                  </TableHeadButton>
                </th>
                <th>
                  <TableHeadButton
                    {...sortState.columns.name}
                    onClick={() => handleOnSort('name')}
                  >
                    Name
                  </TableHeadButton>
                </th>
                <th>
                  <TableHeadButton
                    {...sortState.columns.priority}
                    onClick={() => handleOnSort('priority')}
                  >
                    Priority
                  </TableHeadButton>
                </th>
                <th>Must be assigned by</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!loading && jobs.results.length === 0 ? (
                <tr className="empty-message">
                  <td>No unassigned jobs.</td>
                </tr>
              ) : (
                jobs.results.map((job) => (
                  <tr key={job.id}>
                    <td className="mono">{job.reference}</td>
                    <td>
                      <Link
                        to={`/clients/${client.slug}/jobs/${job.reference}`}
                      >
                        {job.name}
                      </Link>
                    </td>
                    <td>
                      <JobPriorityPill job={job} type="response" />
                    </td>
                    <td className="mono">
                      {parseDateTime(job.targetDateTimeStart)}{' '}
                      <small className="text-75">
                        ({parseTimeAgo(job.targetDateTimeStart)})
                      </small>
                    </td>
                    <td className="table-cell-with-button">
                      <SimpleButtonLink
                        to={`/clients/${client.slug}/jobs/${job.reference}`}
                        buttonProps={{ icon: faArrowRight, iconSide: 'right' }}
                      >
                        Assign visit
                      </SimpleButtonLink>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <TableFooter
            page={page}
            count={jobs.count}
            length={jobs.results.length}
            handleChangePage={handleChangePage}
          />
        </CardWrapper>
      </div>
    </>
  );
};

export default ClientImmediateActionsUnassigned;
