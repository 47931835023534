import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import Node from '../NodeBase';
import { NodeBinaryProps } from './types';

const NodeBinary = ({ children, ...node }: NodeBinaryProps) => {
  const { id } = node;
  return (
    <Node {...node}>
      <Handle
        id={`target-1-${id}`}
        type="target"
        position={Position.Top}
        style={{ left: '20%' }}
      />
      <Handle
        id={`target-2-${id}`}
        type="target"
        position={Position.Top}
        style={{ left: '80%' }}
      />
      {children}
      <Handle id={`source-1-${id}`} type="source" position={Position.Bottom} />
    </Node>
  );
};

export default NodeBinary;
