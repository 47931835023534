import { DeckGL } from 'deck.gl';
import React, { useRef } from 'react';
import { _MapContext, MapRef, StaticMap } from 'react-map-gl';
import { useTheme } from 'styled-components';
import { MapProps } from './types';

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const Map = ({ children, height, viewState, viewStateOnChange }: MapProps) => {
  const { mapboxStyle } = useTheme();
  const mapRef = useRef<MapRef>(null);

  return (
    <div
      className="position-relative overflow-hidden"
      style={{ height, borderRadius: '0.25rem' }}
    >
      <DeckGL
        width="100%"
        height="100%"
        viewState={viewState}
        controller
        ContextProvider={_MapContext.Provider}
        onViewStateChange={viewStateOnChange}
      >
        <StaticMap
          ref={mapRef}
          width="100%"
          height="100%"
          mapStyle={mapboxStyle}
          mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
        >
          {children}
        </StaticMap>
      </DeckGL>
    </div>
  );
};

export default Map;
