import { useLazyQuery } from '@apollo/client';
import { faLessThanEqual } from '@fortawesome/pro-light-svg-icons/faLessThanEqual';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/pro-light-svg-icons/faLessThan';
import { Node } from 'react-flow-renderer';
import { ValueType } from 'react-select/src/types';
import { useClient } from 'lib/hooks';
import { serialize } from '../../../../lib/slate';
import Checkbox from '../../../Checkbox';
import SelectAsyncCreatable from '../../../SelectAsyncCreatable';
import { useDerivationEditorContext } from '../../context';
import { SETTINGS_VARIABLES_QUERY } from './query';
import {
  SettingsVariablesQueryData,
  SettingsVariablesQueryVariables,
} from './types';
import NodeUnaryCondition from '../NodeUnaryCondition';

const NodeLessThan = (node: Node) => {
  const client = useClient();
  const { derivationOnChange, searchFields } = useDerivationEditorContext();
  const { id, type, data } = node;

  const handleConditionOnChange = (value: ValueType<any, false>) => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        prevDerivation.map((element) =>
          element.id === id
            ? { ...element, data: { ...element.data, select: value } }
            : element
        ),
    });
  };

  const handleToggleEquals = () => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        prevDerivation.map((element) => {
          if (element.id === id) {
            let nextElementType = '';

            if (element.type === 'LESS_THAN') {
              nextElementType = 'LESS_THAN_EQUAL';
            }
            if (element.type === 'LESS_THAN_EQUAL') {
              nextElementType = 'LESS_THAN';
            }

            return {
              ...element,
              type: nextElementType,
            };
          }

          return element;
        }),
    });
  };

  const [getSettingsVariables] = useLazyQuery<
    SettingsVariablesQueryData,
    SettingsVariablesQueryVariables
  >(SETTINGS_VARIABLES_QUERY);

  const handleLoadOptions = async (searchTerm: string) => {
    const ret = [];

    const { data: searchData } = await getSettingsVariables({
      variables: { clientId: client.id, searchTerm },
    });

    if (searchData) {
      ret.push({
        label: 'Variables',
        options: searchData.settingsVariables.map((variable) => ({
          label: variable.name,
          value: variable.id,
          settingsVariableId: variable.id,
          settingsVariableValue: variable.value,
        })),
      });
    }

    const fields = searchFields(searchTerm);
    ret.push({
      label: 'Fields',
      options: fields.map((field) => ({
        label: serialize(field.label),
        value: field.id,
      })),
    });

    return ret;
  };

  return (
    <NodeUnaryCondition {...node}>
      <div className="d-flex">
        <div className="m-1">
          <FontAwesomeIcon
            icon={type === 'LESS_THAN_EQUAL' ? faLessThanEqual : faLessThan}
            fixedWidth
          />
        </div>
        <div>
          <div style={{ width: 150 }}>
            <SelectAsyncCreatable
              className="mb-2"
              value={data.select}
              customStyles={{ menu: { width: 'auto', maxWidth: 500 } }}
              cacheOptions
              loadOptions={handleLoadOptions}
              onChange={handleConditionOnChange}
            />
          </div>
          <Checkbox
            wrapperProps={{ className: 'mb-0' }}
            small
            checked={type === 'LESS_THAN_EQUAL'}
            label="Equals"
            onClick={handleToggleEquals}
          />
        </div>
      </div>
    </NodeUnaryCondition>
  );
};

export default NodeLessThan;
