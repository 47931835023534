import React, { useCallback, useEffect, useMemo } from 'react';

export const useClickedOutside = (
  ref: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  onClickedOutside: (event: MouseEvent) => void
) => {
  const refArray = useMemo(() => (Array.isArray(ref) ? ref : [ref]), [ref]);

  const handleOnClickedOutside = useCallback(
    (event: MouseEvent) => {
      if (
        refArray.some(
          (innerRef) =>
            innerRef.current &&
            innerRef.current.contains(event.target as Element)
        )
      ) {
        return;
      }

      onClickedOutside(event);
    },
    [onClickedOutside, refArray]
  );

  useEffect(() => {
    if (!refArray.some((innerRef) => !!innerRef.current)) {
      return () => () => {
        document.removeEventListener('mousedown', handleOnClickedOutside);
      };
    }

    document.addEventListener('mousedown', handleOnClickedOutside);
    return () => {
      document.removeEventListener('mousedown', handleOnClickedOutside);
    };
  }, [refArray, handleOnClickedOutside]);
};

export default useClickedOutside;
