import React from 'react';
import { Line } from 'lib/loaders';
import { useTheme } from 'styled-components';

const Loader = () => {
  const theme = useTheme();
  return (
    <div>
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          key={index.toString()}
          className="p-2"
          style={{ borderBottom: theme.border }}
        >
          <Line
            className="mb-3"
            style={{ width: '70%', height: 16, opacity: 0.25 }}
          />
          <Line className="mb-3" style={{ width: '60%', opacity: 0.35 }} />
          <Line
            style={{
              width: '90%',
              height: 16,
              marginBottom: 6,
              opacity: 0.2,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default Loader;
