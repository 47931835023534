import { useQuery } from '@apollo/client';
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faDivide } from '@fortawesome/pro-light-svg-icons/faDivide';
import { faGreaterThan } from '@fortawesome/pro-light-svg-icons/faGreaterThan';
import { faLessThan } from '@fortawesome/pro-light-svg-icons/faLessThan';
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faValueAbsolute } from '@fortawesome/pro-light-svg-icons/faValueAbsolute';
import { faQuestionSquare } from '@fortawesome/pro-solid-svg-icons/faQuestionSquare';
import Graph from 'graphology';
import { Spec } from 'immutability-helper';
import { serialize } from 'lib/slate';
import { ReportTemplateBlock } from 'lib/types';
import { generateId } from 'lib/utils';
import MiniSearch from 'minisearch';
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  Edge,
  Node,
  OnLoadFunc,
} from 'react-flow-renderer';
import { useTheme } from 'styled-components';
import { useClickedOutside } from '../../lib/hooks';
import CharField from '../CharField';
import {
  updateReportTemplateBlock,
  updateReportTemplateBlockLogic,
  useReducerContext,
} from '../ReportTemplateEditor/reducer';
import SearchBar from '../SearchBar';
import SearchBarResults from '../SearchBarResults';
import SidePanel from '../SidePanel';
import SimpleButton from '../SimpleButton';
import SimpleButtonSquare from '../SimpleButtonSquare';
import edges from './edges';
import nodes from './nodes';
import Context from './context';
import { INITIAL_QUERY } from './query';
import {
  InitialQueryData,
  InitialQueryVariables,
  ReportTemplateBlockDerivationEditorProps,
} from './types';
import executeDerivation, { renderValue, renderValueType } from './utils';
import { Small } from '../Typography';

const miniSearch = new MiniSearch({
  fields: ['label'],
});

const ReportTemplateBlockDerivationEditor = ({
  item,
  isOpen,
  toggle,
}: ReportTemplateBlockDerivationEditorProps) => {
  const theme = useTheme();
  const [{ reportTemplate }, dispatch] = useReducerContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<ReportTemplateBlock[]>([]);

  const updateAction =
    item.valueType === 'LOGIC'
      ? updateReportTemplateBlockLogic
      : updateReportTemplateBlock;

  const { loading } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      skip: !isOpen,
      variables: { reportTemplateBlockId: item.id, isOpen },
      onCompleted: (data) => {
        const { derivation } = data.reportTemplateBlock;
        dispatch(
          updateAction(item.id, {
            derivation: () => derivation,
          })
        );
      },
    }
  );

  const handleBeginOnClick = () => {
    if (!reportTemplate?.root) return;

    let defaultDerivation: any = [
      {
        id: generateId(),
        type: 'OUTPUT',
        data: { type: 'OUTPUT', label: 'Output' },
        position: { x: 250, y: 25 },
      },
    ];

    if (item.valueType === 'LOGIC') {
      const parentBlock = reportTemplate.root.blocks.find(
        (block) => block.id === item.parent.id
      );

      if (parentBlock) {
        defaultDerivation = [
          {
            id: item.parent.id,
            type: 'INPUT',
            data: {
              type: 'INPUT',
              reportTemplateBlockId: parentBlock.id,
              dataType: parentBlock.valueType,
              label: parentBlock.label,
            },
            position: { x: 250, y: 25 },
          },
          ...defaultDerivation,
        ];
      }
    }

    dispatch(
      updateAction(item.id, {
        derivation: () => defaultDerivation,
      })
    );
  };

  useEffect(() => {
    if (!reportTemplate?.root) return;

    miniSearch.addAll(
      reportTemplate.root.blocks
        .filter((block) => block.id !== item.id)
        .filter((block) => block.id !== item.parent?.id)
        .map((block) => ({
          id: block.id,
          label: serialize(block.label),
        }))
    );
  }, [item, reportTemplate?.root]);

  const handleSearchFields = (value: string) => {
    if (!value) {
      return [];
    }
    if (reportTemplate?.root) {
      const autoSuggest = miniSearch.autoSuggest(value)?.[0];
      if (autoSuggest) {
        const searchResults = miniSearch.search(autoSuggest.suggestion);
        const searchResultIds = searchResults.map((result) => result.id);
        return [
          ...reportTemplate.root.blocks.filter((block) =>
            searchResultIds.includes(block.id)
          ),
        ].sort(
          (a, b) =>
            searchResultIds.indexOf(a.id) - searchResultIds.indexOf(b.id)
        );
      }
      return [];
    }
    return [];
  };

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchTerm(value);
    setResults(handleSearchFields(value));
  };

  const [searchResultsIsOpen, setSearchResultsIsOpen] = useState(false);

  const handleResultOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (reportTemplate?.root) {
      const { value: blockId } = event.currentTarget;
      const block = reportTemplate.root.blocks.find(
        (innerBlock) => innerBlock.id === blockId
      );
      if (block) {
        dispatch(
          updateAction(item.id, {
            derivation: (prevDerivation) => [
              ...prevDerivation.filter((element) => element.id !== blockId),
              {
                id: block.id,
                type: 'INPUT',
                data: {
                  ...block,
                  type: 'INPUT',
                  reportTemplateBlockId: block.id,
                  dataType: block.valueType,
                  label: block.label,
                },
                position: { x: 250, y: 25 },
              },
            ],
          })
        );
        setSearchResultsIsOpen(false);
      }
    }
  };

  const handleOnConnect = (connection: Edge | Connection) => {
    dispatch(
      updateAction(item.id, {
        derivation: (prevDerivation) =>
          addEdge(
            {
              ...connection,
              id: generateId(),
              type: ConnectionLineType.SmoothStep,
            },
            prevDerivation
          ),
      })
    );
  };

  const handleNodeOnDragStop = (event: React.MouseEvent, node: Node) => {
    dispatch(
      updateAction(item.id, {
        derivation: (prevDerivation) =>
          prevDerivation.map((innerNode) =>
            innerNode.id === node.id
              ? { ...node, position: node.position }
              : innerNode
          ),
      })
    );
  };

  const searchBarWrapperRef = useRef<HTMLDivElement>(null);

  const handleSearchBarOnFocus = () => {
    setSearchResultsIsOpen(true);
  };

  useClickedOutside(searchBarWrapperRef, () => {
    setSearchResultsIsOpen(false);
  });

  type ToolboxMode = 'OPERATIONS' | 'CONDITIONS' | 'TEST';

  const [toolboxMode, setToolboxMode] = useState<ToolboxMode>('OPERATIONS');

  const handleConstantOnClick = () => {
    dispatch(
      updateAction(item.id, {
        derivation: (prevDerivation) => [
          ...prevDerivation,
          {
            id: generateId(),
            type: 'CONSTANT',
            data: {
              type: 'CONSTANT',
              constantType: 'CONSTANT',
              dataType: 'NUMBER',
            },
            position: { x: 250, y: 25 },
          },
        ],
      })
    );
  };

  const handleOperatorOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: type } = event.currentTarget;
    dispatch(
      updateAction(item.id, {
        derivation: (prevDerivation) => [
          ...prevDerivation,
          {
            id: generateId(),
            type,
            data: { type: 'OPERATION' },
            position: { x: 250, y: 25 },
          },
        ],
      })
    );
  };

  const handleOnLoad: OnLoadFunc = ({ fitView }) => {
    fitView();
  };

  const handleDerivationOnChange = (updateSpec: Spec<ReportTemplateBlock>) => {
    dispatch(updateAction(item.id, updateSpec));
  };

  const [executionValue, setExecutionValue] = useState();
  const [execution, setExecution] = useState<Graph<Node, Edge>>();

  const handleOnSubmit = (values: Record<string, any>) => {
    const [value, graph] = executeDerivation(item.derivation, values);
    setExecutionValue(value);
    setExecution(graph);
  };

  return (
    <SidePanel
      wrapperClassName="p-0"
      width={600}
      isOpen={isOpen}
      toggle={toggle}
    >
      {!item.derivation || item.derivation.length === 0 ? (
        <>
          <SimpleButton onClick={handleBeginOnClick}>Begin</SimpleButton>
        </>
      ) : (
        <div className="d-flex">
          <div className="p-2" style={{ width: 80, borderRight: theme.border }}>
            <SimpleButtonSquare
              className="w-100"
              icon={faTimes}
              value="ADD"
              onClick={handleOperatorOnClick}
            >
              Close
            </SimpleButtonSquare>
          </div>
          <div className="flex-grow-1">
            <div className="position-relative pt-2 px-2">
              <div ref={searchBarWrapperRef}>
                <SearchBar
                  placeholder="Search for fields..."
                  wrapperClassName="mr-0"
                  style={{
                    border: searchResultsIsOpen
                      ? `2px solid ${theme.color.primary.hex()}`
                      : undefined,
                    borderBottom: searchResultsIsOpen ? 'none' : undefined,
                    borderBottomRightRadius: searchResultsIsOpen
                      ? 0
                      : theme.borderRadius,
                    borderBottomLeftRadius: searchResultsIsOpen
                      ? 0
                      : theme.borderRadius,
                  }}
                  value={searchTerm}
                  onClear={() => {
                    setSearchTerm('');
                    setResults([]);
                  }}
                  onFocus={handleSearchBarOnFocus}
                  onChange={handleSearchTermOnChange}
                />
                <SearchBarResults
                  isOpen={searchResultsIsOpen}
                  searchTerm={searchTerm}
                  results={results}
                >
                  {() => (
                    <>
                      {results.map((result) => (
                        <SimpleButton
                          key={result.id}
                          className="p-2 h-auto w-100 d-block text-left"
                          style={{ borderRadius: 0 }}
                          value={result.id}
                          onClick={handleResultOnClick}
                        >
                          <div>{serialize(result.label)}</div>
                          <div className="text-75">
                            {renderValueType(result.valueType)}
                          </div>
                        </SimpleButton>
                      ))}
                    </>
                  )}
                </SearchBarResults>
              </div>
            </div>
            <div
              className="position-relative p-2"
              style={{
                height: 400,
                width: '100%',
                borderRadius: '1rem',
                overflow: 'hidden',
              }}
            >
              {searchResultsIsOpen && (
                <div
                  className="position-absolute"
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9,
                  }}
                />
              )}
              <Context.Provider
                value={{
                  derivationExecution: execution,
                  derivationOnChange: handleDerivationOnChange,
                  searchFields: handleSearchFields,
                }}
              >
                <ReactFlow
                  elements={loading ? [] : item.derivation}
                  nodeTypes={nodes}
                  edgeTypes={edges}
                  snapToGrid
                  snapGrid={[10, 10]}
                  connectionLineType={ConnectionLineType.SmoothStep}
                  elementsSelectable
                  onLoad={handleOnLoad}
                  onConnect={handleOnConnect}
                  onNodeDragStop={handleNodeOnDragStop}
                >
                  <Background
                    variant={BackgroundVariant.Dots}
                    gap={20}
                    size={1}
                  />
                </ReactFlow>
              </Context.Provider>
            </div>
            <div className="p-2" style={{ borderTop: theme.border }}>
              <div>
                <SimpleButton
                  active={toolboxMode === 'OPERATIONS'}
                  inButtonToolbar
                  icon={faSigma}
                  onClick={() => setToolboxMode('OPERATIONS')}
                >
                  Operations
                </SimpleButton>
                <SimpleButton
                  active={toolboxMode === 'CONDITIONS'}
                  inButtonToolbar
                  icon={faQuestionSquare}
                  onClick={() => setToolboxMode('CONDITIONS')}
                >
                  Conditions
                </SimpleButton>
                <SimpleButton
                  active={toolboxMode === 'TEST'}
                  inButtonToolbar
                  icon={faPlay}
                  onClick={() => setToolboxMode('TEST')}
                >
                  Test
                </SimpleButton>
              </div>
              {toolboxMode === 'OPERATIONS' && (
                <div className="mt-3">
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faPlus}
                    value="CONSTANT"
                    onClick={handleConstantOnClick}
                  >
                    Constant
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faPlus}
                    value="ADD"
                    onClick={handleOperatorOnClick}
                  >
                    Add
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faMinus}
                    value="SUBTRACT"
                    onClick={handleOperatorOnClick}
                  >
                    Subtract
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faTimes}
                    value="MULTIPLY"
                    onClick={handleOperatorOnClick}
                  >
                    Multiply
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faDivide}
                    value="DIVIDE"
                    onClick={handleOperatorOnClick}
                  >
                    Divide
                  </SimpleButtonSquare>
                  <SimpleButtonSquare
                    style={{ width: 64 }}
                    icon={faValueAbsolute}
                    value="ABSOLUTE"
                    onClick={handleOperatorOnClick}
                  >
                    Absolute
                  </SimpleButtonSquare>
                </div>
              )}
              {toolboxMode === 'CONDITIONS' && (
                <div className="mt-3">
                  <SimpleButton
                    icon={faLessThan}
                    value="LESS_THAN"
                    onClick={handleOperatorOnClick}
                  >
                    Less than
                  </SimpleButton>
                  <SimpleButton icon={faGreaterThan}>Greater than</SimpleButton>
                </div>
              )}
              {toolboxMode === 'TEST' && (
                <div className="mt-3">
                  <div className="mb-3">
                    <SimpleButtonSquare
                      style={{ width: 64 }}
                      icon={faPlus}
                      value="SCOPE"
                      onClick={handleOperatorOnClick}
                    >
                      Scope
                    </SimpleButtonSquare>
                  </div>
                  <Form onSubmit={handleOnSubmit}>
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        {item.derivation
                          .filter((element) => element.type === 'INPUT')
                          .map((inputElement) => (
                            <div key={inputElement.id} className="mb-3">
                              <Small>
                                {serialize(inputElement.data.label)}
                              </Small>
                              <Field
                                name={inputElement.id}
                                component={CharField}
                                noLabel
                                placeholder={serialize(inputElement.data.label)}
                              />
                            </div>
                          ))}
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>
                              <span className="text-75">Output:</span>{' '}
                              <strong>
                                {renderValue('BOOLEAN', executionValue)}
                              </strong>
                            </div>
                          </div>
                          <SimpleButton icon={faBolt}>Execute</SimpleButton>
                        </div>
                      </form>
                    )}
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </SidePanel>
  );
};

export default ReportTemplateBlockDerivationEditor;
