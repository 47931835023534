import React, { useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { useClient, useIsPathActive } from 'lib/hooks';
import { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';
import PortalTopbarExtension from '../PortalTopbarExtension';
import ClientImmediateActionsIncoming from '../ClientImmediateActionsIncoming';
import { INITIAL_QUERY } from './query';
import {
  ButtonTextProps,
  InitialQueryData,
  InitialQueryVariables,
} from './types';
import ClientImmediateActionsLateResponse from '../ClientImmediateActionsLateResponse';
import ClientImmediateActionsLateCompletion from '../ClientImmediateActionsLateCompletion';
import ClientImmediateActionsUnassigned from '../ClientImmediateActionsUnassigned';
import ClientImmediateActionsAll from '../ClientImmediateActionsAll';

const ButtonText = ({ children, count }: ButtonTextProps) => {
  return (
    <>
      <span
        className={classNames('mono', {
          'text-primary': count === 0,
          'text-danger': count > 0,
        })}
      >
        {count}
      </span>{' '}
      {children}
    </>
  );
};

const ClientImmediateActions = () => {
  const theme = useTheme();
  const client = useClient();

  const [counts, setCounts] = useState<{
    incoming: number;
    unassigned: number;
    lateResponse: number;
    requiringFurtherVisit: number;
    lateCompletion: number;
  }>({
    incoming: 0,
    unassigned: 0,
    lateResponse: 0,
    requiringFurtherVisit: 0,
    lateCompletion: 0,
  });

  useQuery<InitialQueryData, InitialQueryVariables>(INITIAL_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id },
    onCompleted: (data) => {
      setCounts({
        incoming: data.incomingJobs.count,
        unassigned: data.unassignedJobs.count,
        lateResponse: data.lateResponseJobs.count,
        requiringFurtherVisit: data.jobsRequiringFurtherVisit.count,
        lateCompletion: data.lateCompletionJobs.count,
      });
    },
  });

  const isPathActive = useIsPathActive();
  return (
    <>
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <Link to={`/clients/${client.slug}/immediate-actions`}>
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive('/clients/:slug/immediate-actions')}
                >
                  <ButtonText count={client.immediateActionCount}>
                    All immediate actions
                  </ButtonText>
                </SimpleButton>
              </Link>
              <div className="ml-3 mr-2 d-inline-block text-75">Filters:</div>
              <Link to={`/clients/${client.slug}/immediate-actions/incoming`}>
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/immediate-actions/incoming'
                  )}
                >
                  <ButtonText count={counts.incoming}>Incoming</ButtonText>
                </SimpleButton>
              </Link>
              <Link to={`/clients/${client.slug}/immediate-actions/unassigned`}>
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/immediate-actions/unassigned'
                  )}
                >
                  <ButtonText count={counts.unassigned}>Unassigned</ButtonText>
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/immediate-actions/late-response`}
              >
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/immediate-actions/late-response'
                  )}
                >
                  <ButtonText count={counts.lateResponse}>
                    Late response
                  </ButtonText>
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/immediate-actions/requires-further-visit`}
              >
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/immediate-actions/requires-further-visit'
                  )}
                >
                  <ButtonText count={counts.requiringFurtherVisit}>
                    Requires further visit
                  </ButtonText>
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/immediate-actions/late-completion`}
              >
                <SimpleButton
                  value="visits"
                  inButtonToolbar
                  active={isPathActive(
                    '/clients/:slug/immediate-actions/late-completion'
                  )}
                >
                  <ButtonText count={counts.lateCompletion}>
                    Late completion
                  </ButtonText>
                </SimpleButton>
              </Link>
            </div>
          </div>
        </CardWrapper>
      </PortalTopbarExtension>
      <Routes>
        <Route
          path="/clients/:slug/immediate-actions"
          element={<ClientImmediateActionsAll />}
        />
        <Route
          path="/clients/:slug/immediate-actions/incoming"
          element={<ClientImmediateActionsIncoming />}
        />
        <Route
          path="/clients/:slug/immediate-actions/unassigned"
          element={<ClientImmediateActionsUnassigned />}
        />
        <Route
          path="/clients/:slug/immediate-actions/late-response"
          element={<ClientImmediateActionsLateResponse />}
        />
        <Route
          path="/clients/:slug/immediate-actions/late-completion"
          element={<ClientImmediateActionsLateCompletion />}
        />
      </Routes>
    </>
  );
};

export default ClientImmediateActions;
