import React from 'react';
import classNames from 'classnames';
import { WindowWrapper } from './styled';
import Portal from '../Portal';
import { WindowProps } from './types';

const Window = ({
  innerRef,
  innerProps = {},
  children,
  position = 'center',
  onMouseEnter,
  onMouseLeave,
  ...rest
}: WindowProps) => {
  return (
    <Portal>
      <WindowWrapper
        {...rest}
        ref={innerRef}
        position={position}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classNames('inner shadow h-100', innerProps?.className)}
          {...innerProps}
        >
          {children}
        </div>
      </WindowWrapper>
    </Portal>
  );
};

export default Window;
