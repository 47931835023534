import React, { useCallback, useEffect } from 'react';
import { useDelay } from 'lib/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import Portal from '../Portal';
import SimpleButton from '../SimpleButton';
import { SidePanelProps } from './types';
import { SidePanelWrapper, SidePanelOverlay } from './styled';

const SidePanel = ({
  children,
  innerRef,
  title,
  isOpen,
  width = 400,
  height,
  overlay = true,
  wrapperClassName = 'm-3 flex-grow-1',
  wrapperStyle = {},
  toggle,
  onOpening,
  onOpened,
  onClosing,
  onClosed,
  ...rest
}: SidePanelProps) => {
  const isOpenDelayedShort = useDelay<boolean>(isOpen, {
    delay: 10,
  });
  const isOpenDelayed = useDelay<boolean>(isOpen, {
    delay: 250,
    delayOnStarted: (value) => {
      if (value) {
        if (onOpening) onOpening();
      } else if (onClosing) onClosing();
    },
    delayOnCompleted: (value) => {
      if (value) {
        if (onOpened) onOpened();
      } else if (onClosed) onClosed();
    },
  });
  const theme = useTheme();

  const handleOnKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen) return;
      switch (event.key) {
        case 'Escape':
          event.preventDefault();
          event.stopPropagation();
          toggle(false);
          break;
        default:
      }
    },
    [isOpen, toggle]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeydown);
    return () => {
      document.removeEventListener('keydown', handleOnKeydown);
    };
  }, [handleOnKeydown]);

  return (
    <Portal>
      {isOpen || isOpenDelayed ? (
        <>
          <SidePanelWrapper
            ref={innerRef}
            width={width}
            height={height}
            isOpen={isOpenDelayedShort}
            isOpenDelayed={isOpenDelayed}
            {...rest}
            className={classNames('shadow d-flex flex-column', rest.className)}
          >
            {title && (
              <div
                className="d-flex justify-content-between px-3 py-2"
                style={{ borderBottom: theme.border }}
              >
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ height: 34 }}
                >
                  {typeof title === 'string' ? (
                    <p className="mb-0">{title}</p>
                  ) : (
                    title
                  )}
                </div>
                <div>
                  <SimpleButton onClick={() => toggle(false)}>
                    <FontAwesomeIcon fixedWidth icon={faTimes} />
                  </SimpleButton>
                </div>
              </div>
            )}
            <div
              className={wrapperClassName}
              style={{ minHeight: 0, ...wrapperStyle }}
            >
              {children}
            </div>
          </SidePanelWrapper>
          {overlay && (
            <SidePanelOverlay
              isOpen={isOpenDelayedShort}
              isOpenDelayed={isOpenDelayedShort}
              onClick={() => toggle(false)}
            />
          )}
        </>
      ) : null}
    </Portal>
  );
};

export default SidePanel;
