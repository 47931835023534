import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTopbarExtension } from '../Layout';

const PortalTopbarExtension = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const ref = useTopbarExtension();
  const [canMount, setCanMount] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;
    setCanMount(true);
  }, [ref]);

  if (!ref?.current) return null;

  return ReactDOM.createPortal(children, ref?.current as HTMLDivElement);
};

export default PortalTopbarExtension;
