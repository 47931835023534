import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { useClient, useIsPathActive } from 'lib/hooks';
import { useTheme } from 'styled-components';
import { faTools } from '@fortawesome/pro-duotone-svg-icons/faTools';
import { faExclamationSquare } from '@fortawesome/pro-duotone-svg-icons/faExclamationSquare';
import { faGlobeEurope } from '@fortawesome/pro-duotone-svg-icons/faGlobeEurope';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';
import PortalTopbarExtension from '../PortalTopbarExtension';
import ClientJobsAll from '../ClientJobsAll';
import ClientJobsPPM from '../ClientJobsPPM';
import ClientJobsReactive from '../ClientJobsReactive';
import ClientRecentActions from '../ClientRecentActions';

const ClientJobs = () => {
  const theme = useTheme();
  const client = useClient();

  const isPathActive = useIsPathActive();

  return (
    <>
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <Link to={`/clients/${client.slug}/jobs`}>
                <SimpleButton
                  icon={faGlobeEurope}
                  inButtonToolbar
                  active={isPathActive('/clients/:slug/jobs')}
                >
                  All jobs
                </SimpleButton>
              </Link>
              <Link to={`/clients/${client.slug}/jobs/ppm`}>
                <SimpleButton
                  icon={faTools}
                  inButtonToolbar
                  active={isPathActive('/clients/:slug/jobs/ppm')}
                >
                  PPM Jobs
                </SimpleButton>
              </Link>
              <Link to={`/clients/${client.slug}/jobs/reactive`}>
                <SimpleButton
                  icon={faExclamationSquare}
                  inButtonToolbar
                  active={isPathActive('/clients/:slug/jobs/reactive')}
                >
                  Reactive Jobs
                </SimpleButton>
              </Link>
            </div>
            <div>
              <ClientRecentActions />
            </div>
          </div>
        </CardWrapper>
      </PortalTopbarExtension>
      <Routes>
        <Route path="ppm" element={<ClientJobsPPM />} />
        <Route path="reactive" element={<ClientJobsReactive />} />
        <Route path="" element={<ClientJobsAll />} />
      </Routes>
    </>
  );
};

export default ClientJobs;
