import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { useLazyQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from 'styled-components';
import {
  jobHistoryQueryOnCompleted,
  useReducerContext,
} from '../ClientJob/reducer';
import SimpleButton from '../SimpleButton';
import { JOB_HISTORY_QUERY } from './query';
import ScrollDiv from '../ScrollDiv';
import LogBlock from '../LogBlock';

const ClientJobHistory = () => {
  const [{ job }, dispatch] = useReducerContext();

  const scrollDivRef = useRef<HTMLDivElement>(null);

  const [hasMore, setHasMore] = useState(job?.logBlocks.length === 20);
  const [getJobHistory] = useLazyQuery(JOB_HISTORY_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(jobHistoryQueryOnCompleted(data));
      setHasMore(data.jobHistory.results.length === 20);
    },
  });

  const handleScrollToTop = () => {
    getJobHistory({
      variables: {
        jobId: job?.id,
        before: job?.logBlocks[job?.logBlocks.length - 1].id,
      },
    });
  };

  const theme = useTheme();
  if (!job) return null;
  return (
    <div className="h-100">
      <div
        className="d-flex justify-content-end px-3 py-2"
        style={{ borderBottom: theme.border }}
      >
        <Link to={`/clients/${job.client.slug}/jobs/${job.reference}/logs`}>
          <small style={{ display: 'block', lineHeight: '34px' }}>
            <SimpleButton className="text-75" icon={faList} hoverStyles={false}>
              View full logs
            </SimpleButton>
          </small>
        </Link>
      </div>
      <ScrollDiv
        id="client-job-history-scroll-div"
        ref={scrollDivRef}
        style={{
          overflowY: 'auto',
          height: 'calc(100% - 50px)',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <InfiniteScroll
          next={handleScrollToTop}
          hasMore={hasMore}
          dataLength={job.logBlocks.length}
          inverse
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          initialScrollY={scrollDivRef?.current?.scrollHeight}
          loader={
            <div className="text-center my-2">
              <small className="text-75">
                <FontAwesomeIcon
                  className="mr-1"
                  icon={faSpinnerThird}
                  fixedWidth
                  spin
                />
                Loading job history
              </small>
            </div>
          }
          endMessage={
            <div className="text-center my-2">
              <small className="text-75">No more job history</small>
            </div>
          }
          scrollableTarget="client-job-history-scroll-div"
        >
          {job.logBlocks.map((logBlock, index) => {
            const first = index === job.logBlocks.length - 1;
            const last = index === 0;
            return (
              <LogBlock
                key={logBlock.id}
                logBlock={logBlock}
                first={first}
                last={last}
              />
            );
          })}
        </InfiniteScroll>
      </ScrollDiv>
    </div>
  );
};

export default ClientJobHistory;
