import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { useIsPathActive } from 'lib/hooks';
import { TabProps } from './types';
import { TabBarWrapper, TabBarInner, TabWrapper } from './styled';

const Tab = ({ name, tab, path }: TabProps) => {
  const active = useIsPathActive(path);
  return (
    <TabWrapper active={active} to={`/${tab}`}>
      <span>{name}</span>
    </TabWrapper>
  );
};

const HomeTabBar = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <TabBarWrapper className="pl-4">
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="d-flex">
          <h4 className="text-75">Home</h4>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <div ref={breadcrumbRef} id="home__tab-bar-breadcrumb" />
        </div>
      </div>
      <TabBarInner className="d-flex">
        <Tab name="Dashboard" tab="" path="/" />
        <Tab name="Clients" tab="clients" path="/clients" />
        <Tab name="Jobs" tab="jobs" path="/jobs" />
      </TabBarInner>
    </TabBarWrapper>
  );
};

export default HomeTabBar;
