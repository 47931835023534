import styled from 'styled-components';
import { InnerProps } from './types';

export const Inner = styled.div<InnerProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ${({ gridTemplateAreas }) => `'${gridTemplateAreas}'`};
  transition: transform 250ms;
`;

export const Cell = styled.div`
  padding: 1rem;
  height: 100%;
`;

export const Note = styled.button`
  display: block;
  width: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.color.cardText.hex()};
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  padding: 1rem;
  text-align: left;
  border-radius: 0.25rem;
`;
