import React from 'react';
import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog';
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons/faFileChartLine';
import { useClient, useIsPathActive } from 'lib/hooks';
import { useTheme } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { faTasksAlt } from '@fortawesome/pro-duotone-svg-icons/faTasksAlt';
import ClientSettingsGeneral from '../ClientSettingsGeneral';
import ClientSettingsReportTemplates from '../ClientSettingsReportTemplates';
import ClientSettingsReportTemplate from '../ClientSettingsReportTemplate';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButtonLink from '../SimpleButtonLink';
import CardWrapper from '../CardWrapper';
import ClientSettingsManagement from '../ClientSettingsManagement';

const ClientSettings = () => {
  const theme = useTheme();
  const client = useClient();

  const isPathActive = useIsPathActive();

  return (
    <>
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings`}
            buttonProps={{
              icon: faCog,
              inButtonToolbar: true,
              active: isPathActive('/clients/:slug/settings'),
            }}
          >
            General
          </SimpleButtonLink>
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings/report-templates`}
            buttonProps={{
              icon: faFileChartLine,
              inButtonToolbar: true,
              active: isPathActive(
                '/clients/:slug/settings/report-templates/*'
              ),
            }}
          >
            Report templates
          </SimpleButtonLink>
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings/management`}
            buttonProps={{
              icon: faTasksAlt,
              inButtonToolbar: true,
              active: isPathActive('/clients/:slug/settings/management/*'),
            }}
          >
            Management
          </SimpleButtonLink>
        </CardWrapper>
      </PortalTopbarExtension>
      <Routes>
        <Route index element={<ClientSettingsGeneral />} />
        <Route
          path="report-templates"
          element={<ClientSettingsReportTemplates />}
        />
        <Route
          path="report-templates/:id"
          element={<ClientSettingsReportTemplate />}
        />
        <Route path="management" element={<ClientSettingsManagement />} />
      </Routes>
    </>
  );
};

export default ClientSettings;
