import { gql } from '@apollo/client';

export const REPORT_TEMPLATE_RESPONSE_SETS_QUERY = gql`
  query {
    reportTemplateResponseSets {
      id
      responses {
        id
        value
        color
      }
    }
  }
`;

export const CREATE_REPORT_TEMPLATE_RESPONSE_SET_MUTATION = gql`
  mutation {
    createReportTemplateResponseSet {
      reportTemplateResponseSet {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_RESPONSE_SET_MUTATION = gql`
  mutation (
    $reportTemplateResponseSetId: ID!
    $data: UpdateReportTemplateResponseSetInput!
  ) {
    updateReportTemplateResponseSet(
      reportTemplateResponseSetId: $reportTemplateResponseSetId
      data: $data
    ) {
      reportTemplateResponseSet {
        id
      }
    }
  }
`;

export const CREATE_REPORT_TEMPLATE_RESPONSE_MUTATION = gql`
  mutation ($reportTemplateResponseSetId: ID!) {
    createReportTemplateResponse(
      reportTemplateResponseSetId: $reportTemplateResponseSetId
    ) {
      reportTemplateResponse {
        id
        value
        color
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_RESPONSE_MUTATION = gql`
  mutation (
    $reportTemplateResponseId: ID!
    $data: UpdateReportTemplateResponseInput!
  ) {
    updateReportTemplateResponse(
      reportTemplateResponseId: $reportTemplateResponseId
      data: $data
    ) {
      reportTemplateResponse {
        id
      }
    }
  }
`;

export const DELETE_REPORT_TEMPLATE_RESPONSE_MUTATION = gql`
  mutation ($reportTemplateResponseId: ID!) {
    deleteReportTemplateResponse(
      reportTemplateResponseId: $reportTemplateResponseId
    ) {
      deleted
      deletedId
    }
  }
`;
