import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import NodeBase from '../NodeBase';
import { NodeUnaryProps } from './types';

const NodeUnary = ({ children, ...node }: NodeUnaryProps) => {
  const { id } = node;
  return (
    <NodeBase {...node}>
      <Handle id={`target-1-${id}`} type="target" position={Position.Top} />
      {children}
      <Handle id={`source-1-${id}`} type="source" position={Position.Bottom} />
    </NodeBase>
  );
};

export default NodeUnary;
