import classNames from 'classnames';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  SchedulerEventModel,
  ResourceModel,
  // eslint-disable-next-line import/extensions
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import React from 'react';
import { Link } from 'react-router-dom';
import { Client, JobVisit } from 'lib/types';
import { parseDateTime } from '../../lib/utils';
import JobStatusFlag from '../JobStatusFlag';
import UserInitials from '../UserInitials';

export const renderColumn = ({ record }: { record: ResourceModel }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const eventsInSeries = record.events.filter((event) => event.inSeries).length;
  return (
    <div className="d-flex">
      <div className="center-vertically">
        <UserInitials
          className="mr-2"
          // @ts-ignore
          style={{ backgroundColor: record.eventColor }}
          user={{ initials: record.initials }}
        />
      </div>
      <div className="center-vertically">
        <p
          className={classNames('mb-0', {
            'font-weight-bold': eventsInSeries > 0,
          })}
        >
          {record.name}
        </p>
        {eventsInSeries > 0 && (
          <p className="mb-0 text-50" style={{ lineHeight: '16px' }}>
            <small>
              <span className="mono">{eventsInSeries}</span> visit
              {eventsInSeries === 1 ? '' : 's'}
            </small>
          </p>
        )}
      </div>
    </div>
  );
};

export const renderEvent = ({
  eventRecord,
  resourceRecord,
}: {
  eventRecord: SchedulerEventModel;
  resourceRecord: ResourceModel;
}) => {
  return renderToStaticMarkup(
    <div
      id={`job-visit-scheduler-visit-${eventRecord.id}`}
      className="d-flex flex-column justify-content-between"
    >
      <div style={{ lineHeight: '14px', opacity: 0.75 }}>
        <small className="mono">
          {DateTime.fromJSDate(eventRecord.startDate as Date).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
          {' - '}
          {DateTime.fromJSDate(eventRecord.endDate as Date).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
        </small>
        <small className="ml-2">
          <FontAwesomeIcon className="mr-1" icon={faUserCircle} fixedWidth />
          {resourceRecord.name}
        </small>
      </div>
      <div
        className="font-weight-bold"
        style={{ lineHeight: '16px', fontSize: 12 }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {eventRecord.inSeries || eventRecord.isPhantom ? (
          <>
            <span className="mono">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {eventRecord?.number ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <>#{eventRecord?.number}</>
              ) : (
                ''
              )}
            </span>{' '}
            {eventRecord.name}
          </>
        ) : (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {eventRecord?.job?.name}
          </>
        )}
      </div>
      <div style={{ lineHeight: '14px', opacity: 0.75, marginTop: 2 }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {eventRecord.inSeries || eventRecord.isPhantom ? (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {eventRecord?.jobVisit?.status?.nameDisplay || 'Scheduled'}
          </>
        ) : (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <span className="mono">{eventRecord?.job?.reference}</span>{' '}
          </>
        )}
      </div>
    </div>
  );
};

export const renderTooltip = (
  client: Client,
  visit: JobVisit,
  eventRecord: SchedulerEventModel
) => {
  if (!visit.job) return '';
  return renderToStaticMarkup(
    <div>
      <div>
        <p className="mb-0">
          Start:{' '}
          <span className="mono">{parseDateTime(visit.dateTimeStart)}</span>
        </p>
        <p>
          End: <span className="mono">{parseDateTime(visit.dateTimeEnd)}</span>
        </p>
      </div>
      <div>
        <span className="mono mr-1">{visit.job.reference}</span>
        <JobStatusFlag className="mr-1" flag={visit.job.status.flag} />
        {visit.job.status.nameDisplay}
        <div>
          <Link
            to={`/clients/${client.slug}/jobs/${visit.job.reference}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {visit.job.name}
          </Link>
        </div>
        <div>{visit.job.site.name}</div>
      </div>
      <div>
        {/* @ts-ignore */}
        {eventRecord.dragValidationText}
      </div>
    </div>
  );
};
