import { gql } from '@apollo/client';

const REPORT_TEMPLATE_FRAGMENT = gql`
  fragment ReportTemplateFragment on ReportTemplate {
    id
    root {
      id
      children
      blocks {
        id
        label
        valueType
        responseSet {
          id
          responses {
            id
            value
            color
          }
        }
        isPrivate
        isRepeated
        isRequired
        isMultipleSelection
        isDerived
        parent {
          id
        }
        children
        logicConditionOrder
        logic {
          id
          valueType
          logicConditionType
          logicConditionValue
          logicConditionResponses {
            id
            value
            color
          }
          logicActionType
          logicTriggers
          children
          parent {
            id
          }
        }
      }
    }
  }
`;

const LOG_BLOCK_FRAGMENT = gql`
  fragment LogBlockFragment on LogItem {
    id
    type
    dateTime
    diffGroup {
      id
      undone
      bases {
        id
        data
      }
      diffs {
        id
        data
      }
    }
  }
`;

export const REPORT_TEMPLATE_QUERY = gql`
  ${REPORT_TEMPLATE_FRAGMENT}
  ${LOG_BLOCK_FRAGMENT}
  query ($reportTemplateId: ID!) {
    reportTemplate(reportTemplateId: $reportTemplateId) {
      ...ReportTemplateFragment
      logBlocks {
        ...LogBlockFragment
      }
    }
    reportTemplateState(reportTemplateId: $reportTemplateId) {
      id
      value
      valueDefaults
      collapsed
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_MUTATION = gql`
  ${LOG_BLOCK_FRAGMENT}
  mutation (
    $reportTemplateId: ID!
    $reportTemplateRoot: ReportTemplateRootInput!
  ) {
    updateReportTemplate(
      reportTemplateId: $reportTemplateId
      reportTemplateRoot: $reportTemplateRoot
    ) {
      reportTemplate {
        id
        logBlocks {
          ...LogBlockFragment
        }
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_STATE_LOGIC_MUTATION = gql`
  mutation (
    $reportTemplateStateId: ID!
    $reportTemplateBlockId: ID!
    $reportTemplateLogicId: ID!
  ) {
    updateReportTemplateStateLogic(
      reportTemplateStateId: $reportTemplateStateId
      reportTemplateBlockId: $reportTemplateBlockId
      reportTemplateLogicId: $reportTemplateLogicId
    ) {
      reportTemplateState {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_STATE_COLLAPSED_GROUPS_MUTATION = gql`
  mutation ($reportTemplateStateId: ID!, $reportTemplateBlockId: ID!) {
    updateReportTemplateStateCollapsedGroups(
      reportTemplateStateId: $reportTemplateStateId
      reportTemplateBlockId: $reportTemplateBlockId
    ) {
      reportTemplateState {
        id
      }
    }
  }
`;

export const UNDO_REPORT_TEMPLATE_UPDATE = gql`
  ${REPORT_TEMPLATE_FRAGMENT}
  ${LOG_BLOCK_FRAGMENT}
  mutation ($reportTemplateId: ID!) {
    undoReportTemplateUpdate(reportTemplateId: $reportTemplateId) {
      reportTemplate {
        ...ReportTemplateFragment
        logBlocks {
          ...LogBlockFragment
        }
      }
    }
  }
`;
