import styled from 'styled-components';
import SimpleButton from '../SimpleButton';

export const SearchBarWrapper = styled.div`
  position: relative;

  .icon {
    font-size: 1.125rem;
    top: 0.65rem;
    left: 0.65rem;
    position: absolute;
    opacity: 0.75;
  }
`;

export const SearchBarInput = styled.input`
  border: 2px solid rgba(0, 0, 0, 0.05);
  padding-left: 2.35rem;
  height: 38px;
  transition: border-color 100ms;
  border-radius: 0.25rem;
  width: 100%;
  color: ${({ theme }) => theme.color.cardText.hex()};
  background-color: ${({ theme }) => theme.color.inputBackground.hex()};

  &:hover {
    border-color: ${({ theme }) => theme.color.buttonHover.hex()};
  }

  &:focus {
    outline: none;
    border-color: #106c12;
  }
`;

export const ClearButton = styled(SimpleButton)`
  position: absolute;
  right: 1rem;
  top: 7px;
  height: 24px;
  font-size: 12px;
  padding: 0;
`;
