import MiniSearch, { SearchResult, Options } from 'minisearch';
import { useEffect, useMemo, useState } from 'react';
import useSearchBar from './useSearchBar';

type Return = [any[], ReturnType<typeof useSearchBar>[1]];

const useMiniSearch = (
  options: Options,
  items: any[],
  onSearch?: (results: SearchResult[]) => void
): Return => {
  const miniSearch = useMemo(() => new MiniSearch(options), [options]);

  const [results, setResults] = useState(items);

  useEffect(() => {
    miniSearch.addAll(items);
  }, [items, miniSearch]);

  useEffect(() => {
    setResults(items);
  }, [items]);

  const [, searchBarProps] = useSearchBar((newSearchTerm) => {
    if (newSearchTerm === '') {
      setResults(items);
      return;
    }

    const autoSuggest = miniSearch.autoSuggest(newSearchTerm)?.[0];
    if (autoSuggest) {
      const searchResults = miniSearch.search(autoSuggest.suggestion);
      if (onSearch) onSearch(searchResults);
      setResults(
        items.filter((item) =>
          searchResults.map((result) => result.id).includes(item.id)
        )
      );
    } else {
      if (onSearch) onSearch([]);
      setResults([]);
    }
  });

  return [results, searchBarProps];
};

export default useMiniSearch;
