const removeKeys = <T extends Record<string, any>>(
  data: Partial<T>,
  keys: (keyof T)[] | string[]
) => {
  return JSON.parse(JSON.stringify(data), (key, value) =>
    keys.includes(key) ? undefined : value
  );
};

export default removeKeys;
