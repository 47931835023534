import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash';
import React from 'react';
import { faCodeMerge } from '@fortawesome/pro-light-svg-icons/faCodeMerge';
import { ReportTemplateBlock, ReportTemplateBlockLogic } from 'lib/types';
import { generateId } from 'lib/utils';
import {
  addLogicToBlock,
  deleteBlock,
  toggleReportTemplateBlockBoolean,
  useReducerContext,
} from '../ReportTemplateEditor/reducer';
import { DELETE_REPORT_TEMPLATE_BLOCK_MUTATION } from './query';
import { TemplateBlockControlsWrapper } from './styled';
import SimpleButton from '../SimpleButton';
import Checkbox from '../Checkbox';
import {
  DeleteReportTemplateBlockMutationData,
  DeleteReportTemplateBlockMutationVariables,
} from './types';

const ReportTemplateBlockControls = ({
  item,
}: {
  item: ReportTemplateBlock;
}) => {
  const [, dispatch] = useReducerContext();

  const getDefaultLogicConditionType = () => {
    switch (item.valueType) {
      case 'CHAR':
        return 'CHAR_IS';
      case 'CHOICE':
        return 'CHOICE_IS';
      case 'NUMBER':
        return 'NUMBER_IS_EQUAL_TO';
      case 'BOOLEAN':
        return 'BOOLEAN_IS_TRUE';
      default:
        throw Error();
    }
  };

  const getDefaultLogicConditionValue = () => {
    switch (item.valueType) {
      case 'CHAR':
        return '';
      case 'BOOLEAN':
        return true;
      default:
        return '';
    }
  };

  const getDefaultLogicConditionResponses = () => {
    if (!item.responseSet || item.responseSet.responses.length === 0) return [];
    switch (item.valueType) {
      case 'CHOICE':
        return item.responseSet.responses.slice(0, 1);
      default:
        return [];
    }
  };

  const handleAddLogicOnClick = () => {
    const newLogic: ReportTemplateBlockLogic = {
      id: generateId(),
      valueType: 'LOGIC',
      logicConditionType: getDefaultLogicConditionType(),
      logicConditionValue: getDefaultLogicConditionValue(),
      logicConditionResponses: getDefaultLogicConditionResponses(),
      logicActionType: 'ASK',
      logicTriggers: [],
      children: [],
      logic: [] as never[],
      parent: item,
      derivation: [],
    };
    dispatch(addLogicToBlock(item, newLogic));
  };

  const handleIsRepeatedOnChange = () => {
    dispatch(toggleReportTemplateBlockBoolean(item.id, 'isRepeated'));
  };

  const handleIsRequiredOnChange = () => {
    dispatch(toggleReportTemplateBlockBoolean(item.id, 'isRequired'));
  };

  const handleIsMultipleSelectionOnChange = () => {
    dispatch(toggleReportTemplateBlockBoolean(item.id, 'isMultipleSelection'));
  };

  const handleIsPrivateOnChange = () => {
    dispatch(toggleReportTemplateBlockBoolean(item.id, 'isPrivate'));
  };

  const [deleteReportTemplateBlock] = useMutation<
    DeleteReportTemplateBlockMutationData,
    DeleteReportTemplateBlockMutationVariables
  >(DELETE_REPORT_TEMPLATE_BLOCK_MUTATION);

  const handleDeleteOnClick = () => {
    dispatch(deleteBlock(item.id));
    // deleteReportTemplateBlock({
    //   variables: { reportTemplateBlockId: item.id },
    // });
  };

  return (
    <TemplateBlockControlsWrapper className="d-flex justify-content-between px-3">
      <div className="d-flex">
        {item.valueType === 'CONTAINER' && (
          <div style={{ marginLeft: -2 }}>
            <Checkbox
              wrapperProps={{ style: { marginTop: 4, marginBottom: 4 } }}
              small
              checked={item.isRepeated}
              onChange={handleIsRepeatedOnChange}
              label="Repeat section"
            />
          </div>
        )}
        {item.valueType !== 'CONTAINER' && (
          <>
            <SimpleButton
              small
              hoverStyles={false}
              icon={faCodeMerge}
              onClick={handleAddLogicOnClick}
            >
              Add logic
            </SimpleButton>
            {!item.isDerived && (
              <Checkbox
                wrapperProps={{
                  className: 'ml-3',
                  style: { marginTop: 4, marginBottom: 4 },
                }}
                small
                label="Required"
                checked={item.isRequired}
                onChange={handleIsRequiredOnChange}
              />
            )}
            {item.valueType === 'CHOICE' && (
              <Checkbox
                wrapperProps={{
                  className: 'ml-3',
                  style: { marginTop: 4, marginBottom: 4 },
                }}
                small
                checked={item.isMultipleSelection}
                onChange={handleIsMultipleSelectionOnChange}
                label="Multiple selection"
              />
            )}
          </>
        )}
        <Checkbox
          wrapperProps={{
            className: 'ml-3',
            style: { marginTop: 4, marginBottom: 4 },
          }}
          small
          checked={item.isPrivate}
          onChange={handleIsPrivateOnChange}
          label="Is private"
        />
      </div>
      <div>
        <SimpleButton
          small
          hoverStyles={false}
          icon={faTrash}
          onClick={handleDeleteOnClick}
        >
          Delete
        </SimpleButton>
      </div>
    </TemplateBlockControlsWrapper>
  );
};

export default ReportTemplateBlockControls;
