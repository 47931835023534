import React from 'react';
import { Small } from 'components/Typography';
import Checkbox from '../../../../components/Checkbox';
import { useUser } from '../../../hooks';
import { getTimeOfDay } from '../../../utils';

export const BlockToolboxCardWelcome = () => {
  const user = useUser();

  return (
    <div>
      <h1>
        Good {getTimeOfDay()}, {user.name}
      </h1>
    </div>
  );
};

const BlockWelcome = () => {
  const user = useUser();

  return (
    <div className="p-3">
      <h3>
        Good {getTimeOfDay()}, {user.name.split(' ')[0]}
      </h3>
      <div className="d-flex">
        <div className="mr-4">
          <div className="mb-2">
            <Small>Todos</Small>
          </div>
          <div className="d-flex">
            <div style={{ width: 32 }}>
              <Checkbox checked={false} />
            </div>
            <div>
              <h5 className="mb-0">Do this task</h5>
              <p>Some note about the task</p>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-2">
            <Small>Management tasks</Small>
          </div>
          <div className="d-flex">
            <div>
              <h5 className="mb-0">Do this task</h5>
              <p>Some note about the task</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockWelcome;
