import styled from 'styled-components';

export type ButtonWithRemovedStylesProps = {
  hoverStyles?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  backgroundActiveColor?: string;
  persistBackgroundColor?: boolean;
  active?: boolean | null;
  secondary?: boolean;
  small?: boolean;
  color?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const ButtonWithRemovedStyles = styled.button<ButtonWithRemovedStylesProps>`
  display: inline-block;
  outline: none;
  border: none;
  border-radius: ${({ hoverStyles }) => (hoverStyles ? '0.3rem' : 0)};
  padding: ${({ hoverStyles, small }) => {
    if (small) return hoverStyles ? '0.15rem 0.55rem 0.15rem 0.5rem' : 0;
    return hoverStyles ? '0.3rem 0.55rem 0.3rem 0.5rem' : 0;
  }};
  margin: 0;
  background-color: ${({
    theme,
    active,
    backgroundColor,
    backgroundActiveColor,
  }) =>
    (active
      ? backgroundActiveColor || theme.color.simpleButtonHover.hex()
      : backgroundColor) || 'transparent'};
  color: ${({ theme }) => theme.color.buttonText.hex()};
  ${({ hoverStyles, secondary }) => {
    if (hoverStyles && secondary) {
      return 'transition: background-color 150ms, opacity 150ms';
    }
    if (hoverStyles) {
      return 'transition: background-color 150ms';
    }
    if (secondary) {
      return 'transition: opacity 150ms';
    }
    return '';
  }};
  height: ${({ small }) => (small ? 24 : 34)}px;
  font-size: ${({ small }) => (small ? 12 : 16)}px;
  white-space: nowrap;

  ${({ secondary }) =>
    secondary &&
    `
    opacity: 0.5;
  `}

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.buttonText.hex()};
    text-decoration: ${({ hoverStyles }) =>
      hoverStyles ? 'none' : 'underline'};
    background-color: ${({
      theme,
      disabled,
      hoverStyles,
      backgroundHoverColor,
    }) => {
      if (disabled) {
        return 'transparent';
      }
      if (backgroundHoverColor) {
        return backgroundHoverColor;
      }
      return hoverStyles
        ? `${theme.color.simpleButtonHover.alpha(0.75).hex()} !important`
        : 'transparent';
    }};
    ${({ hoverStyles, secondary }) => {
      if (hoverStyles && secondary) {
        return 'transition: background-color 150ms, opacity 150ms';
      }
      if (hoverStyles) {
        return 'transition: background-color 50ms';
      }
      if (secondary) {
        return 'transition: opacity 50ms';
      }
      return '';
    }};

    ${({ secondary }) =>
      secondary &&
      `
    opacity: 1;
  `}
  }

  &:active,
  &:focus {
    outline: none;
    ${({ persistBackgroundColor }) =>
      !persistBackgroundColor && 'background-color: transparent'}
    background-color: ${({ theme, active, backgroundColor }) =>
      (active ? theme.color.simpleButtonHover.hex() : backgroundColor) ||
      'transparent'};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.buttonText.alpha(0.5).hex()};
  }

  .simple-button__icon {
    transition: transform 150ms;

    vertical-align: ${({ hoverStyles }) =>
      hoverStyles ? '-0.175rem' : '-0.125rem'};
  }
`;
