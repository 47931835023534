import styled from 'styled-components';
import { PriorityPillProps } from './types';

export const PriorityPill = styled.div.attrs<PriorityPillProps>(
  ({ theme, priority }) => {
    let color;
    switch (priority) {
      case 'LOW':
        color = theme.color.cardText.hex();
        break;
      case 'MEDIUM':
        color = theme.color.warning.hex();
        break;
      case 'HIGH':
        color = theme.color.danger.hex();
        break;
      default:
        color = theme.color.cardText.hex();
        break;
    }
    return { color };
  }
)<PriorityPillProps>`
  display: inline-block;
  padding: 2px 1rem 0 0.35rem;
  border-radius: 999px;
  margin: 3px 0;
  text-transform: capitalize;

  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  border: 1px solid ${({ color }) => color};

  svg {
    color: ${({ color }) => color};
  }
`;
