import React from 'react';
import { Handle, Node, Position } from 'react-flow-renderer';
import { useDerivationEditorContext } from '../../context';
import { renderValue } from '../../utils';
import NodeBase from '../NodeBase';

const NodeScope = (node: Node) => {
  const { derivationExecution } = useDerivationEditorContext();
  const { id } = node;

  let valueType: string | undefined;
  let value: any;

  if (derivationExecution) {
    try {
      const [edgeEntry] = derivationExecution.inEdgeEntries(id);
      const nodeData = edgeEntry.sourceAttributes.data;
      valueType = nodeData.dataType;
      value = nodeData.value;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  return (
    <NodeBase {...node}>
      <Handle id={`target-1-${id}`} type="target" position={Position.Top} />
      {valueType &&
        typeof value !== 'undefined' &&
        renderValue(valueType, value)}
    </NodeBase>
  );
};

export default NodeScope;
