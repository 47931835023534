import styled from 'styled-components';
import { DepthLineProps } from './types';

export const Placeholder = styled.div`
  position: absolute;
  background-color: #61dafb;
`;

export const DepthLine = styled.div<DepthLineProps>`
  position: absolute;
  top: 0;
  height: 100%;
  margin-left: 1px;

  border-left: 3px solid
    ${({ theme, depth }) =>
      theme.color.cardBackground.brighten(depth / 2).hex()};
`;
