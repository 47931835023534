import React from 'react';
import { Row, Col } from 'reactstrap';
import ClientSettingsVariables from '../ClientSettingsVariables';

const ClientSettingsGeneral = () => {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <ClientSettingsVariables />
        </Col>
      </Row>
    </div>
  );
};

export default ClientSettingsGeneral;
