import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Wrapper } from 'components/ClientDashboardIncoming/styled';
import JobPill from 'components/JobPill';
import ScrollDiv from 'components/ScrollDiv';
import { Job, QueryResult } from '../../../types';
import { parseDateTime } from '../../../utils';
import { BlockInnerProps } from '../Block/types';
import { BlockToolboxCardProps } from '../BlockToolbox/types';
import { INCOMING_JOBS_QUERY } from './query';

const IncomingJob = ({ job, last }: any) => {
  const theme = useTheme();
  const href = `/clients/${job.client.slug}/jobs/${job.reference}`;
  const ref = useRef<HTMLAnchorElement>(null);
  return (
    <Link key={job.id} to={href}>
      <Wrapper
        ref={ref}
        href={href}
        className="position-relative use-todos"
        style={{
          borderBottom: last ? '' : theme.border,
          borderLeftWidth: 2,
          borderLeftStyle: 'solid',
          borderLeftColor: job?.priority?.color,
        }}
      >
        <div className="p-2">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <JobPill
                job={job}
                style={{ backgroundColor: 'transparent', padding: 0 }}
              />
            </div>
            <small
              className="mono text-75"
              style={{ lineHeight: '24px', fontSize: 12 }}
            >
              {parseDateTime(job.dateTimeCreated)}
            </small>
          </div>
          <div>
            <p className="mb-2 font-weight-bold">
              {job.instantiationType.name}
            </p>
            <small className="d-block" style={{ lineHeight: '18px' }}>
              {job.priority ? (
                <>
                  {job.priority.responseName} - {job.priority.completionName}
                </>
              ) : (
                'This job has no priority'
              )}
            </small>
          </div>
        </div>
      </Wrapper>
    </Link>
  );
};

export const BlockToolboxCardIncomingJobs = ({
  blockDefinition,
}: BlockToolboxCardProps) => {
  const [incomingJobs, setIncomingJobs] = useState<QueryResult<Job>>({
    results: [],
    count: 0,
  });

  useQuery(INCOMING_JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { size: 5 },
    onCompleted: (data) => {
      setIncomingJobs(data.incomingJobs);
    },
  });

  return (
    <div>
      {blockDefinition.type} hello
      <div>
        {incomingJobs.results.map((job) => (
          <div key={job.id}>{job.reference}</div>
        ))}
      </div>
    </div>
  );
};

const BlockIncomingJobs = ({ block }: BlockInnerProps) => {
  const theme = useTheme();
  const [incomingJobs, setIncomingJobs] = useState<QueryResult<Job>>({
    results: [],
    count: 0,
  });

  useQuery(INCOMING_JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIncomingJobs(data.incomingJobs);
    },
  });

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex p-2" style={{ borderBottom: theme.border }}>
        <h2 className="mono">{incomingJobs.count}</h2>
        <div className="position-relative ml-2">
          <h5 className="position-absolute text-nowrap" style={{ bottom: 0 }}>
            Incoming jobs
          </h5>
        </div>
      </div>
      <ScrollDiv
        className="flex-grow-1"
        style={{ overflowY: 'scroll', minHeight: 0 }}
      >
        {incomingJobs.results.map((job) => (
          <div key={job.id}>
            <IncomingJob job={job} last={false} />
          </div>
        ))}
      </ScrollDiv>
    </div>
  );
};

export default BlockIncomingJobs;
