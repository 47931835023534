import styled from 'styled-components';
import { WindowWrapperProps } from './types';

export const WindowWrapper = styled.div<WindowWrapperProps>`
  position: absolute;
  ${({ position }) => {
    switch (position) {
      case 'center':
        return 'left: -10000px';
      case 'right':
        return 'right: 10000px';
      default:
        return '';
    }
  }}
  padding-top: 1rem;
  opacity: 0;
  transition: opacity 50ms, border-color 100ms;
  z-index: 8998;

  div.inner {
    border-radius: 0.25rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.color.cardText.hex()};
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  }
`;
