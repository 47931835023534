import React, { useMemo, useRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useMutation } from '@apollo/client';
import { UserNote } from 'lib/types';
import { parseDateTime } from 'lib/utils';
import { useLocation } from 'react-router-dom';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { Inner, Cell, Note } from './styled';
import Button from '../Button';
import {
  CREATE_USER_NOTE_MUTATION,
  DELETE_USER_NOTE_MUTATION,
  UPDATE_USER_NOTE_MUTATION,
} from './query';
import { UserNoteFormValues, UserNotesProps } from './types';
import CharField from '../CharField';
import SlateField from '../SlateField';
import SlateViewer from '../SlateViewer';
import BooleanField from '../BooleanField';
import ScrollDiv from '../ScrollDiv';

const UserNotes = ({
  cellStyle = {},
  mode,
  userNotes,
  setMode,
  setUserNotes,
}: UserNotesProps) => {
  const [userNote, setUserNote] = useState<UserNote>();

  const [translateX, setTranslateX] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  const gridTemplateAreas = useMemo(() => {
    switch (mode) {
      case 'list':
        setTranslateX(0);
        return 'list create edit';
      case 'create':
        setTranslateX(-100 / 3);
        return 'list create edit';
      case 'edit':
        setTranslateX(-100 / 3);
        return 'list edit create';
      default:
        return '. . .';
    }
  }, [mode]);

  const handleCreateOnClick = () => {
    setMode('create');
  };

  const handleValidate = (values: UserNoteFormValues) => {
    const errors: { title?: string; body?: string } = {};
    if (!values.title) errors.title = 'Please enter a title';
    if (!values.body) errors.body = 'Please enter a note';
    return errors;
  };

  const [createUserNote, { loading: creating }] = useMutation(
    CREATE_USER_NOTE_MUTATION
  );

  const location = useLocation();

  const handleCreateOnSubmit = async (values: UserNoteFormValues) => {
    const { data } = await createUserNote({
      variables: {
        data: {
          title: values.title,
          body: values.body,
          path: values.includePath ? location.pathname : null,
        },
      },
    });
    const newUserNote = data.createUserNote.userNote;
    setUserNotes((prevUserNotes) => [newUserNote, ...prevUserNotes]);
    setMode('list');
    setUserNote(newUserNote);
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  const [updateUserNote, { loading: updating }] = useMutation(
    UPDATE_USER_NOTE_MUTATION
  );

  const handleUpdateOnSubmit = async (values: UserNoteFormValues) => {
    if (!userNote) return;
    const { data } = await updateUserNote({
      variables: { userNoteId: userNote.id, data: { ...values, path: null } },
    });
    const updatedUserNote = data.updateUserNote.userNote;
    setUserNotes((prevUserNotes) =>
      prevUserNotes.map((innerUserNote) =>
        innerUserNote.id === updatedUserNote.id
          ? updatedUserNote
          : innerUserNote
      )
    );
    setUserNote(updatedUserNote);
  };

  const [deleteUserNote, { loading: deleting }] = useMutation(
    DELETE_USER_NOTE_MUTATION
  );

  const handleDeleteOnClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!userNote) return;
    const { data } = await deleteUserNote({
      variables: { userNoteId: userNote.id },
    });
    const { deletedId } = data.deleteUserNote;
    setUserNotes((prevUserNotes) =>
      prevUserNotes.filter((innerUserNote) => innerUserNote.id !== deletedId)
    );
    setMode('list');
  };

  const handleUserNoteOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: userNoteId } = event.currentTarget;
    setUserNote(
      userNotes.find((innerUserNote) => innerUserNote.id === userNoteId)
    );
    setMode('edit');
  };

  return (
    <Inner
      style={{ transform: `translateX(${translateX}%)` }}
      gridTemplateAreas={gridTemplateAreas}
    >
      <Cell
        className="d-flex flex-column"
        style={{ gridArea: 'list', padding: 0, ...cellStyle }}
      >
        <div className="d-flex m-3">
          <Button icon={faPlus} onClick={handleCreateOnClick}>
            New
          </Button>
        </div>
        <ScrollDiv
          ref={scrollRef}
          className="h-100 px-3 flex-grow-1"
          style={{ overflowY: 'auto', minHeight: 0 }}
        >
          {userNotes.map((innerUserNote, index) => {
            const last = index === userNotes.length - 1;
            return (
              <Note
                key={innerUserNote.id}
                style={{ marginBottom: last ? 0 : '1rem' }}
                value={innerUserNote.id}
                onClick={handleUserNoteOnClick}
              >
                <div>
                  <h5>{innerUserNote.title}</h5>
                </div>
                <div className="text-75">
                  <SlateViewer value={innerUserNote.body} />
                </div>
                <div>
                  <small className="d-block mb-1">
                    {parseDateTime(innerUserNote.dateTime)}
                  </small>
                  <small className="d-block mono">{innerUserNote.path}</small>
                </div>
              </Note>
            );
          })}
        </ScrollDiv>
      </Cell>
      <Cell
        className="d-flex flex-column"
        style={{ gridArea: 'create', ...cellStyle }}
      >
        <div className="d-flex">
          <Button icon={faArrowLeft} onClick={() => setMode('list')}>
            Back
          </Button>
        </div>
        <div className="flex-grow-1">
          <Form validate={handleValidate} onSubmit={handleCreateOnSubmit}>
            {({ handleSubmit, form }) => (
              <form
                className="mt-4"
                onSubmit={async (event) => {
                  await handleSubmit(event);
                  form.reset();
                }}
              >
                <div className="mb-3">
                  <Field name="title" component={CharField} />
                </div>
                <div className="mb-3">
                  <Field
                    name="body"
                    placeholder="Note content..."
                    component={SlateField}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <Field
                    name="includePath"
                    component={BooleanField}
                    checkbox={false}
                    label="Include location"
                  />
                  <Button icon={faCheck} loading={creating}>
                    Create
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Cell>
      <Cell style={{ gridArea: 'edit', ...cellStyle }}>
        <div className="d-flex">
          <Button icon={faArrowLeft} onClick={() => setMode('list')}>
            Back
          </Button>
        </div>
        <Form
          initialValues={{
            title: userNote?.title,
            body: userNote?.body,
          }}
          validate={handleValidate}
          onSubmit={handleUpdateOnSubmit}
        >
          {({ handleSubmit, form }) => (
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="mb-3">
                <Field name="title" component={CharField} />
              </div>
              <div className="mb-3">
                <Field
                  name="body"
                  placeholder="Note content..."
                  component={SlateField}
                />
              </div>
              <div className="d-flex justify-content-between">
                <Button
                  icon={faTrashAlt}
                  loading={deleting}
                  onClick={async (event) => {
                    await handleDeleteOnClick(event);
                    form.reset();
                  }}
                >
                  Delete
                </Button>
                <Button icon={faCheck} loading={updating}>
                  Save
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Cell>
    </Inner>
  );
};

export default UserNotes;
