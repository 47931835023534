import React from 'react';
import Switch from 'react-switch';
import { FieldRenderProps } from 'react-final-form';
import { Small } from '../Typography';
import Checkbox from '../Checkbox';

const BooleanField = ({
  input,
  label,
  checkbox = true,
}: FieldRenderProps<boolean>) => {
  const handleOnChange = (checked: boolean) => {
    input.onChange(checked);
  };

  const id = `switch-${input.name}`;
  if (checkbox) {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor={id} className="mb-0">
        <p className="mb-1 ml-2">
          <Small>{label}</Small>
        </p>
        <Switch
          id={id}
          className="ml-1"
          checked={input.value}
          onColor="#106c12"
          onChange={handleOnChange}
        />
      </label>
    );
  }
  return (
    <Checkbox
      id={id}
      label={label}
      className="ml-1"
      checked={input.value}
      onColor="#106c12"
      onChange={handleOnChange}
    />
  );
};

export default BooleanField;
