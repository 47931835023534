import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useClientSlug } from 'lib/hooks';
import { useReducerContext } from '../ClientJob/reducer';
import SimpleConfirmButton from '../SimpleConfirmButton';
import { ClientJobReportCardProps } from './types';
import { Wrapper } from './styled';
import JobReportPDFPreview from '../JobReportPDFPreview';

const ClientJobReportCard = ({
  jobReport,
  last,
  onDelete,
}: ClientJobReportCardProps) => {
  const clientSlug = useClientSlug();
  const [{ job }] = useReducerContext();

  if (!job) return null;
  return (
    <Link
      to={`/clients/${clientSlug}/jobs/${job.reference}/reports/${jobReport.number}`}
    >
      <Wrapper className={classNames('d-flex', { 'mb-3': !last })}>
        <JobReportPDFPreview jobReport={jobReport} width={80} />
        <div>
          <div>
            <small className="text-75">{jobReport.statusDisplay}</small>
          </div>
          <p>{jobReport.template.name}</p>
        </div>
        {jobReport.status !== 'SUBMITTED' && (
          <div className="job-report-card-delete">
            <SimpleConfirmButton
              hoverStyles={false}
              value={jobReport.id}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onDelete(event);
              }}
              icon={faTimes}
            />
          </div>
        )}
      </Wrapper>
    </Link>
  );
};

export default ClientJobReportCard;
