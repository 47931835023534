import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleButton from '../SimpleButton';
import { SimpleButtonLargeProps } from './types';

const SimpleButtonLarge = ({
  children,
  icon,
  style,
  ...rest
}: SimpleButtonLargeProps) => {
  return (
    <SimpleButton
      style={{ height: 'auto', padding: '0.5rem', ...style }}
      {...rest}
    >
      <div className="d-flex text-left">
        <div className="mr-3">
          <FontAwesomeIcon icon={icon} size="3x" />
        </div>
        <div className="mt-1" style={{ fontSize: '0.75rem' }}>
          {children}
        </div>
      </div>
    </SimpleButton>
  );
};

export default SimpleButtonLarge;
