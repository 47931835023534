import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  line-height: 1;
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  line-height: 18px;
  padding-left: 28px;
  vertical-align: text-top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
    border: 2px solid ${({ theme }) => theme.color.contentBackground.hex()};
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 200ms;
  }
`;

export const Input = styled.input`
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;

  &:checked + ${Label}:before {
    border-color: ${({ theme }) => theme.color.primary.hex()};
  }

  &:checked + ${Label}:after {
    background-color: ${({ theme }) => theme.color.primary.hex()};
    transform: scale(1);
  }
`;
