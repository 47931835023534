import { gql } from '@apollo/client';

export const INCOMING_JOBS_QUERY = gql`
  query ($size: Int) {
    incomingJobs(size: $size) {
      count
      results {
        id
        client {
          slug
        }
        reference
        priority {
          code
          responseDuration
          responseName
          completionName
          completionDuration
          color
        }
        name
        dateTimeCreated
        status {
          nameDisplay
          flag {
            color
          }
        }
        instantiationType {
          name
        }
      }
    }
  }
`;
