import { gql } from '@apollo/client';

export const MANAGEMENT_TASKS_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String) {
    managementTaskTypes(clientId: $clientId, searchTerm: $searchTerm) {
      results
      counts
    }
  }
`;

export const MANAGEMENT_TASK_QUERY = gql`
  query ($managementTaskId: ID!) {
    managementTask(managementTaskId: $managementTaskId) {
      id
      type
      name
      description
      instance {
        ... on ManagementTaskClientSite {
          type
          data {
            id
            name
            location {
              id
              address
              meta
            }
          }
        }
        ... on ManagementTaskJobIssue {
          type
          data {
            id
            status
            issueType
            issueTypeDisplay
          }
        }
      }
    }
  }
`;
