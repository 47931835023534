import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientDashboardOverview from '../ClientDashboardOverview';
import ClientDashboardSchedule from '../ClientDashboardSchedule';
import Layout from '../Layout';
import HomeDashboard from '../HomeDashboard';
import HomeClients from '../HomeClients';
import HomeJobs from '../HomeJobs';
import ClientDashboard from '../ClientDashboard';
import ClientJobs from '../ClientJobs';
import ClientJobInstantiation from '../ClientJobInstantiation';
import ClientJobGroup from '../ClientJobGroup';
import ClientJob from '../ClientJob';
import ClientPeople from '../ClientPeople';
import ClientImmediateActions from '../ClientImmediateActions';
import ClientSites from '../ClientSites';
import ClientWorkflow from '../ClientWorkflow';
import Settings from '../Settings';
import People from '../People';
import Profile from '../Profile';
import ClientSettings from '../ClientSettings';

const LayoutRouter = () => (
  <Layout>
    <Routes>
      <Route path="/">
        <Route path="clients" element={<HomeClients />} />
        <Route path="jobs" element={<HomeJobs />} />
        <Route path="" element={<HomeDashboard />} />
      </Route>

      <Route path="/clients/:slug">
        <Route
          index
          element={
            <ClientDashboard>
              <ClientDashboardOverview />
            </ClientDashboard>
          }
        />
        <Route
          path="schedule"
          element={
            <ClientDashboard>
              <ClientDashboardSchedule />
            </ClientDashboard>
          }
        />

        <Route path="jobs">
          <Route path=":reference/*" element={<ClientJob />} />
          <Route path="instantiate" element={<ClientJobInstantiation />} />
          <Route path="ppm" element={<ClientJobs />} />
          <Route path="reactive" element={<ClientJobs />} />
          <Route path="" element={<ClientJobs />} />
        </Route>

        <Route path="job-groups/:reference/*" element={<ClientJobGroup />} />
        <Route path="people" element={<ClientPeople />} />
        <Route
          path="immediate-actions/*"
          element={<ClientImmediateActions />}
        />
        <Route path="sites" element={<ClientSites />} />
        <Route path="workflow" element={<ClientWorkflow />} />
        <Route path="settings/*" element={<ClientSettings />} />
      </Route>

      <Route path="/profile/*" element={<Profile />} />
      <Route path="/people/*" element={<People />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  </Layout>
);

export default LayoutRouter;
