import styled from 'styled-components';

export const ReportTemplateEditorEnd = styled.div`
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
`;

export const ReportTemplateEditorHeader = styled(ReportTemplateEditorEnd)`
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: ${({ theme }) => theme.border};
`;

export const PlaceholderWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.brighten(0.5).hex()};
`;

export const ReportTemplateEditorFooter = styled(ReportTemplateEditorEnd)`
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: ${({ theme }) => theme.border};
`;
