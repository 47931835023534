import React from 'react';
import { Line } from 'lib/loaders';
import { useTheme } from 'styled-components';

const Loader = () => {
  const theme = useTheme();
  return (
    <div>
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          key={index.toString()}
          className="p-2"
          style={{ borderBottom: theme.border }}
        >
          <Line
            style={{
              width: '40%',
              height: 16,
              marginBottom: 12,
              opacity: 0.25,
            }}
          />
          <Line
            style={{ width: '50%', height: 16, marginBottom: 4, opacity: 0.35 }}
          />
        </div>
      ))}
    </div>
  );
};

export default Loader;
