import styled from 'styled-components';
import chroma from 'chroma-js';
import { InputProps } from './types';

export const ChoicePill = styled.div`
  display: inline-block;
  border-radius: 999px;
  padding: 0 0.5rem;
  margin-right: 0.5rem;

  background-color: ${({ color }) =>
    chroma(color as string)
      .alpha(0.15)
      .hex()};
  color: ${({ color }) => color};
`;

export const Response = styled.div`
  border-top: ${({ theme }) => theme.border};
  padding: 0.5rem 0;
`;

export const Input = styled.input<InputProps>`
  display: block;
  width: 100%;
  background: none;
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.color.primary.hex() : 'transparent'};
  border-radius: 0.25rem;
  outline: none;
  height: 34px;
  color: ${({ theme }) => theme.color.cardText.hex()};
  padding-left: 0.5rem;
`;

export const ResponseColorButton = styled.button``;
