import React from 'react';
import { useTheme } from 'styled-components';
import chroma from 'chroma-js';
import { Wrapper, Color } from './styled';
import { ColorPickerProps } from './types';

const ColorPicker = ({ onChange }: ColorPickerProps) => {
  const theme = useTheme();
  const defaultColors = chroma
    .scale([
      theme.color.incoming,
      // theme.color.warning,
      theme.color.danger,
      theme.color.primary,
    ])
    .colors(15);

  const handleColorOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: color } = event.currentTarget;
    onChange(color);
  };

  return (
    <div className="p-2">
      <Wrapper className="d-flex flex-wrap">
        {defaultColors.map((color, index) => (
          <Color
            style={{
              backgroundColor: color,
              marginRight: (index + 1) % 5 === 0 ? 0 : 8,
              marginBottom:
                (index + 1) / 5 > defaultColors.length / 5 - 1 ? 0 : 8,
            }}
            value={color}
            onClick={handleColorOnClick}
          />
        ))}
      </Wrapper>
    </div>
  );
};

export default ColorPicker;
