import React, { Reducer, useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useMatch } from 'react-router';
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  useCallbackRef,
  useClient,
  useIsPathActive,
  useSessionId,
  useWebsocketUri,
} from 'lib/hooks';
import { useTheme } from 'styled-components';
import { faPenAlt } from '@fortawesome/pro-duotone-svg-icons/faPenAlt';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faFileContract } from '@fortawesome/pro-duotone-svg-icons/faFileContract';
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';
import { faMapMarkedAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt';
import { useTitle } from 'react-use';
import { faListUl } from '@fortawesome/pro-duotone-svg-icons';
import ClientJobGroupButton from '../ClientJobGroupButton';
import { JOB_QUERY } from './query';
import CardWrapper from '../CardWrapper';
import { ClientJobState, JobQueryData, JobQueryVariables } from './types';
import reducer, {
  initialState,
  ReducerContext,
  jobQueryOnCompleted,
  jobSubscriptionOnUpdate,
  jobSubscriptionHistoryOnCreate,
  jobSubscriptionHistoryOnUpdate,
  jobSubscriptionReportOnCreate,
  jobSubscriptionReportOnUpdate,
} from './reducer';
import SimpleButton from '../SimpleButton';
import ClientJobLogs from '../ClientJobLogs';
import ClientJobLocation from '../ClientJobMap';
import ClientJobHome from '../ClientJobHome';
import ClientJobIssues from '../ClientJobIssues';
import Figure from '../Figure';
import ClientJobReports from '../ClientJobReports';
import ClientJobReport from '../ClientJobReport';
import ClientJobBreadcrumb from '../ClientJobBreadcrumb';
import ClientJobInstantiation from '../ClientJobInstantiation';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButtonReactive from '../SimpleButtonReactive';
import ClientJobIssue from '../ClientJobIssue';
import { JobTabBarExtensionContext } from './context';
import ClientJobChatWindow from '../ClientJobChatWindow';
import ClientJobHistory from '../ClientJobHistory';

const ClientJob = () => {
  const theme = useTheme();
  const client = useClient();

  const match = useMatch('/clients/:slug/jobs/:reference/*');
  const reference = match?.params.reference as string;

  const [state, dispatch] = useReducer<Reducer<ClientJobState, AnyAction>>(
    reducer,
    initialState
  );
  const { job } = state;

  useTitle(reference);

  const location = useLocation();
  const navigate = useNavigate();
  const [getJob, { loading }] = useLazyQuery<JobQueryData, JobQueryVariables>(
    JOB_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.job.reference !== reference) {
          navigate(location.pathname.replace(reference, data.job.reference), {
            replace: true,
          });
        }
        dispatch(jobQueryOnCompleted(data));
      },
    }
  );

  useEffect(() => {
    if (!reference) return;
    if (reference === job?.reference) return;
    getJob({ variables: { reference } });
  }, [getJob, job?.reference, reference]);

  const sessionId = useSessionId();
  const websocketUri = useWebsocketUri('job/', { jobId: job?.id });

  useEffect(() => {
    if (!job) return () => {};
    const websocket = new WebSocket(websocketUri);
    websocket.onmessage = (rawResponse) => {
      const response = {
        data: JSON.parse(rawResponse.data),
      };
      if (!response.data) return;
      const {
        sessionId: sessionIdUsedForUpdate,
        type,
        action,
      } = response.data.jobSubscription;
      switch (type) {
        case 'JOB':
          switch (action) {
            case 'UPDATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionOnUpdate(response.data));
              }
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        case 'HISTORY': {
          switch (action) {
            case 'CREATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionHistoryOnCreate(response.data));
              }
              break;
            case 'UPDATE':
              dispatch(jobSubscriptionHistoryOnUpdate(response.data));
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        }
        case 'REPORT':
          switch (action) {
            case 'CREATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionReportOnCreate(response.data));
              }
              break;
            case 'UPDATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionReportOnUpdate(response.data));
              }
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        default:
          throw Error(`Invalid type: ${type}`);
      }
    };
    return () => {
      websocket.close();
    };
  }, [job, sessionId, websocketUri]);

  const [element, elementRef] = useCallbackRef<HTMLDivElement>();

  const isPathActive = useIsPathActive();

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientJobBreadcrumb loading={loading} />
      {!job?.instantiated ? (
        <ClientJobInstantiation />
      ) : (
        <>
          <PortalTopbarExtension>
            <CardWrapper
              className="px-4 py-2 rounded-0"
              style={{ borderLeft: theme.border, borderBottom: theme.border }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <Link to={`/clients/${client.slug}/jobs/${job?.reference}`}>
                    <SimpleButton
                      value="visits"
                      icon={faHome}
                      inButtonToolbar
                      active={isPathActive('/clients/:slug/jobs/:reference')}
                    >
                      Job home
                    </SimpleButton>
                  </Link>
                  <Link
                    to={`/clients/${client.slug}/jobs/${job?.reference}/reports`}
                  >
                    <SimpleButtonReactive
                      isReactive={!job.initial}
                      reactTo={job.reportCount}
                      value="reports"
                      icon={faFileContract}
                      inButtonToolbar
                      active={isPathActive(
                        '/clients/:slug/jobs/:reference/reports/*'
                      )}
                      windowChildren={() => (
                        <div className="p-3">New report</div>
                      )}
                    >
                      Reports <Figure value={job.reportCount} />
                    </SimpleButtonReactive>
                  </Link>
                  <Link
                    to={`/clients/${client.slug}/jobs/${job?.reference}/issues`}
                  >
                    <SimpleButtonReactive
                      isReactive={!job.initial}
                      reactTo={job.issueCount}
                      value="reports"
                      icon={faExclamationCircle}
                      inButtonToolbar
                      active={isPathActive(
                        '/clients/:slug/jobs/:reference/issues/*'
                      )}
                      windowChildren={() => (
                        <div className="p-3">New issue</div>
                      )}
                    >
                      Issues <Figure value={job.issueCount} />
                    </SimpleButtonReactive>
                  </Link>
                  <Link
                    to={`/clients/${client.slug}/jobs/${job?.reference}/logs`}
                  >
                    <SimpleButton
                      value="reports"
                      icon={faList}
                      inButtonToolbar
                      active={isPathActive(
                        '/clients/:slug/jobs/:reference/logs/*'
                      )}
                    >
                      Logs
                    </SimpleButton>
                  </Link>
                  <Link
                    to={`/clients/${client.slug}/jobs/${job?.reference}/map`}
                  >
                    <SimpleButton
                      value="reports"
                      icon={faMapMarkedAlt}
                      inButtonToolbar
                      active={isPathActive(
                        '/clients/:slug/jobs/:reference/map/*'
                      )}
                    >
                      Map
                    </SimpleButton>
                  </Link>
                  <div ref={elementRef} />
                </div>
                <div>
                  <Routes>
                    <Route path="" />
                    <Route path="logs" />
                    <Route
                      path="*"
                      element={
                        <SimpleButtonWithWindow
                          icon={faListUl}
                          inButtonToolbar
                          windowChildren={() => (
                            <div style={{ height: 400 }}>
                              <ClientJobHistory />
                            </div>
                          )}
                        >
                          History
                        </SimpleButtonWithWindow>
                      }
                    />
                  </Routes>
                  <ClientJobChatWindow />
                  <ClientJobGroupButton job={job} />
                  <SimpleButton icon={faPenAlt} inButtonToolbar>
                    Edit job
                  </SimpleButton>
                </div>
              </div>
            </CardWrapper>
          </PortalTopbarExtension>
          <JobTabBarExtensionContext.Provider value={element}>
            <Routes>
              <Route index element={<ClientJobHome />} />

              <Route path="reports">
                <Route index element={<ClientJobReports />} />
                <Route path=":reportNumber" element={<ClientJobReport />} />
              </Route>

              <Route path="issues">
                <Route index element={<ClientJobIssues />} />
                <Route path=":issueNumber" element={<ClientJobIssue />} />
              </Route>

              <Route path="logs" element={<ClientJobLogs />} />
              <Route path="map" element={<ClientJobLocation />} />
            </Routes>
          </JobTabBarExtensionContext.Provider>
        </>
      )}
    </ReducerContext.Provider>
  );
};

export default ClientJob;
