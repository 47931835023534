import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  div.options-list-outer {
    position: absolute;
    top: 100%;
    right: 0;
    height: 100%;
    z-index: 9999;
  }

  div.options-list-inner {
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.color.buttonMultiBackground.hex()};
    padding: 0.5rem;
    border-radius: 1rem;
  }
`;
