import { useMatch } from 'react-router-dom';

const useClientSlug = () => {
  const match = useMatch('/clients/:slug/*');
  const slug = match?.params.slug as string;

  if (!slug) {
    throw Error('useClientSlug hook used incorrectly');
  }
  return slug;
};

export default useClientSlug;
