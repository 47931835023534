import { faBrowser } from '@fortawesome/pro-duotone-svg-icons/faBrowser';
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faFunction } from '@fortawesome/pro-duotone-svg-icons/faFunction';
import { faQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { ReportTemplateBlock as ReportTemplateBlockType } from 'lib/types';
import { useDebounceCallback, useDelay } from 'lib/hooks';
import { RenderItemProps } from 'lib/tree';
import AnimateHeight from 'react-animate-height';
import { generateId, generateSlateDefault } from '../../lib/utils';
import CardWrapper from '../CardWrapper';
import {
  addBlock,
  blurBlock,
  focusBlock,
  reportTemplateBlockOnChange,
  useReducerContext,
} from '../ReportTemplateEditor/reducer';
import { NewReportTemplateBlock } from '../ReportTemplateEditor/types';
import SimpleButton from '../SimpleButton';
import SimpleButtonSquare from '../SimpleButtonSquare';
import SlateEditor from '../SlateEditor';
import SlateViewer from '../SlateViewer';
import { Small } from '../Typography';
import { TemplateBlockWrapper } from './styled';
import ReportTemplateBlockControls from '../ReportTemplateBlockControls';
import ReportTemplateBlockLogic from '../ReportTemplateBlockLogic';
import ReportTemplateBlockValueTypeEditor from '../ReportTemplateBlockValueTypeEditor';
import Context from './context';

const ReportTemplateBlock = ({
  item,
  depth,
  isCollapsed,
  draggableProvided,
  draggableSnapshot,
  toggleCollapse,
  onLogicChange,
}: RenderItemProps<ReportTemplateBlockType>) => {
  const [{ focused }, dispatch] = useReducerContext();

  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<Descendant[]>([]);
  const [editing, setEditing] = useState(false);
  const [editor] = useState<ReactEditor>(() =>
    withHistory(withReact(createEditor() as ReactEditor))
  );

  const updateLabel = useDebounceCallback((newValue: Descendant[]) => {
    dispatch(reportTemplateBlockOnChange(item.id, { label: newValue }));
  });

  const handleLabelOnChange = (newValue: Descendant[]) => {
    setValue(newValue);
    updateLabel(newValue);
  };

  const handleOnFocus = () => {
    dispatch(focusBlock(item.id));

    setValue(item.label);
    setEditing(true);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget.contains(event.relatedTarget as Element)) return;

    dispatch(blurBlock());
    setEditing(false);
  };

  const handleAddContainerOnClick = () => {
    const newBlock: NewReportTemplateBlock = {
      id: generateId(),
      label: generateSlateDefault('New section'),
      children: [],
      logicConditionOrder: [],
      logic: [],
      parent: item.parent,
      valueType: 'CONTAINER',
    };
    dispatch(addBlock(newBlock, item));
  };

  const handleAddQuestionOnClick = () => {
    const newBlock: NewReportTemplateBlock = {
      id: generateId(),
      label: generateSlateDefault('New question'),
      children: [],
      logicConditionOrder: [],
      logic: [],
      parent: item.parent,
      valueType: 'CHAR',
    };
    dispatch(addBlock(newBlock, item));
  };

  const handleAddDerivedOnClick = () => {
    const newBlock: NewReportTemplateBlock = {
      id: generateId(),
      label: generateSlateDefault('New derived'),
      children: [],
      logicConditionOrder: [],
      logic: [],
      parent: item.parent,
      valueType: 'CHAR',
      isDerived: true,
    };
    dispatch(addBlock(newBlock, item));
  };

  const isFocused = focused === item.id;
  const controlsIsOpen = isFocused;
  const controlsIsOpenDelayed = useDelay(controlsIsOpen, { delay: 250 });
  const controlsIsOpenDelayedShort = useDelay(controlsIsOpen, { delay: 10 });

  return (
    <Context.Provider value={{ depth, draggableSnapshot }}>
      <TemplateBlockWrapper
        id={`template-block-${item.id}`}
        className="position-relative"
        ref={ref}
        style={{
          userSelect: editing ? 'auto' : 'none',
          borderRadius:
            !focused && (isCollapsed || item.children.length === 0)
              ? 0
              : '0 0 0 0.25rem',
        }}
        valueType={item.valueType}
        isSelected={isFocused}
        tabIndex={0}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      >
        <div className="position-relative inner d-flex" style={{ zIndex: 10 }}>
          {item.children.length > 0 && (
            <div className="decoration-collapse">
              <SimpleButton
                style={{ width: 34, borderRadius: '50%' }}
                className="text-center"
                icon={faChevronRight}
                iconProps={{
                  fixedWidth: false,
                  rotation: isCollapsed ? undefined : 90,
                  style: { marginLeft: 2 },
                }}
                onClick={toggleCollapse}
              />
            </div>
          )}
          {(isCollapsed || item.children.length === 0) && (
            <div className="decoration-handle">
              <SimpleButton
                as="span"
                style={{ width: 34, borderRadius: '50%', cursor: 'grab' }}
                className="text-center"
                icon={faGripVertical}
                iconProps={{
                  fixedWidth: false,
                  style: { marginLeft: 2 },
                }}
                {...draggableProvided.dragHandleProps}
              />
            </div>
          )}
          {item.isRepeated && (
            <span style={{ lineHeight: '42px' }}>Repeated: </span>
          )}
          <div
            className="flex-grow-1"
            style={{
              fontWeight: item.isRequired ? 'bold' : 'normal',
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              marginTop: 6,
              marginLeft: 8,
            }}
          >
            {editing ? (
              <SlateEditor
                editor={editor}
                value={value}
                onChange={handleLabelOnChange}
              />
            ) : (
              <SlateViewer value={item.label} />
            )}
          </div>
          {item.isPrivate && (
            <div style={{ padding: '0.25rem' }}>
              <SimpleButton icon={faEyeSlash}>Private</SimpleButton>
            </div>
          )}
          <div style={{ padding: '0.25rem' }}>
            <ReportTemplateBlockValueTypeEditor item={item} />
          </div>
        </div>
        {(controlsIsOpen || controlsIsOpenDelayed) && (
          <AnimateHeight height={controlsIsOpenDelayedShort ? 'auto' : 0}>
            <ReportTemplateBlockControls item={item} />
          </AnimateHeight>
        )}
        {!isCollapsed && item.logic.length > 0 && (
          <ReportTemplateBlockLogic item={item} onLogicChange={onLogicChange} />
        )}
        {isFocused && !draggableSnapshot.isDragging && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 'calc(100% + 16px)',
            }}
          >
            <CardWrapper className="p-1 shadow text-center">
              <Small>
                <FontAwesomeIcon className="mr-1" icon={faPlus} />
                Add
              </Small>
              <SimpleButtonSquare
                style={{ width: '100%' }}
                icon={faBrowser}
                onClick={handleAddContainerOnClick}
              >
                Section
              </SimpleButtonSquare>
              <SimpleButtonSquare
                style={{ width: '100%' }}
                icon={faQuestion}
                onClick={handleAddQuestionOnClick}
              >
                Question
              </SimpleButtonSquare>
              <SimpleButtonSquare
                style={{ width: '100%' }}
                icon={faFunction}
                onClick={handleAddDerivedOnClick}
              >
                Derived
              </SimpleButtonSquare>
            </CardWrapper>
          </div>
        )}
      </TemplateBlockWrapper>
    </Context.Provider>
  );
};

export default ReportTemplateBlock;
