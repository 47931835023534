import React, { Reducer, useReducer } from 'react';
import { AnyAction } from 'redux';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useClient } from 'lib/hooks';
import { useTheme } from 'styled-components';
import ClientSettingsManagementBreadcrumb from '../ClientSettingsManagementBreadcrumb';
import ClientSettingsManagementTaskTable from '../ClientSettingsManagementTaskTable';
import ClientSettingsManagementTaskTypeTable from '../ClientSettingsManagementTaskTypeTable';
import { MANAGEMENT_TASKS_QUERY } from './query';
import {
  ManagementTasksQueryData,
  ManagementTasksQueryVariables,
  State,
} from './types';
import reducer, {
  initialState,
  ReducerContext,
  managementTasksQueryOnCompleted,
  goBack,
} from './reducer';
import ClientSettingsManagementSite from '../ClientSettingsManagementSite';
import CardWrapper from '../CardWrapper';

const ClientSettingsManagement = () => {
  const theme = useTheme();
  const client = useClient();

  const [state, dispatch] = useReducer<Reducer<State, AnyAction>>(
    reducer,
    initialState
  );

  const { managementTaskType, managementTask } = state;

  const { loading } = useQuery<
    ManagementTasksQueryData,
    ManagementTasksQueryVariables
  >(MANAGEMENT_TASKS_QUERY, {
    variables: { clientId: client.id },
    onCompleted: (data) => {
      dispatch(managementTasksQueryOnCompleted(data));
    },
  });

  const handleGoBackOnClick = () => {
    dispatch(goBack());
  };

  const renderManager = () => {
    if (!managementTask) return null;
    switch (managementTaskType) {
      case 'ENTER_SITE_LOCATION':
        return (
          <ClientSettingsManagementSite goBackOnClick={handleGoBackOnClick} />
        );
      default:
        return null;
    }
  };

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientSettingsManagementBreadcrumb loading={loading} />
      <CardWrapper className="p-0 h-100">
        <Row className="h-100" noGutters>
          <Col xs={3} style={{ borderRight: theme.border }}>
            <ClientSettingsManagementTaskTypeTable />
          </Col>
          <Col xs={9}>
            {managementTask ? (
              renderManager()
            ) : (
              <ClientSettingsManagementTaskTable />
            )}
          </Col>
        </Row>
      </CardWrapper>
    </ReducerContext.Provider>
  );
};

export default ClientSettingsManagement;
