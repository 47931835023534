import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import React from 'react';
import {
  Edge as EdgeType,
  EdgeProps,
  getEdgeCenter,
  getMarkerEnd,
  getSmoothStepPath,
  removeElements,
} from 'react-flow-renderer';
import { useDebounce } from 'react-use';
import { useHold, useHovered } from '../../../../lib/hooks';
import SimpleButtonCircle from '../../../SimpleButtonCircle';
import { useDerivationEditorContext } from '../../context';

const foreignObjectSize = 34;

const Edge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
}: EdgeProps) => {
  const { derivationOnChange } = useDerivationEditorContext();

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [hovered, hoveredCallbacks] = useHovered();
  const hoveredHeld = useHold(hovered, { hold: true, holdTime: 500 });

  const handleRemoveOnClick = () => {
    derivationOnChange({
      derivation: (prevDerivation) =>
        removeElements(
          [prevDerivation.find((element) => element.id === id) as EdgeType],
          prevDerivation
        ),
    });
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        style={style}
        d={edgePath}
        markerEnd={markerEnd}
        {...hoveredCallbacks}
      />
      {hoveredHeld && (
        <foreignObject
          className="edgebutton-foreignobject"
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          {...hoveredCallbacks}
        >
          <div>
            <SimpleButtonCircle
              active
              style={{ borderRadius: '50%' }}
              icon={faTimes}
              onClick={handleRemoveOnClick}
            />
          </div>
        </foreignObject>
      )}
    </>
  );
};

export default Edge;
