import React from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import Portal from '../Portal';
import { ContextMenuProps } from './types';

const ContextMenu = ({
  id,
  children,
  menuChildren,
  options,
  ...contextMenuProps
}: ContextMenuProps) => {
  const { show } = useContextMenu({ id });

  return (
    <>
      {children({ show })}
      <Portal>
        <Menu id={id} {...contextMenuProps}>
          {menuChildren}
          {options.map((option) => (
            <Item
              key={option.label}
              data={{ value: option.value, ...option.data }}
              onClick={option.onClick}
            >
              {option.children}
            </Item>
          ))}
        </Menu>
      </Portal>
    </>
  );
};

export default ContextMenu;
