import React from 'react';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from 'react-flow-renderer';
import NodeBinary from '../NodeBinary';

const NodeSubtract = (node: Node) => {
  return (
    <NodeBinary {...node}>
      <FontAwesomeIcon icon={faMinus} fixedWidth />
    </NodeBinary>
  );
};

export default NodeSubtract;
