import { useEffect, useRef } from 'react';
import useSessionId from './useSessionId';
import useWebsocketUri from './useWebsocketUri';

type UseWebsocketHook = <T>(
  path: string,
  query: Record<string, string>,
  onEvent: (event: MessageEvent<T>) => void,
  deps?: any[]
) => void;

const useWebsocket: UseWebsocketHook = (path, query = {}, onEvent, deps) => {
  const sessionId = useSessionId();
  const websocketUri = useWebsocketUri(path, query);

  const shouldConnect = useRef<boolean>(false);
  useEffect(() => {
    shouldConnect.current = !!deps?.some((dep) => !!dep);
  }, [deps]);

  useEffect(() => {
    if (!shouldConnect.current) return () => {};

    const websocket = new WebSocket(websocketUri);

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      onEvent({ ...event, data });
    };

    return () => {
      websocket.close();
    };
  }, [sessionId, websocketUri, onEvent]);
};

export default useWebsocket;
